import { slackValidationSchema } from "../schema/validationSchema";
import { useConnections } from "../core/ConnectionsProvider";
import { FieldConfig, SecretConfig } from "../core/_models";
import ConnectionForm from "./ConnectionForm";
import { useAuth } from "../../auth";
import { hasEditPermission } from "../../../helpers/permissions";

/**
 * `ConnectionSlackForm` is a form component for managing Slack connection configurations.
 * It enables users to input and update their Slack API token for secure communication with the Slack API.
 * The form dynamically adapts to user permissions and displays a loading state during asynchronous operations.
 *
 * @returns {JSX.Element} The Slack connection configuration form.
 */
const ConnectionSlackForm = (): JSX.Element => {
  const { currentUser } = useAuth();
  const slack_connection = hasEditPermission(currentUser, "account", "credentials");
  const { connection_data_slack, isLoading } = useConnections();

  /**
   * Configuration for the form fields, including labels, validation, and user permissions.
   */
  const fieldsConfigs: FieldConfig[] = [
    { id: "slack_api_token", labelId: "SECRETS.SLACK.APITOKEN", disabled: !slack_connection, showIcon: slack_connection }
  ];

  /**
   * Configuration for the secret keys used in the form.
   * This includes the secret keys, alert description, and API endpoints.
   * The secret keys are used to securely store and retrieve the user's Marketo credentials.
   */
  const secretConfig: SecretConfig = {
    testEndpoint: "/secrets/test/slack",
    saveEndpoint: "/secrets",
    alertDescId: "SECRETS.ALERT.DESC.SLACK",
    secretKeys: [["SLACK_API_TOKEN", "slack_api_token"]],
    optionalKeys: []
  };

  return (
    <ConnectionForm
      fieldConfigs={fieldsConfigs}
      validationSchema={slackValidationSchema}
      secretConfig={secretConfig}
      initialValues={connection_data_slack}
      isLoading={isLoading}
    />
  );
};

export default ConnectionSlackForm;
