import { Form } from "formik";
import FormFields from "./FormFields";
import { useIntl } from "react-intl";
import { Button } from "react-bootstrap";
import { hasEditPermission } from "../../../../../helpers/permissions";
import { useAuth } from "../../../../auth";

const FormWrapper = (props: any) => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const account_users_edit_permission = hasEditPermission(currentUser, "account", "users");
  
  return (
    <Form className="mx-auto w-100 p-3" id="insentric_js_form">
      <FormFields
        errors={props.errors}
        touched={props.touched}
        values={props.values}
        setFieldValue={props.setFieldValue}
      />
      <div className="d-flex justify-content-end">
        <Button
          className="m-3"
          type="submit"
          variant="primary"
          disabled={props.isSubmitting || !account_users_edit_permission}
        >
          {props.isSubmitting ? (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({ id: "FORM.SUBMITTING" })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            intl.formatMessage({ id: "FORM.SUBMIT" })
          )}
        </Button>
      </div>
    </Form>
  );
};

export default FormWrapper;
