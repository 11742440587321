type Props = {
  domain?: string;
};

const TableInsJsDomainCell = ({ domain }: Props) => {
  return (
    <div id={`domain-cell-${domain}`} className="w-100 fw-bold fs-5 text-primary text-start">
      {domain}
    </div>
  );
};

export { TableInsJsDomainCell };
