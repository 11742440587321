import { marketoValidationSchema } from "../schema/validationSchema";
import { useConnections } from "../core/ConnectionsProvider";
import { FieldConfig, SecretConfig } from "../core/_models";
import ConnectionForm from "./ConnectionForm";
import { useAuth } from "../../auth";
import { hasEditPermission } from "../../../helpers/permissions";

/**
 * `ConnectionMarketoForm` is a form component for managing Marketo connection configurations.
 * It allows users to input and update credentials for connecting to Marketo APIs.
 * The form validates user input and submits the data securely to the backend.
 *
 * @returns {JSX.Element} The Marketo connection configuration form.
 */
const ConnectionMarketoForm = (): JSX.Element => {
  const { currentUser } = useAuth();
  const marketo_connection = hasEditPermission(currentUser, "account", "credentials");
  const { isLoading, connection_data_marketo } = useConnections();

  /**
   * Configuration for the form fields, including labels, validation, and user permissions.
   */
  const fieldsConfigs: FieldConfig[] = [
    { id: "mkto_munchkin_id", labelId: "SECRETS.MARKETO.MUNCHKINID", disabled: true, showIcon: marketo_connection },
    { id: "mkto_client_id", labelId: "SECRETS.MARKETO.CLIENTID", disabled: !marketo_connection, showIcon: marketo_connection },
    { id: "mkto_client_secret", labelId: "SECRETS.MARKETO.CLIENTSECRET", disabled: !marketo_connection, showIcon: marketo_connection }
  ];

  /**
   * Configuration for the secret keys used in the form.
   * This includes the secret keys, alert description, and API endpoints.
   * The secret keys are used to securely store and retrieve the user's Marketo credentials.
   */
  const secretConfig: SecretConfig = {
    testEndpoint: "/secrets/test/marketo",
    saveEndpoint: "/secrets",
    alertDescId: "SECRETS.ALERT.DESC.MARKETO",
    secretKeys: [
      ["MRKT_CLIENT_ID", "mkto_client_id"],
      ["MRKT_CLIENT_SECRET", "mkto_client_secret"]
    ],
    optionalKeys: []
  };

  return (
    <ConnectionForm
      fieldConfigs={fieldsConfigs}
      validationSchema={marketoValidationSchema}
      secretConfig={secretConfig}
      initialValues={connection_data_marketo}
      isLoading={isLoading}
    />
  );
};

export default ConnectionMarketoForm;
