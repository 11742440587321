import { FC, PropsWithChildren } from "react";
import { HeaderProps } from "react-table";
import { useIntl } from "react-intl";
import { Role } from "../../core/_models";

type Props = {
  className?: string;
  title?: string;
  tableProps: PropsWithChildren<HeaderProps<Role>>;
};

const TableCustomHeader: FC<Props> = ({ className, title, tableProps }) => {
  const intl = useIntl();

  const { key, ...headerProps } = tableProps.column.getHeaderProps();

  return (
    <th
      key={key} // Pass `key` directly here
      {...headerProps}
      className={className}
      style={{ cursor: "pointer" }}
    >
      {intl.formatMessage({ id: title })}
    </th>
  );
};

export { TableCustomHeader };
