import axios from "axios";
import { useState } from "react";

// Fetches Initial data from the backend
export const fetchInitialData = async (userRole: any) => {
  let isSfdcEnabled = false;
  let result;
  try {
    const terraformResponse = await axios.get("/configs/terraform");
    if (terraformResponse.data.sfdc_organization_id) {
      isSfdcEnabled = true;
    }

    if (userRole === "Demo User") {
      result = {
        marketo: {
          mkto_munchkin_id: "",
          mkto_client_id: "",
          mkto_client_secret: ""
        },
        isSfdcEnabled: isSfdcEnabled,
        salesforce: {
          sfdc_login_url: "",
          sfdc_username: "",
          sfdc_password: "",
          sfdc_token: ""
        },
        hubspot: {
          hubspot_hub_id: "",
          hubspot_private_app_token: "",
          hubspot_public_app_id: "",
          hubspot_public_client_id: "",
          hubspot_public_client_secret: ""
        },
        slack: {
          slack_api_token: ""
        }
      };
    } else {
      const secretsResponse = await axios.get("/secrets");
      const data = secretsResponse.data;

      result = {
        marketo: {
          mkto_munchkin_id: data.MRKT_MUNCHKIN_ID || "",
          mkto_client_id: data.MRKT_CLIENT_ID || "",
          mkto_client_secret: data.MRKT_CLIENT_SECRET || ""
        },
        isSfdcEnabled: isSfdcEnabled || false,
        salesforce: {
          sfdc_login_url: data.SFDC_LOGIN_URL || "",
          sfdc_username: data.SFDC_USERNAME || "",
          sfdc_password: data.SFDC_PASSWORD || "",
          sfdc_token: data.SFDC_TOKEN || ""
        },
        hubspot: {
          hubspot_hub_id: data.HS_HUB_ID || "",
          hubspot_private_app_token: data.HS_PRIVATE_APP_TOKEN || "",
          hubspot_public_app_id: data.HS_PUBLIC_APP_ID || "",
          hubspot_public_client_id: data.HS_PUBLIC_CLIENT_ID || "",
          hubspot_public_client_secret: data.HS_PUBLIC_CLIENT_SECRET || ""
        },
        slack: {
          slack_api_token: data.SLACK_API_TOKEN || ""
        }
      };
    }
  } catch (err) {
    console.error("Error fetching initial values:", err);
  }
  return result;
};
