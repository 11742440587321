import { useIntl } from "react-intl";

type FormFieldNonTemplatedEmailsProps = {
  errors: any;
  touched: any;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  editPermission: boolean;
};

const FormFieldNonTemplatedEmails = ({
  errors,
  touched,
  values,
  setFieldValue,
  editPermission
}: FormFieldNonTemplatedEmailsProps) => {
  const intl = useIntl();

  return (
    <div className="m-3">
      <div className="mb-6">
        <div className="form-check form-switch form-check-custom form-check-solid justify-content-between align-items-center">
          <span className="fs-5 fw-bold">
            {intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.NONTEMPLATEDEMAILS"
            })}
          </span>
          <label htmlFor="mkto_qs_appender_update_non_templated" className="form-check-label sr-only">
            {intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.NONTEMPLATEDEMAILS"
            })}
          </label>
          <input
            id="mkto_qs_appender_update_non_templated"
            name="mkto_qs_appender_update_non_templated"
            type="checkbox"
            className="form-check-input"
            value={String(values.mkto_qs_appender_update_non_templated)}
            checked={values.mkto_qs_appender_update_non_templated}
            onChange={(e: any) => {
              setFieldValue("mkto_qs_appender_update_non_templated", e.target.checked ? true : false);
            }}
            aria-describedby="mkto_qs_appender_update_non_templated_description"
            disabled={!editPermission}
          />
        </div>
        <div id="mkto_qs_appender_update_non_templated_description" className="text-muted mt-3">
          {intl.formatMessage({
            id: "SETTINGS.MKTO.QSAPPEND.NONTEMPLATEDEMAILS.DESC"
          })}
        </div>
      </div>
      {!!errors.mkto_qs_appender_update_non_templated && touched.mkto_qs_appender_update_non_templated ? (
        <p className="text-danger">
          {intl.formatMessage({
            id: errors.mkto_qs_appender_update_non_templated
          })}
        </p>
      ) : null}
    </div>
  );
};

export default FormFieldNonTemplatedEmails;
