import { Field } from "formik";
import { Form as BootstrapForm } from "react-bootstrap";
import { LockAlert } from "./Alerts";
import { GenerateNewPassworIcon } from "./GenerateNewPasswordIcon";
import { CopyToClipboardIcon } from "./CopyToClipboardIcon";
import { ShowPasswordIcon } from "./ShowPasswordIcon";
import { FC, useState } from "react";
import { checkPassword, passwordGenerator, useKeyLock } from "../core/_helper";
import { useIntl } from "react-intl";
import "./_style.css";
import { useAuth } from "../../../auth";
import { hasEditPermission } from "../../../../helpers/permissions";

type PasswordFieldsProps = {
  setPasswordRequirements: any;
  setFieldValue: any;
  values: any;
  errors: any;
  touched: any;
  isSaving: boolean;
};

const PasswordFields: FC<PasswordFieldsProps> = ({
  setPasswordRequirements,
  setFieldValue,
  values,
  errors,
  touched,
  isSaving
}) => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const user_profile = hasEditPermission(currentUser, "user", "profile");

  const [generatedPassword, setGeneratedPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);

  const capsLockOn = useKeyLock("CapsLock");
  // const numLockOn = useKeyLock("NumLock");
  const scrollLockOn = useKeyLock("ScrollLock");

  // Javascript Clipboard API - It copies the generated password to the Clipboard
  const handleCopyPassword = async () => {
    setCopied(true);
    try {
      await navigator.clipboard.writeText(generatedPassword);
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  // Generates a new password every time the refresh icon is clicked at the end of the password line
  const handleGenerateNewPassword = () => {
    const password = passwordGenerator();
    setGeneratedPassword(password);
    setPasswordRequirements(checkPassword(password, confirmPassword));
    setFieldValue("password", password);
  };

  return (
    <>
      <label htmlFor="password" className="mb-2">
        <span className="fs-4 fw-bold">
          {intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.FORM.LABEL.CURRENTPASSWORD" })}
        </span>
      </label>
      <div className="password-form-field-container">
        <Field
          type={showCurrentPassword ? "text" : "password"}
          id="currentPassword"
          name="currentPassword"
          as={BootstrapForm.Control}
          isInvalid={!!errors.currentPassword && touched.currentPassword}
          disabled={!user_profile}
          className="form-input"
          style={{ paddingRight: "30px" }}
          value={currentPassword}
          onChange={(e: any) => {
            setFieldValue("currentPassword", e.target.value);
            setCurrentPassword(e.target.value);
          }}
          placeholder={intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.FORM.LABEL.CURRENTPASSWORD" })}
        />
        <div>
          <ShowPasswordIcon
            id="currentPassword"
            showPassword={showCurrentPassword}
            setShowPassword={() => setShowCurrentPassword((prevState) => !prevState)}
          />
        </div>
      </div>
      {errors.currentPassword && touched.currentPassword && (
        <div className="text-danger mt-2">{intl.formatMessage({ id: errors.currentPassword })}</div>
      )}

      <label htmlFor="password" className="mb-2 mt-10">
        <span className="fs-4 fw-bold">
          {intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.FORM.LABEL.PASSWORD" })}
        </span>
      </label>
      <div className="password-form-field-container">
        <Field
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          as={BootstrapForm.Control}
          isInvalid={!!errors.password && touched.password}
          disabled={!user_profile}
          className="form-input"
          style={{ paddingRight: "30px" }}
          value={generatedPassword}
          onChange={(e: any) => {
            setFieldValue("password", e.target.value);
            setGeneratedPassword(e.target.value);
            setPasswordRequirements(checkPassword(e.target.value, confirmPassword));
          }}
          placeholder={intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.FORM.LABEL.PASSWORD" })}
        />
        <div>
          <ShowPasswordIcon
            id="password"
            showPassword={showPassword}
            setShowPassword={() => setShowPassword(!showPassword)}
          />
          <CopyToClipboardIcon handleCopyPassword={handleCopyPassword} copied={copied} />
          <GenerateNewPassworIcon handleGenerateNewPassword={handleGenerateNewPassword} />
        </div>
      </div>
      {errors.password && touched.password && (
        <div className="text-danger mt-2">{intl.formatMessage({ id: errors.password })}</div>
      )}

      <label htmlFor="password" className="mb-2 mt-10">
        <span className="fs-4 fw-bold">
          {intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.FORM.LABEL.CONFIRMPASSWORD" })}
        </span>
      </label>

      <div className="password-form-field-container">
        <Field
          type={showConfirmPassword ? "text" : "password"}
          id="confirmPassword"
          name="confirmPassword"
          as={BootstrapForm.Control}
          isInvalid={!!errors.confirmPassword && touched.confirmPassword}
          disabled={!user_profile}
          className="form-input"
          style={{ paddingRight: "30px" }}
          placeholder={intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.FORM.LABEL.CONFIRMPASSWORD" })}
          value={confirmPassword}
          onChange={(e: any) => {
            setFieldValue("confirmPassword", e.target.value);
            setConfirmPassword(e.target.value);
            setPasswordRequirements(checkPassword(generatedPassword, e.target.value));
          }}
        />
        <div>
          <ShowPasswordIcon
            id="confirmPassword"
            showPassword={showConfirmPassword}
            setShowPassword={() => setShowConfirmPassword(!showConfirmPassword)}
          />
        </div>
      </div>
      {errors.confirmPassword && touched.confirmPassword && (
        <div className="text-danger mt-2">{intl.formatMessage({ id: errors.confirmPassword })}</div>
      )}

      <div className="d-flex justify-content-end mt-5">
        <button type="submit" className="btn btn-primary" disabled={!user_profile || isSaving}>
          {!isSaving ? (
            intl.formatMessage({ id: "BUTTON.UPDATEPASSWORD" })
          ) : (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({
                id: "BUTTON.UPDATINGPASSWORD"
              })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>

      {capsLockOn && <LockAlert alert="capsLock" />}
      {/* {!numLockOn && <LockAlert alert="numLock" />} */}
      {scrollLockOn && <LockAlert alert="scrollLock" />}
    </>
  );
};

export default PasswordFields;
