import { useEffect } from "react";
import { RolesModalHeader } from "./RolesModalHeader";
import RolesModalBody from "./RolesModalBody";

const RolesModal = () => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <div className="modal fade show d-block" id="modal_add_role" role="dialog" tabIndex={-1} aria-modal="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
          <div className="modal-content">
            <RolesModalHeader />
            <RolesModalBody />
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export { RolesModal };
