import { useIntl } from "react-intl";
import { Tooltip } from "react-tooltip";
import { useInsJSFilesRequest } from "../../../core/InsJSFilesProvider";

type Props = {
  domain: string;
  embedCode: string;
  id: string;
};

const TableInsJsEmbedCodeCell = ({ domain, embedCode, id }: Props) => {
  const intl = useIntl();

  const { handleCopyEmbedCode } = useInsJSFilesRequest();

  return (
    <div className="w-100 text-start">
      <div className="copyIconContainer">
        <button
          onClick={() => handleCopyEmbedCode(embedCode, id)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleCopyEmbedCode(embedCode, id);
            }
          }}
          data-tooltip-id={`tooltip-${id}`}
          style={{
            cursor: "pointer",
            background: "none",
            border: "none",
            padding: 0,
            textAlign: "left",
            fontFamily: "inherit"
          }}
          aria-label={`Copy embed code for ${domain}`}
        >
          <code style={{ boxShadow: "none" }}>{embedCode}</code>
        </button>
        <Tooltip
          id={`tooltip-${id}`}
          key={`tooltip-${id}`}
          content={intl.formatMessage({ id: "INSENTRICJS.EMBED" })}
          place="top"
          variant="dark"
          style={{
            zIndex: 100,
            fontFamily: "inter, helvetica, sans-serif"
          }}
        />
      </div>
    </div>
  );
};

export { TableInsJsEmbedCodeCell };
