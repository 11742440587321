import { useEffect, useState } from "react";
import "cropperjs/dist/cropper.css";
import { useIntl } from "react-intl";
import axios from "axios";
import CompanyLogoDropzone from "./CompanyLogoDropzone";
import { useAuth } from "../../auth";
import { hasEditPermission } from "../../../helpers/permissions";

const CompanyLogo = () => {
  const [lightImage, setLightImage] = useState<any>(null);
  const [darkImage, setDarkImage] = useState<any>(null);
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);
  const intl = useIntl();
  const { currentUser } = useAuth();
  const account_settings_edit_permission = hasEditPermission(currentUser, "account", "settings");

  useEffect(() => {
    setIsLoadingImage(true);
    axios
      .get("/assets/client_logo?theme=light")
      .then((res) => {
        setLightImage(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
    axios
      .get("/assets/client_logo?theme=dark")
      .then((res) => {
        setDarkImage(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
    setIsLoadingImage(false);
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-xxl-row gap-10">
        <div className="w-100 w-xxl-50">
          <div className="d-flex flex-column justify-content-center align-items-center mb-3 bg-white border border-primary rounded p-3">
            <span className="fs-3 text-primary mb-2">
              {intl.formatMessage({
                id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.LOGO.LIGHT"
              })}
            </span>
            <div className="p-0 mb-5 d-flex justify-content-center">
              {isLoadingImage && <div className="spinner spinner-primary"></div>}
              {!isLoadingImage && lightImage && <img src={lightImage} className="mh-35px w-100" alt="" />}
              {!isLoadingImage && !lightImage && (
                <div className="text-center">
                  {intl.formatMessage({
                    id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.LOGO.NOIMAGE"
                  })}
                </div>
              )}
            </div>
          </div>
          {account_settings_edit_permission ? <CompanyLogoDropzone theme="light" /> : null}
        </div>

        <div className="w-100 w-xxl-50">
          <div className="d-flex flex-column justify-content-center align-items-center mb-3 bg-primary border border-primary rounded p-3">
            <span className="fs-3 mb-2 text-white">
              {intl.formatMessage({
                id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.LOGO.DARK"
              })}
            </span>
            <div className="p-0 mb-5 d-flex justify-content-center">
              {isLoadingImage && <div className="spinner spinner-primary"></div>}
              {!isLoadingImage && darkImage && <img src={darkImage} className="mh-35px w-100" alt="" />}
              {!isLoadingImage && !darkImage && (
                <div className="text-center">
                  {intl.formatMessage({
                    id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.LOGO.NOIMAGE"
                  })}
                </div>
              )}
            </div>
          </div>

          {account_settings_edit_permission ? <CompanyLogoDropzone theme="dark" /> : null}
        </div>
      </div>
    </>
  );
};

export default CompanyLogo;
