import RolesHeader from "./components/RolesHeader";
import RolesTable from "./table/RolesTable";
import "../style/style.css";

const RolesWrapper = () => {
  return (
    <div className="card mb-5 mb-xxl-8 sticky-roles">
      <RolesHeader />
      <div className="card-body">
        <RolesTable />
      </div>
    </div>
  );
};

export default RolesWrapper;
