import { Container } from "react-bootstrap";
import { useIntl } from "react-intl";
import ChannelsTable from "../../../layout/components/tables/ChannelsTable";
import PageHeader from "../../../layout/components/content/PageHeader";
import { useEffect, useState } from "react";
import axios from "axios";
import InsSpinner from "../../../layout/components/spinner/InsSpinner";

export default function MktoProgressionStatusMap() {
  const intl = useIntl();

  const [mktoDataFetchStatus, setMktoDataFetchStatus] = useState<any>(undefined);

  useEffect(() => {
    Promise.all([axios.get("/status/data")])
      .then((response: any) => {
        if (response?.data?.mkto?.lead_fields.last_successful_fetch !== "") {
          setMktoDataFetchStatus(true);
        } else {
          setMktoDataFetchStatus(false);
        }
      })
      .catch((error: any) => {
        console.error("Error fetching initial values:", error);
      });
  }, []);

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level1name="SIDEBAR.SUBHEAD.MARKETO"
          level1link="/app/home"
          level2name="SETTINGS.MKTO.PROGSTATUSMAP"
          instructions="SETTINGS.MKTO.PROGSTATUSMAP.INSTRUCTIONS"
        />
      </div>
      <div className="mb-xl-3">
        {mktoDataFetchStatus === undefined ? (
          <div>
            <InsSpinner />
          </div>
        ) : mktoDataFetchStatus ? (
          <div className="mt-10 mb-10">
            <ChannelsTable />
          </div>
        ) : (
          <div className="mt-10 mb-10">
            <Container>
              <div className="d-flex justify-content-center">
                <h3>
                  {intl.formatMessage({
                    id: "SETTINGS.MKTO.GENERAL.DATAFETCHING.WAITMESSAGE"
                  })}
                </h3>
              </div>
            </Container>
          </div>
        )}
      </div>
    </>
  );
}
