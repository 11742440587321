import PermissionsHeader from "./components/PermissionsHeader";
import PermissionsBodyWrapper from "./components/PermissionsBodyWrapper";
import "../style/style.css";
import PermissionsFooter from "./components/PermissionsFooter";

const PermissionsWrapper = () => {
  return (
    <div className="card mb-5 mb-xxl-8 text-end">
      <PermissionsHeader />
      <div className="card-body ">
        <PermissionsBodyWrapper />
      </div>
      <PermissionsFooter />
    </div>
  );
};

export default PermissionsWrapper;
