import axios from "axios";
import { toast } from "react-toastify";
import { toastSettings } from "../../toast/Toast";
import { endpointMap, OBJECT_KEYS } from "../../../../helpers/apiHelpers";

/**
 * @description Fetches the appropriate data based on the refreshType and handles the response.
 *
 * @param url - The URL to send the POST request to
 * @param setIsRefreshing - The function to set the isRefreshing state in the component to true or false to show or hide the spinner
 * @param intl - The react-intl object to get the translations for the toast messages displayed.
 * @param body - The body prop contains the assetNames to fetch the data for
 */

type fetchAndHandleProps = {
  url: string;
  setIsRefreshing: (value: boolean) => void;
  intl: any;
  body?: any;
};

/**
 * Fetches the appropriate data based on the provided URL and handles API responses.
 *
 * @param props - The properties for fetching and handling the response.
 * @param props.url - The URL to send the POST request to.
 * @param props.setIsRefreshing - Function to control the spinner's visibility.
 * @param props.intl - The `react-intl` instance for localized messages.
 * @param props.body - The request payload for the API call.
 * @returns A promise that resolves when the API call completes.
 */

export const fetchAndHandle = async ({ url, setIsRefreshing, intl, body }: fetchAndHandleProps) => {
  try {
    await axios.post(url, body);
    toast.success(intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.REFRESHVALUES.SUCCESS" }), toastSettings("success"));
  } catch (err: any) {
    toast.error(
      `${intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.REFRESHVALUES.ERROR" })}: ${err.message}`,
      toastSettings("error")
    );
  } finally {
    setIsRefreshing(false); // Ensure the spinner is stopped
  }
};

/**
 * @description Handles the refresh click event for the refresh button in the component.
 *
 * @param refreshType - The type of refresh to perform
 * @param setIsRefreshing - The function to set the isRefreshing state in the component to true or false to show or hide the spinner
 * @param intl - The react-intl object to get the translations for the toast messages displayed.
 * @returns A promise that resolves when the refresh operation completes.
 */

export const handleRefreshClick = async (
  refreshType: string,
  setIsRefreshing: (value: boolean) => void,
  intl: any
): Promise<void> => {
  setIsRefreshing(true); // Start the spinner

  // Perform the refresh based on the refreshType
  try {
    switch (refreshType) {
      // ******************************************
      // *                                        *
      // * MARKETO ASSETS REFRESH LOGIC           *
      // * [smartlists, forms, folders, emails]   *
      // *                                        *
      // ******************************************

      /**
       * Refresh logic for Marketo smart lists
       * Invokes the fetchAndHandle function to fetch the smart lists data
       */
      case "smart_lists":
        await fetchAndHandle({
          url: endpointMap["marketo-fetch-assets"],
          setIsRefreshing,
          intl,
          body: { assetNames: [OBJECT_KEYS.MKTOASSETS.SMARTLISTS] }
        });
        break;

      /**
       * Refresh logic for Marketo forms
       * Invokes the fetchAndHandle function to fetch the forms data
       */
      case "forms":
        await fetchAndHandle({
          url: endpointMap["marketo-fetch-assets"],
          setIsRefreshing,
          intl,
          body: { assetNames: [OBJECT_KEYS.MKTOASSETS.FORMS] }
        });
        break;

      /**
       * Refresh logic for Marketo folders
       * Invokes the fetchAndHandle function to fetch the folders data
       */
      case "folders":
        await fetchAndHandle({
          url: endpointMap["marketo-fetch-assets"],
          setIsRefreshing,
          intl,
          body: { assetNames: [OBJECT_KEYS.MKTOASSETS.FOLDERS] }
        });
        break;

      /**
       * Refresh logic for Marketo emails
       * Invokes the fetchAndHandle function to fetch the emails data
       */
      case "emails":
        await fetchAndHandle({
          url: endpointMap["marketo-fetch-assets"],
          setIsRefreshing,
          intl,
          body: { assetNames: [OBJECT_KEYS.MKTOASSETS.EMAILS] }
        });
        break;

      // ****************************************************************************************
      // *                                                                                      *
      // * MARKETO OBJECTS REFRESH LOGIC                                                        *
      // * [mkto_programs, mkto_lead, mkto_company, mkto_opportunity, mkto_program_member]      *
      // *                                                                                      *
      // ****************************************************************************************

      /**
       * Refresh logic for Marketo programs
       * Invokes the fetchAndHandle function to fetch the programs data
       */
      case "mkto_programs":
        await fetchAndHandle({
          url: endpointMap["marketo-fetch-programs"],
          setIsRefreshing,
          intl,
          body: { program_ids: "" }
        });
        break;

      /**
       * Refresh logic for Marketo leads
       * Invokes the fetchAndHandle function to fetch the lead data
       */
      case "mkto_lead":
        await fetchAndHandle({
          url: endpointMap["marketo-fetch-metadata"],
          setIsRefreshing,
          intl,
          body: { objectKeys: [OBJECT_KEYS.MKTO.LEAD] }
        });
        break;

      /**
       * Refresh logic for Marketo company
       * Invokes the fetchAndHandle function to fetch the company data
       */
      case "mkto_company":
        await fetchAndHandle({
          url: endpointMap["marketo-fetch-metadata"],
          setIsRefreshing,
          intl,
          body: { objectKeys: [OBJECT_KEYS.MKTO.COMPANY] }
        });
        break;

      /**
       * Refresh logic for Marketo opportunity
       * Invokes the fetchAndHandle function to fetch the opportunity data
       */
      case "opportunity":
        await fetchAndHandle({
          url: endpointMap["marketo-fetch-metadata"],
          setIsRefreshing,
          intl,
          body: { objectKeys: [OBJECT_KEYS.MKTO.OPPORTUNITY] }
        });
        break;

      /**
       * Refresh logic for Marketo program member
       * Invokes the fetchAndHandle function to fetch the program member data
       */
      case "program_member":
        await fetchAndHandle({
          url: endpointMap["marketo-fetch-metadata"],
          setIsRefreshing,
          intl,
          body: { objectKeys: [OBJECT_KEYS.MKTO.PROGRAMMEMBER] }
        });
        break;

      // ****************************************************************************************************************
      // *                                                                                                              *
      // * SALESFORCE OBJECTS REFRESH LOGIC                                                                             *
      // * [sfdc_lead, sfdc_contact, sfdc_account, sfdc_opportunity, sfdc_campaign, sfdc_campaign_member, sfdc_task]    *
      // *                                                                                                              *
      // ****************************************************************************************************************

      /**
       * Refresh logic for Salesforce leads
       * Invokes the fetchAndHandle function to fetch the lead data
       */
      case "sfdc_lead":
        await fetchAndHandle({
          url: endpointMap["salesforce-fetch-metadata"],
          setIsRefreshing,
          intl,
          body: { objectKeys: [OBJECT_KEYS.SFDC.LEAD] }
        });
        break;

      /**
       * Refresh logic for Salesforce contacts
       * Invokes the fetchAndHandle function to fetch the contact data
       */
      case "sfdc_contact":
        await fetchAndHandle({
          url: endpointMap["salesforce-fetch-metadata"],
          setIsRefreshing,
          intl,
          body: { objectKeys: [OBJECT_KEYS.SFDC.CONTACT] }
        });
        break;

      /**
       * Refresh logic for Salesforce accounts
       * Invokes the fetchAndHandle function to fetch the account data
       */
      case "sfdc_account":
        await fetchAndHandle({
          url: endpointMap["salesforce-fetch-metadata"],
          setIsRefreshing,
          intl,
          body: { objectKeys: [OBJECT_KEYS.SFDC.ACCOUNT] }
        });
        break;

      /**
       * Refresh logic for Salesforce opportunities
       * Invokes the fetchAndHandle function to fetch the opportunity data
       */
      case "sfdc_opportunity":
        await fetchAndHandle({
          url: endpointMap["salesforce-fetch-metadata"],
          setIsRefreshing,
          intl,
          body: { objectKeys: [OBJECT_KEYS.SFDC.OPPORTUNITY] }
        });
        break;

      /**
       * Refresh logic for Salesforce campaigns
       * Invokes the fetchAndHandle function to fetch the campaign data
       */
      case "sfdc_campaign":
        await fetchAndHandle({
          url: endpointMap["salesforce-fetch-metadata"],
          setIsRefreshing,
          intl,
          body: { objectKeys: [OBJECT_KEYS.SFDC.CAMPAIGN] }
        });
        break;

      /**
       * Refresh logic for Salesforce campaign members
       * Invokes the fetchAndHandle function to fetch the campaign member data
       */
      case "sfdc_campaign_member":
        await fetchAndHandle({
          url: endpointMap["salesforce-fetch-metadata"],
          setIsRefreshing,
          intl,
          body: { objectKeys: [OBJECT_KEYS.SFDC.CAMPAIGNMEMBER] }
        });
        break;

      /**
       * Refresh logic for Salesforce tasks
       * Invokes the fetchAndHandle function to fetch the task data
       */
      case "sfdc_task":
        await fetchAndHandle({
          url: endpointMap["salesforce-fetch-metadata"],
          setIsRefreshing,
          intl,
          body: { objectKeys: [OBJECT_KEYS.SFDC.TASK] }
        });
        break;

      default:
        // No action needed for default case
        break;
    }
  } catch (error) {
    console.error("Error during refresh: ", error);
    // Optionally handle the error here instead
  } finally {
    setIsRefreshing(false); // Stop the spinner
  }
};


/**
 * Validates that the specified prop is provided when `showRefreshButton` is true.
 *
 * @param props - The props object passed to the component.
 * @param propName - The name of the prop to validate.
 * @param componentName - The name of the component being validated.
 * @returns An error if the prop is missing and `showRefreshButton` is true; otherwise, null.
 */

export const requireRefreshType = (props: any, propName: string, componentName: string): Error | null => {
  if (props.showRefreshButton && !props[propName]) {
    return new Error(`${propName} is required when showRefreshButton is true in ${componentName}`);
  }
  return null;
};
