import axios from "axios";
import { ID, usersRoleUpdateObject } from "./_models";
import { toast } from "react-toastify";
import { toastSettings } from "../../../layout/components/toast/Toast";

/**
 * @description Fetches permissions and roles
 *
 * @returns permissions and roles data in separate objects
 */
const getRolesAndPermissions = async (): Promise<any> => {
  const requests = [axios.get("/permissions"), axios.get("/roles")];
  return await axios
    .all(requests)
    .then((response) => {
      return { permissions: response[0].data, roles: response[1].data };
    })
    .catch((error) => {
      console.error("Error fetching permissions and roles:", error);
    });
};

/**
 * @description Updates the role with the given ID
 *
 * @param id The ID of the role to update
 * @param payload The updated permissions in the role
 * @returns A promise that resolves when the role has been updated
 */
const updateRole = async (id: ID, payload: any): Promise<any> => {
  await axios
    .put(`/roles/${id}`, payload)
    .then((response) => {
      toast.success("The Role has been successfully updated", toastSettings("success"));
    })
    .catch((error) => {
      console.log("Error : ", error);
      toast.error(`Error : ${error}`, toastSettings("error"));
    });
};

/**
 * @description Creates a new role
 *
 * @param payload An object containing the role name, description, and an array of permissions
 * @returns A promise that resolves when the role has been created
 */
const createRole = async (payload: any): Promise<any> => {
  await axios
    .post(`/roles`, payload)
    .then((response) => {
      toast.success("The Role has been successfully created", toastSettings("success"));
    })
    .catch((error) => {
      console.log("Error : ", error);
      toast.error(`Error : ${error}`, toastSettings("error"));
    });
};

/**
 * @description Deletes a role
 *
 * @param id The ID of the role to delete
 */
const deleteRole = async (id: ID): Promise<any> => {
  await axios
    .delete(`/roles/${id}`)
    .then((response) => {
      toast.success("The Role has been successfully deleted", toastSettings("success"));
    })
    .catch((error) => {
      console.log("Error : ", error);
      toast.error(`Error : ${error}`, toastSettings("error"));
    });
};

/**
 * @description Updates the role of multiple users
 *
 * @param users An array of objects containing the user ID and the role ID
 * @returns A promise that resolves when all users have been updated
 */
const updateUsersRole = async (users: Array<usersRoleUpdateObject>): Promise<void> => {
  const requests = users.map((user) => {
    const userRolePayload = {
      roles: [
        {
          id: user.roleId
        }
      ]
    };
    return axios.put(`/users/${user.userId}/roles`, userRolePayload);
  });

  return await axios
    .all(requests)
    .then(() => {
      toast.success("All selected users role have been successfully updated", toastSettings("success"));
    })
    .catch((error: any) => {
      console.log("Error : ", error);
      toast.error(`Error : ${error}`, toastSettings("error"));
    });
};

export { getRolesAndPermissions, updateRole, createRole, deleteRole, updateUsersRole };
