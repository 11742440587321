import { useIntl } from "react-intl";
import { KTIcon } from "../../../helpers";

type SourceTrackerHeaderProps = {
  openNewFileModal: () => void;
};

const SourceTrackerHeader = ({ openNewFileModal }: SourceTrackerHeaderProps) => {
  const intl = useIntl();

  return (
    <div className="card-header border-0 pt-6 justify-content-end">
      <div className="card-toolbar">
        <button
          type="button"
          className="btn btn-primary"
          onClick={openNewFileModal}
          aria-label={intl.formatMessage({
            id: "SETTINGS.ACCOUNT.INSENTRICJS.CREATE"
          })}
        >
          <KTIcon iconName="plus" className="fs-2" />
          {intl.formatMessage({
            id: "SETTINGS.ACCOUNT.INSENTRICJS.CREATE"
          })}
        </button>
      </div>
    </div>
  );
};

export { SourceTrackerHeader };
