import { useIntl } from "react-intl";
import { useState } from "react";
import { KTIcon } from "../../../../helpers";
import { useRolesView } from "../../core/RolesAndPermissionsProvider";
import PermissionRow from "./PermissionRow";
import { Permission } from "../../core/_models";

type GroupedPermission = {
  baseId: string;
  name: string;
  description: string;
  readId: string | null;
  editId: string | null;
  triggerId: string | null; // Generic trigger for permissions without "read" or "edit"
};

type GroupedByResource = Record<string, GroupedPermission[]>;

type PermissionsAppBlockProps = {
  resource: string;
  app: string;
};

// Mapping for custom resource names
const resourceDisplayNameMap: Record<string, string> = {
  superset: "Reporting" // Map "superset" to "Reporting"
};

const getResourceDisplayName = (resource: string): string => {
  return resourceDisplayNameMap[resource.toLowerCase()] || resource;
};

// Preprocess permissions to group by resource/application
const preprocessPermissionsByResource = (permissions: Permission[]): GroupedByResource => {
  const groupedByResource: GroupedByResource = {};

  permissions.forEach((permission) => {
    if (!permission.id) return;

    // Extract resource from ID (e.g., "account" from "account.credentials.read")
    const resource = permission.id.split(".")[0];

    if (!groupedByResource[resource]) {
      groupedByResource[resource] = [];
    }

    // Resolve conflicts and merge permissions
    const baseId = permission.id.replace(/\.read|\.edit$/, "");
    const existingPermission = groupedByResource[resource].find((perm) => perm.baseId === baseId);

    if (!existingPermission) {
      groupedByResource[resource].push({
        baseId,
        name: permission.name,
        description: permission.description,
        readId: permission.id.endsWith(".read") ? permission.id : null,
        editId: permission.id.endsWith(".edit") ? permission.id : null,
        triggerId: !permission.id.endsWith(".read") && !permission.id.endsWith(".edit") ? permission.id : null
      });
    } else {
      // Resolve conflicts for existing permission
      existingPermission.readId = permission.id.endsWith(".read") ? permission.id : existingPermission.readId;
      existingPermission.editId = permission.id.endsWith(".edit") ? permission.id : existingPermission.editId;
      existingPermission.triggerId =
        !permission.id.endsWith(".read") && !permission.id.endsWith(".edit")
          ? permission.id
          : existingPermission.triggerId;
    }
  });

  return groupedByResource;
};

const PermissionsAppBlock = ({ resource, app }: PermissionsAppBlockProps) => {
  const intl = useIntl();
  const { permissions } = useRolesView();

  const resourceName = getResourceDisplayName(
    (resource.charAt(0).toUpperCase() + resource.slice(1)).replaceAll(/_/g, " ")
  );
  const [isExpanded, setIsExpanded] = useState(false);

  // Preprocess permissions to group by resource/application
  const permissionsGroupedByResource = preprocessPermissionsByResource(permissions);

  // Get permissions specific to this resource
  const cleanedPermissions = permissionsGroupedByResource[resource.toLowerCase()] || [];

  // console.log("Cleaned Permissions for Resource:", resource, cleanedPermissions);

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="accordion accordion-icon-collapse" id="permissions_accordion">
      <div className="mb-6">
        <div
          className={`accordion-header py-3 d-flex ${isExpanded ? "" : "collapsed"}`}
          data-bs-toggle="collapse"
          data-bs-target={`#accordion_body_${app}_${resource}`}
          role="button"
          aria-expanded={isExpanded}
          onClick={handleToggle}
        >
          <span className="accordion-icon">
            <KTIcon iconType="duotone" iconName="plus-square" className="fs-3 accordion-icon-off" />
            <KTIcon iconType="duotone" iconName="minus-square" className="fs-3 accordion-icon-on" />
          </span>
          <h3 className="fs-2 fw-semibold mb-0 ms-4 text-primary">
            {resourceName} {intl.formatMessage({ id: "ROLES.PERMISSIONS.HEADER.BLOCKTITLE" })}
          </h3>
        </div>

        <div
          id={`accordion_body_${app}_${resource}`}
          className={`accordion-collapse collapse ${isExpanded ? "show" : ""}`}
          aria-labelledby={`accordion_header_${app}_${resource}`}
          data-bs-parent="#permissions_accordion"
        >
          <div className="accordion-body px-0 px-sm-5">
            <div className="d-flex flex-column w-100">
              {cleanedPermissions.map((permission, index) => (
                <>
                  <div key={permission.baseId || `fallback-key-${index}`}>
                    <PermissionRow
                      id={permission.baseId}
                      name={permission.name}
                      description={permission.description}
                      readId={permission.readId}
                      editId={permission.editId}
                      triggerId={permission.triggerId}
                    />
                  </div>
                  {cleanedPermissions.length - 1 !== index && <div className="separator separator-dashed" />}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionsAppBlock;
