import { hubspotValidationSchema } from "../schema/validationSchema";
import { useConnections } from "../core/ConnectionsProvider";
import { FieldConfig, SecretConfig } from "../core/_models";
import ConnectionForm from "./ConnectionForm";
import { useAuth } from "../../auth";
import { hasEditPermission } from "../../../helpers/permissions";

/**
 * `ConnectionHubspotForm` is a form component for managing HubSpot connection configurations.
 * It allows users to input and update credentials for connecting to HubSpot APIs.
 * The form dynamically adapts based on user permissions and validation rules.
 *
 * @returns {JSX.Element} The HubSpot connection configuration form.
 */
const ConnectionHubspotForm = (): JSX.Element => {
  const { currentUser } = useAuth();
  const hubspot_connection = hasEditPermission(currentUser, "account", "credentials");
  const { connection_data_hubspot, isLoading } = useConnections();

  /**
   * Configuration for the form fields, including labels, validation, and user permissions.
   */
  const fieldsConfigs: FieldConfig[] = [
    { id: "hubspot_hub_id", labelId: "SECRETS.HUBSPOT.HUBID", disabled: !hubspot_connection, showIcon: hubspot_connection },
    { id: "hubspot_private_app_token", labelId: "SECRETS.HUBSPOT.PRIVATEAPPTOKEN", disabled: !hubspot_connection, showIcon: hubspot_connection },
    { id: "hubspot_public_app_id", labelId: "SECRETS.HUBSPOT.PUBLICAPPTOKEN", disabled: !hubspot_connection, showIcon: hubspot_connection },
    { id: "hubspot_public_client_id", labelId: "SECRETS.HUBSPOT.PUBLICCLIENTID", disabled: !hubspot_connection, showIcon: hubspot_connection },
    {
      id: "hubspot_public_client_secret",
      labelId: "SECRETS.HUBSPOT.PUBLICCLIENTSECRET",
      disabled: !hubspot_connection,
      showIcon: hubspot_connection
    }
  ];

  /**
   * Configuration for the secret keys used in the form.
   * This includes the secret keys, alert description, and API endpoints.
   * The secret keys are used to securely store and retrieve the user's Marketo credentials.
   */
  const secretConfig: SecretConfig = {
    testEndpoint: "/secrets/test/hubspot",
    saveEndpoint: "/secrets",
    alertDescId: "SECRETS.ALERT.DESC.HUBSPOT",
    secretKeys: [["HS_PRIVATE_APP_TOKEN", "hubspot_private_app_token"]],
    optionalKeys: [
      ["HS_HUB_ID", "hubspot_hub_id"],
      ["HS_PUBLIC_APP_ID", "hubspot_public_app_id"],
      ["HS_PUBLIC_CLIENT_ID", "hubspot_public_client_id"],
      ["HS_PUBLIC_CLIENT_SECRET", "hubspot_public_client_secret"]
    ]
  };

  return (
    <ConnectionForm
      fieldConfigs={fieldsConfigs}
      validationSchema={hubspotValidationSchema}
      secretConfig={secretConfig}
      initialValues={connection_data_hubspot}
      isLoading={isLoading}
    />
  );
};

export default ConnectionHubspotForm;
