import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { ProgressionStatusMapModal } from '../modals/ProgressionStatusMapModal';
import InsSpinner from '../spinner/InsSpinner';

type ChannelsTableProps = {
  className?: string;
};

export default function ChannelsTable({ className } : ChannelsTableProps) {
  const [channels, setChannels] = useState<any[]>([]);
  const [applicableProgramTypes, setApplicableProgramTypes] = useState<string[]>([]);
  const [selectedProgramTypes, setSelectedProgramTypes] = useState<string[]>([]);
  const [progressionStatusMapModalChannel, setProgressionStatusMapModalChannel] = useState(undefined);
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const intl = useIntl();

  useEffect(() => {
    axios.get('/marketo/settings/marketo_channels').then((response) => {
      if (response.data) {
        setChannels(response.data);
        setApplicableProgramTypes(Array.from(new Set(response.data.map((channel: any) => channel.applicableProgramType))));
      }
    });
  }, []);

  const sortData = (field: any) => {
    const isAsc = sortField === field && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortField(field);
  };

  const renderSortIcon = (field: any) => {
    if (sortField !== field) {
      return <FontAwesomeIcon icon={faSort} aria-label={intl.formatMessage({ id: 'PAGINATION.SORT' })} />;
    }
    return sortDirection === 'asc' ? 
      (<FontAwesomeIcon icon={faSortUp} aria-label={intl.formatMessage({ id: 'PAGINATION.SORT.ASC' })} />) : 
      (<FontAwesomeIcon icon={faSortDown} aria-label={intl.formatMessage({ id: 'PAGINATION.SORT.DESC' })} />);
  };

  const filteredAndSortedChannels = React.useMemo(() => {
    if (!channels) return [];

    // Filter first based on selected program types
    let filtered = channels;
    if (selectedProgramTypes.length > 0) {
      filtered = channels.filter((channel: any) => selectedProgramTypes.includes(channel.applicableProgramType));
    }

    // Then sort the filtered list
    return filtered.sort((a, b) => {
      let aValue = a[sortField];
      let bValue = b[sortField];

      if (sortField === 'progressionStatuses') {
        aValue = aValue?.length || 0;
        bValue = bValue?.length || 0;
      }

      if (aValue < bValue) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [channels, selectedProgramTypes, sortField, sortDirection]);

  return (
    <div className={`card ${className}`}>
      <div className='card-body py-3'>
        <div className='card-header border-0 p-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {intl.formatMessage({id: 'SETTINGS.MKTO.PROGSTATUSMAP.PROGTYPES'})}
            </span>
          </h3>
        </div>

        <div className='row mb-10'>
          {applicableProgramTypes && applicableProgramTypes.map((programType) => (
            <div key={programType} className='col align-items-start channel-button-wrap'>
              <Button
                id={programType}
                className="channel-button"
                onClick={() => {
                  const currentIndex = selectedProgramTypes.indexOf(programType);
                  const newSelectedProgramTypes = [...selectedProgramTypes];

                  if (currentIndex === -1) {
                    newSelectedProgramTypes.push(programType);
                  } else {
                    newSelectedProgramTypes.splice(currentIndex, 1);
                  }

                  setSelectedProgramTypes(newSelectedProgramTypes);
                }}
                variant={selectedProgramTypes.includes(programType) ? 'primary' : 'secondary'}
                aria-pressed={selectedProgramTypes.includes(programType)}
              >
                <img 
                  src={`/media/icons/${programType}.svg`}
                  alt={programType}
                  style={{ marginRight: '10px' }}
                  className='channel-icon'
                />
                {intl.formatMessage({ id: `SETTINGS.MKTO.PROGSTATUSMAP.PROGTYPES.${programType.toUpperCase()}` })}
              </Button>
            </div>
          ))}
        </div>

        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th onClick={() => sortData('name')} className='sortable-column' scope="col">
                  {intl.formatMessage({ id: 'SETTINGS.MKTO.PROGSTATUSMAP.NAME' })}
                  {renderSortIcon('name')}
                </th>
                <th onClick={() => sortData('applicableProgramType')} className='sortable-column' scope="col">
                  {intl.formatMessage({ id: 'SETTINGS.MKTO.PROGSTATUSMAP.PROGRAMTYPE' })}
                  {renderSortIcon('applicableProgramType')}
                </th>
                <th onClick={() => sortData('progressionStatuses')} className='sortable-column' scope="col">
                  {intl.formatMessage({ id: 'SETTINGS.MKTO.PROGSTATUSMAP.PROGSTATUSES' })}
                  {renderSortIcon('progressionStatuses')}
                  </th>
                <th className='min-w-125px' scope='col' aria-label={intl.formatMessage({ id: 'FORM.EDIT' })}></th>
              </tr>
            </thead>
            <tbody>
              {filteredAndSortedChannels.length > 0 ? filteredAndSortedChannels.map((channel) => (
                <tr key={channel.id}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-50px me-5'>
                        {/* symbol content */}
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6' aria-label={`Channel name: ${channel.name}`}>
                          {channel.name}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          ID: {channel.id}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6' aria-label={`Program type: ${intl.formatMessage({ id: `SETTINGS.MKTO.PROGSTATUSMAP.PROGTYPES.${channel.applicableProgramType.toUpperCase()}` })}`}>
                      {intl.formatMessage({ id: `SETTINGS.MKTO.PROGSTATUSMAP.PROGTYPES.${channel.applicableProgramType.toUpperCase()}` })}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6' aria-label={`Progression statuses: ${channel.progressionStatuses?.length}`}>
                      {channel.progressionStatuses?.length}
                    </a>
                  </td>
                  <td className='text-end'>
                    <button
                      onClick={() => {setProgressionStatusMapModalChannel(channel)}}
                      className='btn btn-bg-dark btn-color-white btn-active-color-primary btn-sm px-10 me-2'
                      aria-label={intl.formatMessage({ id: 'SETTINGS.MKTO.PROGSTATUSMAP.EDITBTN' })}
                    >
                      {intl.formatMessage({id: 'SETTINGS.MKTO.PROGSTATUSMAP.EDITBTN'})}
                    </button>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan={4} className='text-center'>
                    <InsSpinner text={intl.formatMessage({ id: 'MISC.LOADING' })} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {progressionStatusMapModalChannel && (
            <ProgressionStatusMapModal
              show={progressionStatusMapModalChannel}
              channel={progressionStatusMapModalChannel}
              handleClose={() => setProgressionStatusMapModalChannel(undefined)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
