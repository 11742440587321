import axios, { Axios, AxiosError } from "axios";
import { useState, useEffect, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../localization/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../layout/core";
import { MasterInit } from "../layout/MasterInit";
import { AuthInit } from "./auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { FrigadeProvider } from "@frigade/react";
import * as Frigade from "@frigade/react";
import { apiGet } from "../helpers/apiHelpers";

import packageInfo from "../../package.json";

function setupAxios(axios: Axios) {
  axios.interceptors.request.use(
    (config) => {
      if (config.url?.startsWith("/")) {
        config.headers["Api-Version"] = packageInfo.backendVersion;
        config.headers["X-APP-Version"] = packageInfo.version;

        if (!config.url?.startsWith("/auth") && !config.url?.startsWith("/assets")) {
          config.baseURL = "/api";
        }
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      // Any status codes that fall outside the range of 2xx will cause this function to trigger
      if (error.response && error.response.status === 500) {
        console.error(error.response.data);
        return error.response;
      }
      return Promise.reject(error);
    }
  );
}

async function fetchUserInfo() {
  try {
    const response = await apiGet("/auth/me");
    if (!response) {
      throw new Error("No response from API");
    }
    return {
      instanceId: response.firebase_id || null, // TODO: Must be updated once we have the correct instance ID
      userId: response.firebase_id || null, // TODO: Replace with Firebase ID
      email: response.email || null,
      first_name: response.first_name || null,
      last_name: response.last_name || null
    };
  } catch (error) {
    console.error("Error fetching user info:", error);
    return {
      instanceId: null,
      userId: null,
      email: null,
      first_name: null,
      last_name: null
    };
  }
}

const App = () => {
  const [userInfo, setUserInfo] = useState({
    userId: undefined,
    instanceId: undefined,
    email: undefined,
    first_name: undefined,
    last_name: undefined
  });
  useEffect(() => {
    setupAxios(axios);
    fetchUserInfo().then((info) => {
      setUserInfo(info);
    });
  }, []);

  // Check if userId and instanceId are available before rendering FrigadeProvider
  const isUserInfoAvailable = userInfo.userId !== undefined && userInfo.instanceId !== undefined;

  return isUserInfoAvailable ? (
    <Frigade.Provider
      apiKey="api_public_oBoJRyzhBkBFDOuBVHwb2PGnXQQ2mPVt8rINdVl7uWCsIHrHDLELZoYqAucjfzdr"
      userId={userInfo.userId}
      userProperties={{
        firstName: userInfo.first_name,
        lastName: userInfo.last_name,
        email: userInfo.email,
        organizationId: userInfo.instanceId
      }}
      // organizationId={userInfo.instanceId}
    >
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
              <ToastContainer limit={5} />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </Frigade.Provider>
  ) : (
    <LayoutSplashScreen /> // Or any other placeholder/loading component
  );
};

export { App };
