import { KTIcon } from "../../../../helpers";
import { Tooltip } from "react-tooltip";
import { useIntl } from "react-intl";
import { useRolesView } from "../../core/RolesAndPermissionsProvider";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { deleteRole } from "../../core/_requests";

const MySwal = withReactContent(Swal);

type TableRoleActionsCellProps = {
  id: string;
};

const TableRoleActionsCell = ({ id }: TableRoleActionsCellProps) => {
  const intl = useIntl();

  const { setRoleIdForUpdate, refetch, permissionHasChanged, roles } = useRolesView();

  const handleEdit = () => {
    if (id === "Admin" || id === "User" || id === "Demo User") {
      console.log(`You can't edit ${id} role`);
      return;
    }

    if (permissionHasChanged) {
      MySwal.fire({
        title: "Unsaved changes",
        text: "You have unsaved changes. Are you sure you want to leave?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, leave",
        cancelButtonText: "No, stay and save"
      }).then(async (result) => {
        if (result.isConfirmed) {
          setRoleIdForUpdate((prevState) => {
            return { id: id, type: "EDIT_ROLE" };
          });
        }
      });
    } else {
      setRoleIdForUpdate((prevState) => {
        return { id: id, type: "EDIT_ROLE" };
      });
    }
  };

  const handleDelete = () => {
    if (id === "Admin" || id === "User" || id === "Demo User") {
      console.log(`You can't delete ${id} role`);
      return;
    }

    const role = roles.find((role) => role.id === id);
    const roleUsers = role?.users.length || 0;

    if (roleUsers > 0) {
      MySwal.fire(
        intl.formatMessage({ id: "ROLES.DELETEROLE.NOTEMPTY.TITLE" }),
        intl.formatMessage({ id: "ROLES.DELETEROLE.NOTEMPTY.ERRORMESSAGE" }),
        "error"
      );
      return;
    }

    MySwal.fire({
      title: intl.formatMessage({ id: "ROLES.DELETEROLE.TITLE" }),
      text: intl.formatMessage({ id: "ROLES.DELETEROLE.CONFIRMATION" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "ROLES.DELETEROLE.CONFIRMBTN" }),
      cancelButtonText: intl.formatMessage({ id: "BUTTON.CANCEL" })
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteRole(id);

        if (refetch) {
          refetch();
        }

        MySwal.fire(
          intl.formatMessage({ id: "ROLES.DELETEROLE.SUCCESS" }),
          intl.formatMessage({ id: "ROLES.DELETEROLE.SUCCESSMESSAGE" }),
          "success"
        );
      }
    });
  };

  return (
    <>
      {id !== "Admin" && id !== "User" && id !== "Demo User" ? (
        <div className="d-flex justify-content-start">
          <div className="menu-item px-3">
            <button
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={handleEdit}
              data-tooltip-id={`edit-role-tooltip-${id}`}
              data-tooltip-content={intl.formatMessage({ id: "ROLES.TABLE.ACTIONS.TOOLTIP.EDIT" })}
              data-tooltip-place="bottom"
              aria-label={intl.formatMessage({ id: "ROLES.TABLE.ACTIONS.TOOLTIP.EDIT" })}
              aria-describedby={`edit-role-tooltip-${id}`}
            >
              <KTIcon iconName="pencil" className="fs-2 m-0 actionIcon" />
            </button>
            <Tooltip id={`edit-role-tooltip-${id}`} style={{ zIndex: 100 }} />
          </div>

          <div className="menu-item px-3">
            <button
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={handleDelete}
              data-tooltip-id={`delete-role-tooltip-${id}`}
              data-tooltip-content={intl.formatMessage({ id: "ROLES.TABLE.ACTIONS.TOOLTIP.DELETE" })}
              data-tooltip-place="bottom"
              aria-label={intl.formatMessage({ id: "ROLES.TABLE.ACTIONS.TOOLTIP.DELETE" })}
              aria-describedby={`delete-role-tooltip-${id}`}
            >
              <KTIcon iconName="trash" className="fs-2 m-0 actionIcon" />
            </button>
            <Tooltip id={`delete-role-tooltip-${id}`} style={{ zIndex: 1000 }} />
          </div>
        </div>
      ) : (
        <>
          <div
            className="badge text-bg-warning rounded-pill fw-normal p-2"
            data-tooltip-id="system_default_role"
            data-tooltip-content={intl.formatMessage({ id: "ROLES.SYSTEM.TOOLTIP" })}
            data-tooltip-place="bottom"
          >
            {intl.formatMessage({ id: "ROLES.SYSTEM.ROLE" })}
          </div>
          <Tooltip id="system_default_role" />
        </>
      )}
    </>
  );
};

export { TableRoleActionsCell };
