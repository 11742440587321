import { useIntl } from "react-intl";
import { useRolesView } from "../../core/RolesAndPermissionsProvider";
import PermissionsAppBlock from "./PermissionsAppBlock";

type PermissionsAppSectionProps = {
  app: string;
};

// Mapping for custom app names
const appDisplayNameMap: Record<string, string> = {
  superset: "Reporting", // Map "Superset" to "Reporting"
};

const getAppDisplayName = (app: string): string => {
  return appDisplayNameMap[app.toLowerCase()] || app;
};

const PermissionsAppSection = ({ app }: PermissionsAppSectionProps) => {
  const { permissions } = useRolesView();
  const intl = useIntl();

  const appName = getAppDisplayName(app.charAt(0).toUpperCase() + app.slice(1));

  // Extract resources and map their names
  const resources = permissions.reduce<string[]>((acc, permission) => {
    if (permission.application === app && !acc.includes(permission.resource)) {
      acc.push(permission.resource);
    }
    return acc;
  }, []);

  return (
    <>
      <div className="d-flex align-items-start border-bottom border-primary border-2 mb-3 mt-6 fs-1">
        <span className="text-primary fw-bold mb-1">{appName}</span>
        &nbsp;{intl.formatMessage({ id: "ROLES.PERMISSIONS.HEADER.SECTIONTITLE" })}
      </div>
      <div className="d-flex flex-column w-100">
        {resources &&
          resources.length > 0 &&
          resources.map((resource) => (
            <PermissionsAppBlock
              key={resource}
              resource={resource}
              app={app}
            />
          ))}
      </div>
    </>
  );
};

export default PermissionsAppSection;
