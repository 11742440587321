import { KTIcon } from "../../../../helpers";
import "../style/MultiSelectDropdownWithSearch.css";

type FieldsWithSearchDropdownButtonProps = {
  children: any;
  onClick: (e: any) => void;
  removeAll: (e: any) => void;
  error?: string;
};

const FieldsWithSearchDropdownButton = ({
  children,
  onClick,
  removeAll,
  error
}: FieldsWithSearchDropdownButtonProps) => {
  let btnClass = "MSD__btn w-100";
  if (error) {
    btnClass = "MSD__btn w-100 MSD__error";
  }
  return (
    <div className={btnClass} role="textbox" tabIndex={0} onClick={onClick} onKeyDown={onClick}>
      <div className="d-flex w-100 justify-content-between">
        <div className="d-flex flex-row gap-2 flex-wrap">{children}</div>
        <div className="d-flex">
          <div
            className="h-100 d-flex align-items-center"
            role="button"
            tabIndex={0}
            onClick={removeAll}
            onKeyDown={removeAll}
          >
            <KTIcon iconName="cross" iconType="duotone" className="MSD__cross-icon" />
          </div>
          <div className="MSD__separator"></div>
          <div className="h-100 d-flex align-items-center">
            <KTIcon iconName="down" iconType="duotone" className="MSD__down-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldsWithSearchDropdownButton;
