import { useIntl } from "react-intl";

type FormFieldRunInTwoHoursProps = {
  errors: any;
  touched: any;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  editPermission: boolean;
};

const FormFieldRunInTwoHours = ({
  errors,
  touched,
  values,
  setFieldValue,
  editPermission
}: FormFieldRunInTwoHoursProps) => {
  const intl = useIntl();
  return (
    <div className="m-3">
      <div className="mb-6">
        <div className="form-check form-switch form-check-custom form-check-solid justify-content-between align-items-center">
          <span className="fs-5 fw-bold" aria-hidden="true">
            {intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.RUNINTWOHOURS.TITLE"
            })}
          </span>
          <label htmlFor="mkto_qs_appender_run_in_two_hours" className="form-check-label sr-only">
            {intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.RUNINTWOHOURS.TITLE"
            })}
          </label>
          <input
            id="mkto_qs_appender_run_in_two_hours"
            name="mkto_qs_appender_run_in_two_hours"
            type="checkbox"
            className="form-check-input"
            value={String(values.mkto_qs_appender_run_in_two_hours)}
            checked={values.mkto_qs_appender_run_in_two_hours}
            onChange={(e: any) => {
              setFieldValue("mkto_qs_appender_run_in_two_hours", e.target.checked ? true : false);
            }}
            aria-describedby="mkto_qs_appender_run_in_two_hours_description"
            disabled={!editPermission}
          />
        </div>
        <div id="mkto_qs_appender_run_in_two_hours_description" className="text-muted mt-3">
          {intl.formatMessage({
            id: "SETTINGS.MKTO.QSAPPEND.RUNINTWOHOURS.DESC"
          })}
        </div>
      </div>
      {!!errors.mkto_qs_appender_run_in_two_hours && touched.mkto_qs_appender_run_in_two_hours ? (
        <p className="text-danger">
          {intl.formatMessage({
            id: errors.mkto_qs_appender_run_in_two_hours
          })}
        </p>
      ) : null}
    </div>
  );
};

export default FormFieldRunInTwoHours;
