import React, { useEffect, useState } from "react";
import { Form as BootstrapForm } from "react-bootstrap";
import { useField } from "formik";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import Creatable from "react-select/creatable";
import { ThemeModeComponent } from "../../../../assets/ts/layout";
import { handleRefreshClick } from "../helper/helpers";
import styles from "../style/MultiSelectDropdownWithInput.module.css";

/**
 * Props for the MultiSelectDropdownWithInput component
 */
type MultiSelectDropdownWithInputProps = {
  id: string; // The id of the field
  label?: string; // The label for the field
  description?: string; // The description for the field
  optional?: boolean; // Whether the field is optional or not
  options?: { value: string; label: string }[]; // The options for the field
  showRefreshButton?: boolean; // Whether to show the refresh button or not
  refreshType?: string | null; // The type of refresh to perform, use OBJECT_KEYS from apiHelpers.ts
  classes?: string; // Any additional classes to apply to the field
  hideLabel?: boolean; // Whether to hide the label or not
  hideDescription?: boolean; // Whether to hide the description or not
  ariaLabelledBy?: string; // The id of the element that labels the field
  ariaDescribedBy?: string; // The id of the element that describes the field
  disabled?: boolean; // Whether the field is disabled or not
  useEmailDomainHandlers?: boolean; // Whether to use newer email domain handlers
};

/**
 * Single select dropdown with Input field component that uses the Creatable "react-select" component to render a single select dropdown input field with an input option.
 *
 * @prop {string} id - The id of the field
 * @prop {string} [label=""] - The label for the field
 * @prop {string} [description=""] - The description for the field
 * @prop {boolean} [optional=false] - Whether the field is optional or not
 * @prop {{ value: string; label: string }[]} [options=[]] - The options for the field
 * @prop {boolean} [showRefreshButton=false] - Whether to show the refresh button or not
 * @prop {string} [refreshType] - The type of refresh to perform, use OBJECT_KEYS from apiHelpers.ts
 * @prop {string} [classes=""] - Any additional classes to apply to the field
 * @prop {boolean} [hideLabel=false] - Whether to hide the label or not
 * @prop {boolean} [hideDescription=false] - Whether to hide the description or not
 * @prop {string} [ariaLabelledBy] - The id of the element that labels the field
 * @prop {string} [ariaDescribedBy] - The id of the element that describes the field
 * @prop {boolean} [disabled=false] - Whether the field is disabled or not
 * @prop {boolean} [useEmailDomainHandlers=false] - Whether to use the email domain-specific handlers
 */
export const MultiSelectDropdownWithInput = ({
  id,
  label = "",
  description = "",
  options = [],
  optional = false,
  showRefreshButton = false,
  refreshType = null,
  classes = "",
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  disabled = false,
  useEmailDomainHandlers = false,
  ...props
}: MultiSelectDropdownWithInputProps) => {
  const [, meta, helpers] = useField(id); // Connects the component to Formik
  const intl = useIntl(); // For internationalized strings
  const { value } = meta; // The current value of the field from Formik
  const { setValue } = helpers; // Function to update the field's value in Formik
  const [isRefreshing, setIsRefreshing] = useState(false); // Tracks the refresh state
  const [themeMode, setThemeMode] = useState(ThemeModeComponent.getMode()); // Tracks the theme mode

  const [errorMsg, setErrorMsg] = useState(""); // Tracks the error message for the field
  const fieldError = meta.error; // Field-level error from Formik validation
  const fieldTouched = meta.touched; // Whether the field has been touched

  // Styles for the error border of the Creatable component
  let errorRedBorder: any = {
    control: (styles: any) => ({
      ...styles,
      borderColor: "#F1416C",
      "&:hover": { borderColor: "#F1416C" },
    }),
  };

  // Updates the error message whenever validation state changes
  useEffect(() => {
    if (fieldError && fieldTouched) {
      setErrorMsg(intl.formatMessage({ id: fieldError }));
    } else {
      setErrorMsg("");
    }
  }, [fieldError, fieldTouched]);

  // General sanitization for email domain inputs
  const sanitizedEmailDomainInput = (value: string): string => {
    return value.startsWith("@") ? value.slice(1) : value;
  };

  // Newer handlers specific to email domains
  const handleEmailDomainChange = (selectedOptions: any) => {
    const sanitizedOptions = selectedOptions.map((option: any) => ({
      value: sanitizedEmailDomainInput(option.value),
      label: sanitizedEmailDomainInput(option.label),
    }));
    setValue(sanitizedOptions);
  };

  const handleCreateEmailDomainOption = (inputValue: string) => {
    const sanitizedInput = sanitizedEmailDomainInput(inputValue);
    const newOption = { value: sanitizedInput, label: sanitizedInput };
    setValue([...value, newOption]);
  };

  // Legacy handlers
  const handleChange = (selectedOptions: any) => {
    setValue(selectedOptions);
  };

  const handleCreateOption = (inputValue: string) => {
    const newOption = { value: inputValue, label: inputValue };
    setValue([...value, newOption]);
  };

  // Dynamically choose handlers based on the prop
  const onChangeHandler = useEmailDomainHandlers
    ? handleEmailDomainChange
    : handleChange;
  const onCreateOptionHandler = useEmailDomainHandlers
    ? handleCreateEmailDomainOption
    : handleCreateOption;

  // Updates the theme mode when it changes
  useEffect(() => {
    setThemeMode((prevState) => ThemeModeComponent.getMode());
  }, [ThemeModeComponent.getMode()]);

  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label !== "" && (
        <BootstrapForm.Label id={`${id}-label`}>
          {`${label}${optional ? " (optional)" : ""}`}
        </BootstrapForm.Label>
      )}
      <div className="d-flex align-items-center flex-help">
        <Creatable
          id={id}
          name={id}
          isMulti
          value={value}
          onChange={onChangeHandler}
          onCreateOption={onCreateOptionHandler}
          options={options}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
          noOptionsMessage={() => "Type to add new option"}
          className={classes}
          aria-labelledby={ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)}
          aria-describedby={ariaDescribedBy || (description ? `${id}-description` : "")}
          isDisabled={disabled}
          {...props}
          styles={errorMsg ? errorRedBorder : {}}
          theme={(theme) => ({
            ...theme,
            borderRadius: 4,
            colors: {
              ...theme.colors,
              neutral80: themeMode === "dark" ? "#ffffff" : "#333333",
            },
          })}
        />
        {showRefreshButton && refreshType && (
          <FontAwesomeIcon
            icon={faSync}
            onClick={() => handleRefreshClick(refreshType, setIsRefreshing, intl)}
            className={isRefreshing ? `${styles.refreshBtnIcon} spin` : `${styles.refreshBtnIcon}`}
          />
        )}
      </div>
      {errorMsg && <p className="text-danger mb-0 mt-2">{errorMsg}</p>}
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

export default MultiSelectDropdownWithInput;
