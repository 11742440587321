import { useState } from "react";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { toastSettings } from "../../../layout/components/toast/Toast";
import axios from "axios";
import { useAuth } from "../../../pages/auth";
import { hasEditPermission } from "../../permissions";

const ObjectSetupSaveBtn = ({
  platformEndpoint,
  platform,
  ...props
}: {
  platformEndpoint: any;
  platform: any;
  object: string;
  updatedObject: any;
  hasChange: boolean;
}) => {
  const intl = useIntl();
  const { currentUser } = useAuth();

  let editPermission = false;
  switch (platform) {
    case "SFDC":
      editPermission = hasEditPermission(currentUser, "salesforce", "fields");
      break;
    case "MKTO":
      editPermission = hasEditPermission(currentUser, "marketo", "fields");
      break;
  }

  const [savingSpinner, setSavingSpinner] = useState<boolean>(false);

  const saveConfig = async () => {
    // Check if user has permission to edit marketo or sfdc fields before submitting
    if (!editPermission) {
      console.error(intl.formatMessage({ id: "PERMISSIONS.ERRORMESSAGE.NOEDITPERMISSION" }));
      return;
    }

    setSavingSpinner(true);

    // Create the payload object based on the incoming props
    const payload = {
      [props.object]: props.updatedObject
    };
    try {
      // Update Firebase with the new object
      await axios.post(`/schema/${platformEndpoint}`, payload);
      setSavingSpinner((prevState) => !prevState);
      toast.success(intl.formatMessage({ id: "SETTINGS.TOAST.SUCCESS" }), toastSettings("success"));
    } catch (error) {
      console.log(error);
      setSavingSpinner((prevState) => !prevState);
    }
  };

  return (
    <button
      type="button"
      onClick={saveConfig}
      className="btn btn-primary me-2"
      disabled={!props.hasChange || !editPermission}
    >
      {!savingSpinner && <span className="indicator-label">{intl.formatMessage({ id: "BUTTON.SAVE" })}</span>}
      {savingSpinner && (
        <span className="indicator-progress" style={{ display: "block" }}>
          {intl.formatMessage({ id: "FORM.PLEASEWAIT" })}{" "}
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      )}
    </button>
  );
};

export default ObjectSetupSaveBtn;
