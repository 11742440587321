import { DateField } from "../../../../../layout/components/form";
import { useIntl } from "react-intl";

type FormFieldStartDateProps = {
  errors: any;
  touched: any;
  editPermission: boolean;
};

const FormFieldStartDate = ({ errors, touched, editPermission }: FormFieldStartDateProps) => {
  const intl = useIntl();
  return (
    <div className="m-3 flex-fill">
      <div className="d-flex justify-content-between align-items-start flex-column flex-sm-row">
        <div>
          <span className="fs-5 fw-bold" id="start-date-label">
            {intl.formatMessage({ id: "SETTINGS.MKTO.QSAPPEND.STARTDATE" })}
          </span>
          <div className="text-muted mb-3" id="start-date-description">
            {intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.STARTDATE.DESC"
            })}
          </div>
        </div>
        <div>
          <DateField
            id="mkto_qs_appender_start_date"
            label={intl.formatMessage({ id: "SETTINGS.MKTO.QSAPPEND.STARTDATE" })}
            hideLabel={true}
            description=""
            ariaLabelledBy="start-date-label"
            ariaDescribedBy="start-date-description"
            hideDescription={true}
            classes={errors.mkto_qs_appender_start_date && touched.mkto_qs_appender_start_date ? "border-danger" : ""}
            disabled={!editPermission}
          />
          {!!errors.mkto_qs_appender_start_date && touched.mkto_qs_appender_start_date ? (
            <p className="text-danger">
              {intl.formatMessage({
                id: errors.mkto_qs_appender_start_date
              })}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FormFieldStartDate;
