import axios from "axios";
import { toast } from "react-toastify";
import { toastSettings } from "../../../layout/components/toast/Toast";

const createInsentricJS = async (payload: any) => {
  let responseInfo;
  await axios
    .post("/insentric-js", payload)
    .then((response) => {
      toast.success("Successfull submit", toastSettings("success"));
      responseInfo = "success";
    })
    .catch((error) => {
      toast.error(`Error : ${error}`, toastSettings("error"));
      responseInfo = "error";
      console.log("Error : ", error);
    });
  return responseInfo;
};

const updateInsentricJS = async (id: any, payload: any) => {
  let responseInfo;

  await axios
    .put(`/insentric-js/${id}`, payload)
    .then((response) => {
      toast.success(`The file with ${id} ID has been updated`, toastSettings("success"));
      responseInfo = "success";
    })
    .catch((error) => {
      toast.error(`Error : ${error}`, toastSettings("error"));
      responseInfo = "error";
      console.log("Error : ", error);
    });
  return responseInfo;
};

const getSourceTrackers = async () => {
  const response = await axios
    .get("/insentric-js")
    .then((response: any) => {
      const formatedResponse = response.data.map((file: any) => {
        return {
          ...file,
          fileLink: `https://cdn.insentric.net/${file.id}.js`,
          embedCode: `<script src="https://cdn.insentric.net/${file.id}.js"></script>`
        };
      });
      return formatedResponse;
    })
    .catch((error: any) => {
      console.error("Error : ", error);
    });

  return response;
};

export { createInsentricJS, updateInsentricJS, getSourceTrackers };
