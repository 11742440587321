import { useIntl } from "react-intl";
import ActionsCopy from "../ActionsCopy";
import ActionsUpdate from "../ActionsUpdate";
import ActionsDelete from "../ActionsDelete";
import { useInsJSFilesRequest } from "../../../core/InsJSFilesProvider";

type Props = {
  id: string;
  embedCode: string;
  domain: string;
};

const TableInsJsActionsCell = ({ embedCode, id, domain }: Props) => {
  const { handleCopyEmbedCode, showFileLinkCopied } = useInsJSFilesRequest();

  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="d-flex align-items-center">
        <ActionsCopy
          key={id}
          handleOnClick={() => handleCopyEmbedCode(embedCode, id)}
          showCopied={showFileLinkCopied === id}
          index={id}
          domain={domain}
        />
        <ActionsUpdate jsFileId={id} />
        <ActionsDelete jsFileId={id} />
      </div>
    </div>
  );
};

export { TableInsJsActionsCell };
