import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ID, InsJSFilesContextProps, initialInsJSFilesRequest, pmcfOrLeadFields } from "./_models";
import { sortFiles } from "./_helpers";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getSourceTrackers } from "./_requests";

const InsJSFilesContext = createContext<InsJSFilesContextProps>(initialInsJSFilesRequest);

const InsJSFilesProvider = ({ children }: { children: any }) => {
  const [trackerFiles, setTrackerFiles] = useState<object[]>([]);
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialInsJSFilesRequest.itemIdForUpdate);
  const [isInitialAndConversion, setIsInitialAndConversion] = useState<boolean>();
  const [pmcfOrLeadFields, setPmcfOrLeadFields] = useState<pmcfOrLeadFields>("program_member");
  const [showFileLinkCopied, setShowFileLinkCopied] = useState<string>("");
  const [actionsObject, setActionsObject] = useState<any>({
    sort: {
      column: "domain",
      order: "asc"
    }
  });

  const {
    isFetching,
    refetch,
    data: response
  } = useQuery({
    queryKey: ["Fetch all Source Tracker Files"],
    queryFn: getSourceTrackers,
    refetchOnMount: false,
    gcTime: 3600 * 1000,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  // Memoize Source Tracker fiedls to avoid unnecessary re-renders
  const fetchedSourceTrackers = useMemo(() => response || [], [response]);

  useEffect(() => {
    const sortedFiles = sortFiles(fetchedSourceTrackers, actionsObject.sort.order, actionsObject.sort.column, "domain");
    setTrackerFiles(sortedFiles);
  }, [actionsObject, fetchedSourceTrackers]);

  const handleCopyEmbedCode = async (fileEmbedCode: any, fileID: string) => {
    try {
      await navigator.clipboard.writeText(fileEmbedCode);
      setShowFileLinkCopied(fileID);
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      setShowFileLinkCopied("");
    }, 1500);
  };

  return (
    <InsJSFilesContext.Provider
      value={{
        trackerFiles,
        refetch,
        isFetching,
        itemIdForUpdate,
        setItemIdForUpdate,
        isInitialAndConversion,
        setIsInitialAndConversion,
        pmcfOrLeadFields,
        setPmcfOrLeadFields,
        handleCopyEmbedCode,
        showFileLinkCopied,
        setShowFileLinkCopied,
        actionsObject,
        setActionsObject
      }}
    >
      {children}
    </InsJSFilesContext.Provider>
  );
};

const useInsJSFilesRequest = () => useContext(InsJSFilesContext);

export { InsJSFilesProvider, useInsJSFilesRequest };
