import { useEffect, useState } from "react";
import RefreshCardUpdateRow from "./RefreshCardUpdateRow";
import { useRefreshData } from "../core/RefreshDataProvider";
import { refreshOptionsObject } from "../core/_models";
import { useIntl } from "react-intl";

type RefreshCardProps = {
  app: string;
};

const RefreshCard = ({ app }: RefreshCardProps) => {
  const intl = useIntl();
  const {
    mktoBasicInfo,
    sfdcBasicInfo,
    mktoRefreshOptions,
    sfdcRefreshOptions,
    mktoAssetsInfo,
    mktoAssetsOptions,
    isLoading
  } = useRefreshData();

  const [options, setOptions] = useState<refreshOptionsObject[]>([]);
  const [basicInfo, setBasicInfo] = useState({ logo: "", title: "", description: "", app: "" });

  useEffect(() => {
    switch (app) {
      case "mkto":
        setOptions(mktoRefreshOptions);
        setBasicInfo(mktoBasicInfo);
        break;
      case "sfdc":
        setOptions(sfdcRefreshOptions);
        setBasicInfo(sfdcBasicInfo);
        break;
      case "mktoAssets":
        setOptions(mktoAssetsOptions);
        setBasicInfo(mktoAssetsInfo);
        break;
      default:
        break;
    }
  }, [app, mktoBasicInfo, sfdcBasicInfo, mktoRefreshOptions, sfdcRefreshOptions, mktoAssetsInfo, mktoAssetsOptions]);

  return (
    <div className="card w-100 h-100">
      <div className="card-header border-bottom pt-5 justify-content-start align-items-start flex-nowrap gap-5 flex-column">
        <div className="d-flex gap-5 align-items-center">
          <img
            src={basicInfo.logo}
            alt={basicInfo.title && intl.formatMessage({ id: basicInfo.title })}
            style={{ width: "auto", height: 30 }}
          />
          <h2 className="card-title text-gray-800 fw-bold fs-2">
            {basicInfo.title && intl.formatMessage({ id: basicInfo.title })}
          </h2>
        </div>
        <div>
          <p className="text-muted">{basicInfo.description && intl.formatMessage({ id: basicInfo.description })}</p>
        </div>
      </div>
      <div className="card-body pt-5 mt-6">
        {options.length > 0 &&
          options.map((option, index) => (
            <div key={`${app}-${index}`}>
              <RefreshCardUpdateRow id={option.id} />
              {index < options.length - 1 && <div className="separator separator-dashed my-3"></div>}
            </div>
          ))}
      </div>
    </div>
  );
};

export default RefreshCard;
