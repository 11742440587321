import { useIntl } from "react-intl";
import { useRolesView } from "../../core/RolesAndPermissionsProvider";

type PermissionRowProps = {
  id: string;
  name: string;
  description: string;
  readId: string | null;
  editId: string | null;
  triggerId: string | null; // Generic trigger
};

// Assuming RolePayload is the type used for `updateRoleObject`
type RolePayload = {
  permissions: string[];
  name: string; // Replace with actual properties of RolePayload
  description: string; // Replace with actual properties of RolePayload
  [key: string]: any; // Allow additional properties if necessary
};

const PermissionRow = ({ id, name, description, readId, editId, triggerId }: PermissionRowProps) => {
  const intl = useIntl();
  const { updateRoleObject, setUpdateRoleObject, setPermissionHasChanged, isSubmitting } = useRolesView();

  const handleToggle = (permissionId: string) => {
    setPermissionHasChanged(true);
    setUpdateRoleObject((prev: RolePayload) => {
      const isEnabled = prev.permissions.includes(permissionId);
      return {
        ...prev,
        permissions: isEnabled
          ? prev.permissions.filter((perm: string) => perm !== permissionId)
          : [...prev.permissions, permissionId]
      };
    });
  };

  // Retrieve dynamic name and description using `intl.formatMessage`
  const dynamicName = intl.formatMessage(
    { id: `ROLES.PERMISSIONS.NAME.${id.toUpperCase()}` },
    { defaultMessage: name }
  );

  const dynamicDescription = intl.formatMessage(
    { id: `ROLES.PERMISSIONS.DESC.${id.toUpperCase()}` },
    { defaultMessage: description }
  );

  const isReadEnabled = readId ? updateRoleObject.permissions.includes(readId) : false;
  const isEditEnabled = editId ? updateRoleObject.permissions.includes(editId) : false;
  const isTriggerEnabled = triggerId ? updateRoleObject.permissions.includes(triggerId) : false;

  return (
    <div className="d-flex flex-column flex-sm-row justify-content-between w-100 align-items-start gap-0 gap-sm-10 p-3 hover">
      <div className="text-start">
        <label htmlFor={id} className="fw-bold fs-5 text-primary">
          {dynamicName}
        </label>
        <p id={`${id}-description`} className="fs-6 text-muted">
          {dynamicDescription}
        </p>
      </div>
      <div className="d-flex flex-column align-items-start gap-6 min-w-100px">
        {triggerId ? (
          <div className="form-check form-switch">
            <label htmlFor={`${id}-activate`} className="form-check-label">
              Activate
            </label>
            <input
              type="checkbox"
              className="form-check-input"
              id={`${id}-activate`}
              disabled={isSubmitting}
              checked={isTriggerEnabled}
              onChange={() => handleToggle(triggerId)}
            />
          </div>
        ) : (
          <>
            <div className="form-check form-switch">
              <label htmlFor={`${id}-read`} className="form-check-label">
                Read
              </label>
              <input
                type="checkbox"
                className="form-check-input"
                id={`${id}-read`}
                disabled={isSubmitting}
                checked={isReadEnabled}
                onChange={() => readId && handleToggle(readId)}
              />
            </div>
            <div className="form-check form-switch">
              <label htmlFor={`${id}-edit`} className="form-check-label">
                Edit
              </label>
              <input
                type="checkbox"
                className="form-check-input"
                id={`${id}-edit`}
                disabled={isSubmitting}
                checked={isEditEnabled}
                onChange={() => editId && handleToggle(editId)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PermissionRow;
