// @ts-nocheck
/* eslint react/prop-types: 0 */
import { Column } from "react-table";
import { TableRoleNameCell } from "./TableRoleNameCell";
import { TableCustomHeader } from "./TableCustomHeader";
import { TableRoleUsersCell } from "./TableRoleUsersCell";
import { TableRoleActionsCell } from "./TableRoleActionsCell";
import { Role } from "../../core/_models";

const rolesColumns: ReadonlyArray<Column<Role>> = [
  {
    Header: (props: any) => (
      <TableCustomHeader tableProps={props} title="ROLES.TABLE.HEADER.ROLENAME" className="min-w-125px" />
    ),
    id: "roles",
    accessor: "name",
    Cell: ({ ...props }) => <TableRoleNameCell role={props.row.original.name} desc={props.row.original.description} />
  },
  {
    Header: (props: any) => (
      <TableCustomHeader tableProps={props} title="ROLES.TABLE.HEADER.USERS" className="min-w-125px" />
    ),
    id: "users",
    accessor: "users",
    Cell: ({ ...props }) => <TableRoleUsersCell users={props.row.original.users} id={props.row.original.id} />
  },
  {
    Header: (props: any) => (
      <TableCustomHeader tableProps={props} title="ROLES.TABLE.HEADER.ACTIONS" className="text-center w-125px" />
    ),
    id: "actions",
    accessor: "actions",
    Cell: ({ ...props }) => <TableRoleActionsCell id={props.row.original.id || ""} />
  }
];

export { rolesColumns };
