import { useIntl } from "react-intl";
import { TextField } from "../../../layout/components/form";
import { useAuth } from "../../auth";
import { hasEditPermission } from "../../../helpers/permissions";

const GenSettingsCompanyName = ({ errors, touched }: { errors: any; touched: any }) => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const account_settings_edit_permission = hasEditPermission(currentUser, "account", "settings");
  
  return (
    <div className="row">
      <div className="col-lg-4 p-3 mb-3">
        <span className="fw-bold fs-4 text-muted" id="company_name-label">
          {intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.COMPANYNAME" })}
        </span>
      </div>
      <div className="col-lg-8 p-3 mb-3">
        <TextField
          id="company_name"
          label=""
          description=""
          placeholder={intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.COMPANYNAME.PLACEHOLDER" })}
          optional={false}
          isInvalid={errors.company_name && touched.company_name}
          ariaLabelledBy="company_name-label"
          ariaDescribedBy=""
          disabled={!account_settings_edit_permission}
        />
      </div>
    </div>
  );
};

export default GenSettingsCompanyName;
