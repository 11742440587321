import { useState } from "react";
import { KTIcon } from "../../../../helpers";
import axios from "axios";
import { useRefreshData } from "../core/RefreshDataProvider";
import { useIntl } from "react-intl";
import { toast, Zoom } from "react-toastify";
import { useAuth } from "../../../auth";
import { hasFetchPermission } from "../../../../helpers/permissions";

type RefreshCardUpdateRowProps = {
  id: string;
};

const RefreshCardUpdateRow = ({ id }: RefreshCardUpdateRowProps) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const { mktoRefreshOptions, sfdcRefreshOptions, mktoAssetsOptions, refetch } = useRefreshData();
  const { currentUser } = useAuth();
  const refresh_marketo = hasFetchPermission(currentUser, "marketo", "data");
  const refresh_sfdc = hasFetchPermission(currentUser, "salesforce", "data");

  const option =
    mktoRefreshOptions.find((option) => option.id === id) ||
    sfdcRefreshOptions.find((option) => option.id === id) ||
    mktoAssetsOptions.find((option) => option.id === id);

  const handleRefresh = async () => {
    setLoading(true);

    if (option?.refreshAPI) {
      await axios
        .post(option.refreshAPI || "", option.payload || {})
        .then((response) => {
          if (response.status === 200) {
            toast.success(intl.formatMessage({ id: "REFRESHDATA.RESPONSE.SUCCESS" }), {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom
          });
          setLoading(false);
        });
    }
  };

  const lastSuccessFullRefreshDate = option?.lastSuccessFullRefresh
    ? new Date(option?.lastSuccessFullRefresh).toLocaleDateString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      })
    : "Never Refreshed";

  return (
    <>
      {option && Object.keys(option).length > 0 && (
        <div key={`${option.title}-${Math.random()}`} className="d-flex flex-stack align-items-start">
          <div className="d-flex justify-content-start align-items-start gap-5">
            {option.assetIcon && <KTIcon iconName={option.assetIcon} className="fs-1 text-primary" />}
            <div>
              <p className="fs-4 fw-bold text-primary">{intl.formatMessage({ id: option.title })}</p>
              <p className="fs-6 fw-normal fst-italic">{intl.formatMessage({ id: option.description })}</p>
              <p className="fs-6 fw-light text-muted fst-italic">
                {option?.lastSuccessFullRefresh && option?.lastSuccessFullRefresh.length > 0
                  ? `${intl.formatMessage({
                      id: "REFRESHDATA.SUCCESSFULLREFRESHDATE"
                    })}: ${lastSuccessFullRefreshDate.toLocaleString()}`
                  : null}
              </p>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-light-primary btn-sm justify-content-end text-nowrap"
            onClick={handleRefresh}
            disabled={loading || !refresh_marketo || !refresh_sfdc}
          >
            {!loading ? (
              <>
                <KTIcon iconName="arrows-circle" /> {intl.formatMessage({ id: option.buttonText })}
              </>
            ) : (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "REFRESHDATA.BUTTONTEXT.LOADING" })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default RefreshCardUpdateRow;
