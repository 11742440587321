import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";
import { Form as BootstrapForm } from "react-bootstrap";
import { ErrorMessage, useField } from "formik";
import { handleRefreshClick } from "../helper/helpers";
import "../style/MultiSelectDropdownWithSearch.css";

import Select, { StylesConfig } from "react-select";
import { useIntl } from "react-intl";
import FieldWithSearchDropdown from "../components/FieldWithSearchDropdown";
import DropdownIndicator from "../components/DropdownIndicator";
import FieldsWithSearchDropdownButton from "../components/FieldsWithSearchDropdownButton";
import FieldWithSearchRemoveOptions from "../components/FieldWithSearchRemoveOptions";
import { handleOutsideClick, handleEscKeydown, throttle } from "../helper/fieldHelpers";

const selectStyles: StylesConfig<any, false> = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    margin: 8
  }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" })
};

type MultiSelectDropdownProps = {
  id: string;
  label?: string;
  description?: string;
  options?: { value: string; label: string }[];
  selectedoption?: string;
  showRefreshButton?: boolean;
  refreshType?: string;
  classes?: string;
  isDisabled?: boolean;
  hideLabel?: boolean;
  hideDescription?: boolean;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
};

/**
 * Multi select dropdown field with Search input component that uses the "react-select" component to render a multi select dropdown input field.
 *
 * @prop {string} id - The id of the field
 * @prop {string} [label=""] - The label for the field
 * @prop {string} [description=""] - The description for the field
 * @prop {{ value: string; label: string }[]} [options=[]] - The options for the field
 * @prop {string} [selectedOption=""] - The selected option for the field
 * @prop {boolean} [showRefreshButton=false] - Whether to show the refresh button or not
 * @prop {string} [refreshType] - The type of refresh to perform
 * @prop {string} [classes=""] - Any additional classes to apply to the field
 * @prop {boolean} [isDisabled=false] - Whether the field is disabled or not
 * @prop {boolean} [hideLabel=false] - Whether to hide the label or not
 * @prop {boolean} [hideDescription=false] - Whether to hide the description or not
 * @prop {string} [ariaLabelledBy] - The id of the element that labels the field
 * @prop {string} [ariaDescribedBy] - The id of the element that describes the field
 * @prop {string} [props] - Any additional props to pass to the field
 */

const MultiSelectDropdownWithSearch = ({
  id,
  label = "",
  description = "",
  options = [],
  selectedoption = "",
  showRefreshButton = false,
  refreshType,
  classes = "",
  isDisabled = false,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}: MultiSelectDropdownProps) => {
  const [, meta, helpers] = useField(id);
  const intl = useIntl();
  const { value } = meta;
  const { setValue } = helpers;
  const [isOpen, setIsOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [displayValues, setDisplayValues] = useState<any[]>([]);
  const [dropDownOptions, setDropDownOptions] = useState<any>(options);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const placeholder = intl.formatMessage({ id: "FORM.SELECT" });
  const [errorMsg, setErrorMsg] = useState("");
  const fieldError = meta.error;
  const fieldTouched = meta.touched;

  useEffect(() => {
    if (fieldError && fieldTouched) {
      setErrorMsg(intl.formatMessage({ id: fieldError }));
    } else {
      setErrorMsg("");
    }
  }, [fieldError, fieldTouched]);

  // Add the selected option to the field
  const handleChange = useCallback(
    (selectedOption: any) => {
      setValue([...value, selectedOption.value]);
    },
    [value, setValue]
  );

  // Remove the selected option from the field
  const handleRemoveOption = useCallback(
    (event: any, optionValue: string) => {
      event.stopPropagation();
      const newValue = value.filter((val: string) => val !== optionValue);
      setValue(newValue);
    },
    [value, setValue]
  );

  // Remove all selected options from the field
  const handleRemoveAll = useCallback(
    (event: any) => {
      event.stopPropagation();
      setValue([]);
    },
    [setValue]
  );

  useEffect(() => {
    // The selected values to display in the field
    setDisplayValues(options.filter((option: any) => value.includes(option.value)));

    // The options to display in the dropdown, with the selected values removed
    setDropDownOptions(options.filter((option: any) => !value.includes(option.value)));
  }, [options, value]);

  // Handle outside click and escape keydown to close dropdown
  useEffect(() => {
    document.addEventListener(
      "click",
      throttle((e: any) => handleOutsideClick(e, dropdownRef, setIsOpen), 200)
    );
    document.addEventListener(
      "keydown",
      throttle((e: any) => handleEscKeydown(e, setIsOpen), 200)
    );

    return () => {
      document.removeEventListener(
        "click",
        throttle((e: any) => handleOutsideClick(e, dropdownRef, setIsOpen), 200)
      );
      document.removeEventListener(
        "keydown",
        throttle((e: any) => handleEscKeydown(e, setIsOpen), 200)
      );
    };
  }, []);

  return (
    <>
      <BootstrapForm.Group className="mb-3">
        {!hideLabel && label && <BootstrapForm.Label id={`${id}-label`}>{label}</BootstrapForm.Label>}
        <div ref={dropdownRef} className="d-flex align-items-center flex-help">
          <FieldWithSearchDropdown
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            target={
              <FieldsWithSearchDropdownButton
                error={errorMsg}
                onClick={() => setIsOpen(true)}
                removeAll={(e: any) => handleRemoveAll(e)}
              >
                {displayValues.length > 0
                  ? displayValues.map((displayValue: any, index: any) => (
                      <FieldWithSearchRemoveOptions
                        key={index}
                        optionLabel={displayValue.label}
                        onClick={(e: any) => handleRemoveOption(e, displayValue.value)}
                      />
                    ))
                  : placeholder}
              </FieldsWithSearchDropdownButton>
            }
          >
            <Select
              key={id}
              id={id}
              autoFocus
              className={classes}
              backspaceRemovesValue={false}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen={isOpen}
              onChange={handleChange}
              options={dropDownOptions}
              placeholder={placeholder}
              styles={selectStyles}
              tabSelectsValue={false}
              value={options.find((option) => option.value === value)}
              isDisabled={isDisabled}
              aria-labelledby={ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)}
              aria-describedby={ariaDescribedBy || (description ? `${id}-description` : undefined)}
              {...props}
            />
          </FieldWithSearchDropdown>
          {showRefreshButton && (
            <FontAwesomeIcon
              icon={faSync}
              onClick={() => handleRefreshClick(refreshType || "", setIsRefreshing, intl)}
              className={isRefreshing ? "MSD__refresh-btn-icon spin" : "MSD__refresh-btn-icon"}
            />
          )}
        </div>
        {errorMsg && <p className="text-danger mb-0 mt-2">{errorMsg}</p>}
        {!hideDescription && description && (
          <BootstrapForm.Text id={`${id}-description`} muted>
            {description}
          </BootstrapForm.Text>
        )}
      </BootstrapForm.Group>
    </>
  );
};

export default MultiSelectDropdownWithSearch;
