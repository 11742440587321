import { object, string } from "yup";
import { blns } from "./blns";

/**
 * Validates a string to ensure it only contains:
 * - Latin characters (including extended Latin for accented letters like `é`, `ű`).
 * - Japanese Hiragana and Katakana characters.
 * - Numbers (0–9).
 * - Specific special characters.
 * - Spaces and apostrophes.
 *
 * @constant
 * @type {RegExp}
 * @description
 * This regular expression ensures that a string is restricted to a set of valid characters:
 * - Basic Latin alphabet: a–z, A–Z.
 * - Latin-1 Supplement: À–ÿ (e.g., `é`, `ñ`, `ü`).
 * - Latin Extended-A: Unicode range \u0100–\u017F (e.g., `ő`, `ű` for Hungarian).
 * - Hiragana: Unicode range \u3040–\u309F (e.g., `あ`, `い`).
 * - Katakana: Unicode range \u30A0–\u30FF (e.g., `ア`, `イ`).
 * - Numbers: 0–9.
 * - Special characters: ! @ # $ % ^ & * ( ) / . : ; ? _ - \ and spaces.
 *
 * @example
 * // Valid examples:
 * excludeChars.test("Jó foxim húszwattos lámpánál ülve pár bűvös cipőt készít."); // true
 * excludeChars.test("クールなひとびとクラブ123!"); // true
 * 
 * // Invalid examples:
 * excludeChars.test("Hello 😊"); // false (Emoji not allowed)
 * excludeChars.test("中文测试"); // false (Non-Latin characters)
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions} for more on regular expressions.
 */
const excludeChars = /^[a-zA-ZÀ-ÿ\u0100-\u017F\u3040-\u309F\u30A0-\u30FF0-9!@#$%^&*()\/\.:;?_\\-\s']+$/;
export const validationSchema = object({
  name: string()
    .required("ROLES.NEWROLE.FORM.ERRORMSG.NAME.REQUIRED")
    .max(20, "ROLES.NEWROLE.FORM.ERRORMSG.NAME.LENGTH")
    .test({
      message: () => "ROLES.NEWROLE.FORM.ERRORMSG.FORBIDDENWORDS",
      test: (value) => {
        const forbidden = blns.some((word) => value.toLocaleLowerCase() === word.toLocaleLowerCase());
        return !forbidden;
      }
    })
    .test({
      message: () => "ROLES.NEWROLE.FORM.ERRORMSG.INVALID",
      test: (value) => excludeChars.test(value)
    })
    .trim(),
  description: string()
    .required("ROLES.NEWROLE.FORM.ERRORMSG.DESC.REQUIRED")
    .max(60, "ROLES.NEWROLE.FORM.ERRORMSG.DESC.LENGTH")
    .test({
      message: () => "ROLES.NEWROLE.FORM.ERRORMSG.FORBIDDENWORDS",
      test: (value) => {
        const forbidden = blns.some((word) => value.toLocaleLowerCase() === word.toLocaleLowerCase());
        return !forbidden;
      }
    })
    .test({
      message: () => "ROLES.NEWROLE.FORM.ERRORMSG.INVALID",
      test: (value) => excludeChars.test(value)
    })
    .trim()
});
