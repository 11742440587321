import { useRolesView } from "../../core/RolesAndPermissionsProvider";
import PermissionsAppSection from "./PermissionsAppSection";
import { useIntl } from "react-intl";

const PermissionsBodyWrapper = () => {
  const { permissions, roleIdForUpdate } = useRolesView();
  const intl = useIntl();

  const apps = permissions.reduce<string[]>((acc, permission) => {
    if (!acc.includes(permission.application)) {
      acc.push(permission.application);
    }
    return acc;
  }, []);

  return (
    <>
      {roleIdForUpdate.id === undefined && (
        <div className="alert alert-info text-center">
          {intl.formatMessage({ id: "ROLES.PERMISSIONS.NOROLESELECTED" })}
        </div>
      )}
      {roleIdForUpdate.id !== undefined && (
        <>{apps && apps.length > 0 && apps.map((app) => <PermissionsAppSection key={app} app={app} />)} </>
      )}
    </>
  );
};

export default PermissionsBodyWrapper;
