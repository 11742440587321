import { Navigate, Route, Routes } from "react-router-dom";

import MktoSettings from "./config-components/MktoSettings";
import MktoQSAppenderSettings from "./config-components/MktoQSAppender/MktoQSAppenderSettings";
import MktoActivityTypes from "./config-components/MktoActivityTypes";
import MktoLifecycleStages from "./config-components/MktoLifecycleStages";
import MktoProgressionStatusMap from "./config-components/MktoProgressionStatusMap";
import { useAuth } from "../auth";
import { hasPermission } from "../../helpers/permissions";

const MktoConfigWrapper = () => {
  const { currentUser } = useAuth();

  /* MARKERTO SETTINGS PERMISSIONS */
  const marketo_general_settings = hasPermission(currentUser, "marketo", "general_settings");
  const marketo_activity_types = hasPermission(currentUser, "marketo", "activity_types");
  const marketo_customer_journey = hasPermission(currentUser, "marketo", "customer_journey");
  const marketo_progression_status_map = hasPermission(currentUser, "marketo", "progression_status_map");
  const marketo_qs_appender_settings = hasPermission(currentUser, "marketo", "qs_appender_settings");
  
  return (
    <>
      <div className="container-fluid px-3 form-container">
        <div className="w-100">
          <div className="col-12">
            <Routes>
              {/* MARKETO GENERAL SETTINGS route with permission check */}
              <Route path="/marketo_settings" element={marketo_general_settings ? <MktoSettings /> : <Navigate to="/app/home" />}/>
              
              {/* MARKETO EMAIL LINK APPENDER route with permission check */}
              <Route path="/marketo_qs_appender_settings" element={marketo_qs_appender_settings ? <MktoQSAppenderSettings /> : <Navigate to="/app/home" />}/>
              
              {/* MARKETO ACTIVITY TYPES route with permission check */}
              <Route path="/marketo_activity_types" element={marketo_activity_types ? <MktoActivityTypes /> : <Navigate to="/app/home" />}/>
              
              {/* MARKETO LIFECYCLE STAGES route with permission check */}
              <Route path="/marketo_lifecycle_stages" element={marketo_customer_journey ? <MktoLifecycleStages /> : <Navigate to="/app/home" />}/>
              
              {/* MARKETO PROGRESSION STATUS MAP route with permission check */}
              <Route path="/marketo_progression_status_map" element={marketo_progression_status_map ? <MktoProgressionStatusMap /> : <Navigate to="/app/home" />}/>
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export { MktoConfigWrapper };
