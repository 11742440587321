import { salesforceValidationSchema } from "../schema/validationSchema";
import { useConnections } from "../core/ConnectionsProvider";
import { FieldConfig, SecretConfig } from "../core/_models";
import ConnectionForm from "./ConnectionForm";
import { useAuth } from "../../auth";
import { hasEditPermission } from "../../../helpers/permissions";

/**
 * `ConnectionSalesforceForm` is a form component for managing Salesforce connection configurations.
 * It allows users to input and update credentials for connecting to Salesforce APIs.
 * The form dynamically adapts based on user permissions and validation rules.
 *
 * @returns {JSX.Element} The Salesforce connection configuration form.
 */
const ConnectionSalesforceForm = (): JSX.Element => {
  const { currentUser } = useAuth();
  const sfdc_connection = hasEditPermission(currentUser, "account", "credentials");
  const { connection_data_salesforce, isSfdcEnabled, isLoading } = useConnections();

  /**
   * Configuration for the form fields, including labels, validation, and user permissions.
   */
  const fieldsConfigs: FieldConfig[] = [
    { id: "sfdc_login_url", labelId: "SECRETS.SFDC.LOGINURL", disabled: !sfdc_connection, showIcon: sfdc_connection },
    { id: "sfdc_username", labelId: "SECRETS.SFDC.USERNAME", disabled: !sfdc_connection, showIcon: sfdc_connection },
    { id: "sfdc_password", labelId: "SECRETS.SFDC.PASSWORD", disabled: !sfdc_connection, showIcon: sfdc_connection },
    { id: "sfdc_token", labelId: "SECRETS.SFDC.TOKEN", disabled: !sfdc_connection, showIcon: sfdc_connection }
  ];

  /**
   * Configuration for the secret keys used in the form.
   * This includes the secret keys, alert description, and API endpoints.
   * The secret keys are used to securely store and retrieve the user's Marketo credentials.
   */
  const secretConfig: SecretConfig = {
    testEndpoint: "/secrets/test/salesforce",
    saveEndpoint: "/secrets",
    alertDescId: "SECRETS.ALERT.DESC.SALESFORCE",
    secretKeys: [
      ["SFDC_LOGIN_URL", "sfdc_login_url"],
      ["SFDC_USERNAME", "sfdc_username"],
      ["SFDC_PASSWORD", "sfdc_password"],
      ["SFDC_TOKEN", "sfdc_token"]
    ],
    optionalKeys: []
  };

  return (
    <ConnectionForm
      fieldConfigs={fieldsConfigs}
      validationSchema={salesforceValidationSchema}
      secretConfig={secretConfig}
      initialValues={connection_data_salesforce}
      isLoading={isLoading}
      isEnabled={isSfdcEnabled}
      enableLink="/app/configs/salesforce_settings"
    />
  );
};

export default ConnectionSalesforceForm;
