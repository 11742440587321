import { Link } from "react-router-dom";
import PageHeader from "../../../layout/components/content/PageHeader";
import { useMemo } from "react";

type ConnectionWrapperProps = {
  /**
   * The application name, used for dynamic text generation in headers and page labels.
   * Example: "Marketo", "Salesforce".
   */
  app: string;

  /**
   * The child components to be rendered within the wrapper. Typically forms or UI components related to the application.
   */
  children: React.ReactNode;
};

/**
 * `ConnectionWrapper` serves as a layout wrapper for connection configuration pages.
 * It includes a consistent header, a back navigation link, and a container for child components.
 *
 * @param {ConnectionWrapperProps} props - The props for the component.
 * @param {string} props.app - The name of the application for which the connection is being configured.
 * @param {React.ReactNode} props.children - The content to render within the wrapper.
 *
 * @returns {JSX.Element} A structured layout for connection configuration pages.
 */
const ConnectionWrapper = ({ app, children }: ConnectionWrapperProps): JSX.Element => {
  const backToListLink = useMemo(() => {
    return (
      <div className="d-flex justify-content-start align-items-center">
        <Link to="/app/configs/connections" className="fs-3">
          &lt;&nbsp;Back to connections list
        </Link>
      </div>
    );
  }, []);

  return (
    <div className="container-fluid px-3 form-container">
      <div className="w-100">
        <div className="col-12">
          <div className="align-items-start d-flex flex-column mb-7">
            <PageHeader
              level1name="SIDEBAR.HEADER.CONFIGURATION"
              level1link="/app/home"
              level2name="SIDEBAR.SUBHEAD.AUTHKEYS"
              level2link="/app/configs/connections"
              level3name={`SECRETS.APPNAME.${app}`}
              instructions="SECRETS.APICREDENTIALS.INSTRUCTIONS"
            />
          </div>

          {backToListLink}
          <div className="row">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionWrapper;
