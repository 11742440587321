import { Navigate, Route, Routes } from "react-router-dom";
import ConnectionsList from "./components/ConnectionsList";
import { connections } from "./core/connections";
import ConnectionConfigCard from "./components/ConnectionConfigCard";
import { ConnectionsProvider } from "./core/ConnectionsProvider";
import { useAuth } from "../auth";
import { hasPermission } from "../../helpers/permissions";

/**
 * ConnectionsRouter is the main routing component for managing API connection-related routes.
 * It uses `react-router-dom` for navigation and wraps the routes with `ConnectionsProvider`
 * to ensure that all connection-related components have access to the required context.
 *
 * @returns {JSX.Element} The routing structure for the connections feature.
 */
const ConnectionsRouter = (): JSX.Element => {
  const { currentUser } = useAuth();
  const account_connections = hasPermission(currentUser, "account", "credentials");

  return (
    <ConnectionsProvider>
      <Routes>
        {/** Route for Marketo connection configuration */}
        <Route path="/marketo" element={!account_connections ? <Navigate to="/app/home" /> : <ConnectionConfigCard {...connections["marketo"]} />} />

        {/** Route for Salesforce connection configuration */}
        <Route path="/salesforce" element={!account_connections ? <Navigate to="/app/home" /> : <ConnectionConfigCard {...connections["salesforce"]} />} />

        {/** Route for HubSpot connection configuration */}
        <Route path="/hubspot" element={!account_connections ? <Navigate to="/app/home" /> : <ConnectionConfigCard {...connections["hubspot"]} />} />

        {/** Route for Slack connection configuration */}
        <Route path="/slack" element={!account_connections ? <Navigate to="/app/home" /> : <ConnectionConfigCard {...connections["slack"]} />} />

        {/** Default route displaying the list of connections */}
        <Route path="/" element={!account_connections ? <Navigate to="/app/home" /> : <ConnectionsList />} />
      </Routes>
    </ConnectionsProvider>
  );
};

export default ConnectionsRouter;
