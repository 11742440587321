import axios from "axios";

export const getDataStatus = async () => {
  const response = await axios
    .get("/status/data")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Refresh API Error", error);
    });
  return response;
};
