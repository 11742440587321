import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDropzone } from "react-dropzone";
import { acceptStyle, baseStyle, focusedStyle, rejectStyle, thumb, thumbsContainer } from "../style/imageUploadStyle"
import axios from "axios";
import { KTIcon } from "../../../helpers";
import { toast } from "react-toastify";
import { toastSettings } from "../../../layout/components/toast/Toast";
import CompanyCropperModal from "./CompanyCropperModal";
import { DataURIToBlob } from "../core/_helpers";

const CompanyLogoDropzone = ({ theme }: { theme: any }) => {
  const [files, setFiles] = useState<any>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [image, setImage] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const intl = useIntl();

  const { acceptedFiles, fileRejections, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "image/jpeg": [".jpg", ".jpeg"],
        "image/jpg": [".jpg", ".jpeg"],
        "image/png": [".png"]
      },
      maxFiles: 1,
      maxSize: 500000,
      onDrop: (acceptedFiles: any) => {
        setFiles(
          acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        );
      }
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    if (files.length > 0) {
      setImage(files[0]);
      setCroppedImage(files[0]);
    }
  }, [files]);

  const saveImage = (themeMode: string) => {
    setIsSaving(true);
    const formData = new FormData();

    if (!croppedImage.preview) {
      formData.append("file", DataURIToBlob(croppedImage));
    } else {
      formData.append("file", croppedImage);
    }
    formData.append("theme", themeMode);

    axios
      .post("/files/client_logo", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res) => {
        toast.success(
          intl.formatMessage({ id: "TOAST.SUCCESS", defaultMessage: "The image has been uploaded" }),
          toastSettings("success")
        );
        setIsSaving(false);
        window.location.reload();
      })
      .catch((err) => {
        console.error("Error uploading image", err);
        setIsSaving(false);
      });
  };

  const handleCroppedImage = (croppedImage: any) => {
    setCroppedImage(croppedImage);
  };

  const thumbs = files.map((file: any) => (
    <div style={thumb} key={file.name}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        {croppedImage && (
          <img
            src={croppedImage.preview ? croppedImage.preview : croppedImage}
            className="img-fluid mw-50 mb-4"
            alt={intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.LOGO.PREVIEW" })}
            onLoad={() => URL.revokeObjectURL(croppedImage.preview)}
          />
        )}

        <div className="d-flex gap-3 mt-4 flex-column flex-sm-row">
          <button
            type="button"
            className={`btn btn-sm rounded-pill ${theme === "dark" ? "btn-secondary" : "btn-primary"}`}
            data-bs-toggle="modal"
            data-bs-target={theme === "light" ? "#modal_crop_image_light" : "#modal_crop_image_dark"}
            aria-label={intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.CROP" })}
          >
            <KTIcon iconName="design-frame" className="fs-4" />
            <span>{intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.CROP" })}</span>
          </button>
          <button
            type="button"
            className={`btn btn-sm rounded-pill ${theme === "dark" ? "btn-secondary" : "btn-primary"}`}
            onClick={() => saveImage(theme)}
            aria-label={intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.LOGO.UPLOAD" })}
          >
            {!isSaving ? (
              <>
                <KTIcon iconName="file-up" className="fs-4" />
                <span>
                  {intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.LOGO.UPLOAD" })}
                </span>
              </>
            ) : (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.LOGO.UPLOADING"
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-live="polite"></span>
              </span>
            )}
          </button>
          <button
            type="button"
            className={`btn btn-sm rounded-pill ${theme === "dark" ? "btn-secondary" : "btn-primary"}`}
            onClick={() => setFiles([])}
            aria-label={intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.DELETE" })}
          >
            <KTIcon iconName="delete-files" className="fs-4" />
            <span>{intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.DELETE" })}</span>
          </button>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <section className="container p-0 d-flex align-items-start w-100t">
        {files.length > 0 ? (
          <aside className={`rounded  ${theme === "dark" ? "bg-primary" : "bg-white"}`} style={thumbsContainer}>
            {thumbs}
          </aside>
        ) : (
          <div
            className="dropzone w-100 p-0 pt-5 pb-5 bg-transparent"
            {...getRootProps({ style })}
            aria-label={intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.LOGO.DROPZONE" })}
          >
            <input {...getInputProps()} aria-label={intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.LOGO.UPLOAD" })} />
            <div className="dz-message m-4 d-flex align-items-center">
              <i className="ki-duotone ki-file-up text-gray-900 fs-3x" aria-hidden="true">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              <div className="ms-4 mw-350px">
                <p className="fs-5 fw-bold text-gray-600 mb-1">
                  {intl.formatMessage(
                    {
                      id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.LOGO.DROPZONE",
                      defaultMessage: "To add or update the {mode} MODE logo, drop a new one here or click to upload."
                    },
                    { mode: intl.formatMessage({ id: theme === "light" ? "MODE.LIGHT" : "MODE.DARK" }) }
                  )}
                </p>
                <span className="fs-7 fw-semibold text-gray-500">
                  <em>
                    {intl.formatMessage({
                      id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.LOGO.DROPZONE_ACCEPT",
                      defaultMessage: "(Only *.jpeg, *.jpg, and *.png images will be accepted)"
                    })}
                  </em>
                </span>
              </div>
            </div>
          </div>
        )}
      </section>
      <CompanyCropperModal imageSrc={image} handleCroppedImage={handleCroppedImage} theme={theme} />
    </>
  );
};

export default CompanyLogoDropzone;
