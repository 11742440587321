import {Route, Routes, Outlet} from 'react-router-dom'
import {Error401} from './components/Error401'
import {Error403} from './components/Error403'
import {Error404} from './components/Error404'
import {Error500} from './components/Error500'
import {Error502} from './components/Error502'
import {Error503} from './components/Error503'
import {Error504} from './components/Error504'
import {useEffect} from 'react'
import {toAbsoluteUrl} from '../../helpers'
import {useThemeMode} from '../../layout/partials'

const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']

const ErrorsLayout = () => {
  const {mode} = useThemeMode()
  useEffect(() => {
    BODY_CLASSES.forEach((c) => document.body.classList.add(c))
    document.body.style.backgroundImage =
      mode === 'dark'
        ? `url(${toAbsoluteUrl('/media/auth/bg4-dark.jpg')})`
        : `url(${toAbsoluteUrl('/media/auth/bg4.jpg')})`

    return () => {
      BODY_CLASSES.forEach((c) => document.body.classList.remove(c))
      document.body.style.backgroundImage = 'none'
    }
  }, [mode])

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ErrorsPage = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path='401' element={<Error401 />} />
      <Route path='404' element={<Error404 />} />
      <Route path='403' element={<Error403 />} />
      <Route path='500' element={<Error500 />} />
      <Route path='502' element={<Error502 />} />
      <Route path='503' element={<Error503 />} />
      <Route path='504' element={<Error504 />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
)

export {ErrorsPage}
