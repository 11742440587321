import React from "react";
import { components, OptionProps } from "react-select";

// Custom Option component
const Option: React.FC<OptionProps<any, false>> = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps || {};
  const newProps = { ...props, innerProps: rest };

  return (
    <components.Option {...newProps}>
      {children}
      <div className="tz-optionTime">{newProps.data.time}</div>
    </components.Option>
  );
};

export default Option;
