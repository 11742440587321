import { createContext, FC, useContext, useEffect, useMemo, useState } from "react";
import { WithChildren } from "../../../helpers";
import { IdForUpdate, initialRoleView, rolesAndPermissionsContextProps } from "./_models";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { getRolesAndPermissions } from "./_requests";

const RolesAndPermissionsContext = createContext<rolesAndPermissionsContextProps>(initialRoleView);

const RolesAndPermissionsProvider: FC<WithChildren> = ({ children }) => {
  const [roleIdForUpdate, setRoleIdForUpdate] = useState<IdForUpdate>(initialRoleView.roleIdForUpdate);
  const [roles, setRoles] = useState(initialRoleView.roles);
  const [permissions, setPermissions] = useState(initialRoleView.permissions);
  const [updateRoleObject, setUpdateRoleObject] = useState(initialRoleView.updateRoleObject);
  const [permissionHasChanged, setPermissionHasChanged] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    isFetching,
    refetch,
    data: response
  } = useQuery({
    queryKey: ["Fetch Roles and Permissions"],
    queryFn: getRolesAndPermissions,
    refetchOnMount: false,
    gcTime: 3600 * 1000,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  // Memoize Permission and Roles to avoid unnecessary re-renders
  const permissionsAndRoles = useMemo(() => response || [], [response]);

  useEffect(() => {
    setPermissionHasChanged(false);

    if (permissionsAndRoles.permissions) {
      setPermissions(permissionsAndRoles.permissions);
    }
    if (permissionsAndRoles.roles) {
      const defaultRoles = permissionsAndRoles.roles.filter(
        (role: any) => role.id === "Demo User" || role.id === "Admin" || role.id === "User"
      );
      const customRoles = permissionsAndRoles.roles.filter(
        (role: any) => role.id !== "Demo User" && role.id !== "Admin" && role.id !== "User"
      );
      setRoles([...defaultRoles, ...customRoles]);
    }
  }, [permissionsAndRoles, roleIdForUpdate, setUpdateRoleObject, refetch]);

  useEffect(() => {
    if (roleIdForUpdate.id) {
      const role = roles.find((role) => role.id === roleIdForUpdate.id);
      if (role) {
        setUpdateRoleObject({
          name: role.name,
          description: role.description,
          permissions: role.permissions
        });
      }
    }
  }, [roleIdForUpdate, roles]);

  return (
    <RolesAndPermissionsContext.Provider
      value={{
        roles,
        updateRoleObject,
        setUpdateRoleObject,
        permissions,
        permissionHasChanged,
        setPermissionHasChanged,
        isSubmitting,
        setIsSubmitting,
        selected: [],
        roleIdForUpdate,
        setRoleIdForUpdate,
        isLoading: isFetching,
        refetch
      }}
    >
      {children}
    </RolesAndPermissionsContext.Provider>
  );
};

const useRolesView = () => useContext(RolesAndPermissionsContext);

export { RolesAndPermissionsProvider, useRolesView };
