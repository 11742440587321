import { useIntl } from "react-intl";
import { MultiSelectDropdownWithInput } from "../../../layout/components/form";

const GenSettingsClientEmailDomains = () => {
  const intl = useIntl();

  return (
    <div className="row">
      <div className="col-lg-4">
        <span className="fw-bold fs-4 text-muted" id="company_client_email_domains-label">
          {intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.CLIENTEMAILDOMAINS" })}
        </span>
        <p className="fs-7 text-muted" id="company_client_email_domains-description">
          {intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.CLIENTEMAILDOMAINS.DESCRIPTION" })}
        </p>
      </div>
      <div className="col-lg-8">
        <MultiSelectDropdownWithInput
          id="client_email_domains"
          label=""
          description=""
          options={[]} // Replace with your actual options
          showRefreshButton={false}
          hideLabel={true}
          hideDescription={true}
          refreshType=""
          ariaLabelledBy="client_email_domains-label"
          ariaDescribedBy=""
          useEmailDomainHandlers={true} // Enable the email domain-specific handlers
        />
      </div>
    </div>
  );
};

export default GenSettingsClientEmailDomains;
