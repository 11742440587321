import { UserModel } from "../pages/auth/core/_models";

/**
 * @typedef {("account" | "hubspot"| "marketo"| "salesforce"| "source_tracker"| "superset"| "user")} Resource
 * @typedef {("credentials" | "roles"| "settings"| "users"| "fields"| "general_settings"| "actitivy_types"| "activity_types"| "customer_journey"| "data"| "progression_status_map"| "qs_appender"| "qs_appender_settings"| "scripts"| "reports"| "profile")} Object
 */

type Resource = "" | "account" | "hubspot" | "marketo" | "salesforce" | "source_tracker" | "superset" | "user";
type Object =
  | ""
  | "credentials"
  | "roles"
  | "settings"
  | "users"
  | "fields"
  | "general_settings"
  | "actitivy_types"
  | "activity_types"
  | "customer_journey"
  | "data"
  | "progression_status_map"
  | "qs_appender"
  | "qs_appender_settings"
  | "scripts"
  | "reports"
  | "profile";

/**
 * @name hasPermission
 * @description This function checks if the user has the required permission for the provided resource and object and returns the highest permission level
 *
 * @param UserModel user - The user object that contains the role and the corresponding permissions
 * @param {Resource} resource - The resource to check. Possible values:  account, hubspot, marketo, salesforce, source_tracker, superset, user
 * @param {Object} object - The object to check. Possible values:  credentials, roles, settings, users, fields, general_settings, actitivy_types, activity_types, customer_journey, data, progression_status_map, qs_appender, qs_appender_settings, scripts, reports, profile
 *
 * @returns string - The highest permission level. Possible values:  read, edit
 * @returns boolean - False if the user does not have the required permission
 * @returns string - SPECIAL CASE: "read" if the user is a demo user
 */

const hasPermission = (user: UserModel | undefined, resource: Resource, object: Object) => {
  let permissionLevel: string | boolean = false;

  // Check if user exists and has roles or if the role to check is empty
  if (!user || !user.roles || resource === "" || object === "") {
    return permissionLevel;
  }

  // Return Special Demo User Permission as read only for all resources
  if (user.roles[0].name === "Demo User") {
    return "read";
  }

  const userPermissions = user.roles.map((role) => role.permissions).flat();
  const permissionID = `${resource}.${object}`;

  // Check if we have matching permissions for the provided resource and object
  const matchingPermissions = userPermissions.filter((permission) => permission?.includes(permissionID));

  // Check if we have any matching permissions
  if (matchingPermissions.length === 0) {
    return false;
  }

  // Check actions
  const permissionActions = matchingPermissions
    .map((permission) => (permission ? permission.split(".")[2] : undefined))
    .filter((action) => action !== undefined);

  // Check highest permission level
  if (permissionActions.includes("run")) {
    permissionLevel = "run";
  } else if (permissionActions.includes("fetch")) {
    permissionLevel = "fetch";
  } else if (permissionActions.includes("edit")) {
    permissionLevel = "edit";
  } else if (permissionActions.includes("read")) {
    permissionLevel = "read";
  }
  
  // Return the highest permission level
  return permissionLevel;
};

/**
 * @name createPermissionChecker
 * @description This function checks if the user has the required permission for the permission level
 *
 * @param level - The permission level to check. Possible values:  read, edit, run, fetch
 *
 * @returns boolean - True if the user has the required permission
 */
const createPermissionChecker = (level: string) => 
  (user: UserModel | undefined, resource: Resource, object: Object): boolean => 
    hasPermission(user, resource, object) === level;

// Permission Checkers
const hasEditPermission  = createPermissionChecker("edit");
const hasReadPermission  = createPermissionChecker("read");
const hasRunPermission   = createPermissionChecker("run");
const hasFetchPermission = createPermissionChecker("fetch");

export { hasPermission, hasEditPermission, hasReadPermission, hasRunPermission, hasFetchPermission };
