import { object, string } from "yup";

export const marketoValidationSchema = object().shape({
  marketo: object().shape({
    mkto_munchkin_id: string().matches(/([\d]{3})-([A-Za-z]{3})-([\d]{3})/),
    mkto_client_id: string().uuid(),
    mkto_client_secret: string()
  })
});

export const salesforceValidationSchema = object().shape({
  sfdc_login_url: string(),
  sfdc_username: string(),
  sfdc_password: string(),
  sfdc_token: string()
});

export const hubspotValidationSchema = object().shape({
  hubspot_hub_id: string(),
  hubspot_private_app_token: string(),
  hubspot_public_app_id: string(),
  hubspot_public_client_id: string(),
  hubspot_public_client_secret: string()
});

export const slackValidationSchema = object().shape({
  slack_api_token: string()
});