import CompanyLogo from "./CompanyLogo";

const CompanyLogoWrapper = () => {

  return (
    <div className="row">
      <CompanyLogo />
    </div>
  );
};

export default CompanyLogoWrapper;
