import { MultiSelectDropdownWithInput } from "../../../../../layout/components/form";
import { useIntl } from "react-intl";

type FormFieldExcludedDomainsProps = {
  errors: any;
  touched: any;
  editPermission: boolean;
};

const FormFieldExcludedDomains = ({ errors, touched, editPermission }: FormFieldExcludedDomainsProps) => {
  const intl = useIntl();

  return (
    <div className="m-3">
      <span className="fs-5 fw-bold" id="excluded-domains-label">
        {intl.formatMessage({
          id: "SETTINGS.MKTO.QSAPPEND.EXCLUDEDDOMAINS"
        })}
      </span>
      <div className="text-muted mb-3" id="excluded-domains-description">
        {intl.formatMessage({
          id: "SETTINGS.MKTO.QSAPPEND.EXCLUDEDDOMAINS.DESC"
        })}
      </div>
      <MultiSelectDropdownWithInput
        id="mkto_qs_appender_excluded_domains"
        label=""
        hideLabel={true}
        disabled={!editPermission}
        ariaLabelledBy="excluded-domains-label"
        ariaDescribedBy="excluded-domains-description"
        description={intl.formatMessage({
          id: "SETTINGS.MKTO.QSAPPEND.EXCLUDEDDOMAINS.DESC"
        })}
        hideDescription={true}
        options={[]}
        classes={
          errors.mkto_qs_appender_excluded_domains && touched.mkto_qs_appender_excluded_domains ? "border-danger" : ""
        }
        showRefreshButton={false}
      />
      {!!errors.mkto_qs_appender_excluded_domains && touched.mkto_qs_appender_excluded_domains ? (
        <p className="text-danger">
          {intl.formatMessage({
            id: errors.mkto_qs_appender_excluded_domains
          })}
        </p>
      ) : null}
    </div>
  );
};

export default FormFieldExcludedDomains;
