import { ColumnInstance, Row, useTable } from "react-table";
import { useMemo } from "react";
import { TableCustomHeaderColumn } from "./columns/TableCustomHeaderColumn";
import { TableColumns } from "../../core/_models";
import { rolesColumns } from "./columns/_columns";
import { useIntl } from "react-intl";
import TableCustomRow from "./columns/TableCustomRow";

import { useInsJSFilesRequest } from "../../core/InsJSFilesProvider";
import InsSpinner from "../../../../layout/components/spinner/InsSpinner";

const InsJsFilesTable = () => {
  const intl = useIntl();

  const { trackerFiles, isFetching } = useInsJSFilesRequest();

  const data = useMemo(() => trackerFiles, [trackerFiles]);
  const columns = useMemo(() => rolesColumns, []);

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data
  });

  return (
    <div className="table-responsive">
      {!isFetching ? (
        <table
          id="insFilesTable"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead>
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
              {headers.map((column: ColumnInstance<TableColumns>) => (
                <TableCustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<TableColumns>, i) => {
                prepareRow(row);
                return <TableCustomRow row={row} key={`row-${i}-${row.id}`} />;
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "200px" }}
                    aria-live="polite"
                  >
                    <h3>{intl.formatMessage({ id: "INSENTRICJS.NOTRACKERS" })}</h3>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <InsSpinner text={intl.formatMessage({ id: "INSENTRICJS.LOADING.FILES" })} />
      )}
    </div>
  );
};

export default InsJsFilesTable;
