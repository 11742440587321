import { SingleSelectDropdown } from "../../../../layout/components/form";
import { useIntl } from "react-intl";
import { useAuth } from "../../../auth";
import { hasEditPermission } from "../../../../helpers/permissions";

const Step4 = (props: any) => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const source_tracker = hasEditPermission(currentUser, "source_tracker", "scripts");

  return (
    <div className="w-100">
      <h1>{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP4.TITLE" })}</h1>
      <div className="text-muted mt-3">
        {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP4.INSTRUCTIONS" })}
      </div>
      <hr />
      <div className="w-100 d-flex flex-column">
        <div className="m-3 flex-fill">
          <h2 id="tempSource-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.SOURCE" })}</h2>
          <div className="text-muted my-2" id="tempSource-description">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.SOURCE.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="tempSource"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="tempSource-label"
            ariaDescribedBy="tempSource-description"
            isDisabled={!source_tracker}
          />
        </div>
        <div className="m-3 flex-fill">
          <h2 id="tempMedium-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.MEDIUM" })}</h2>
          <div className="text-muted my-2" id="tempMedium-description">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.MEDIUM.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="tempMedium"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="tempMedium-label"
            ariaDescribedBy="tempMedium-description"
            isDisabled={!source_tracker}
          />
        </div>
        <div className="m-3 flex-fill">
          <h2 id="tempCampaign-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.CAMPAIGN" })}</h2>
          <div className="text-muted my-2" id="tempCampaign-description">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.CAMPAIGN.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="tempCampaign"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="tempCampaign-label"
            ariaDescribedBy="tempCampaign-description"
            isDisabled={!source_tracker}
          />
        </div>
        <div className="m-3 flex-fill">
          <h2 id="tempTerm-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.TERM" })}</h2>
          <div className="text-muted my-2" id="tempTerm-description">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.TERM.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="tempTerm"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="tempTerm-label"
            ariaDescribedBy="tempTerm-description"
            isDisabled={!source_tracker}
          />
        </div>
        <div className="m-3 flex-fill">
          <h2 id="tempContent-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.CONTENT" })}</h2>
          <div className="text-muted my-2" id="tempContent-description">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.CONTENT.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="tempContent"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="tempContent-label"
            ariaDescribedBy="tempContent-description"
            isDisabled={!source_tracker}
          />
        </div>
      </div>
    </div>
  );
};

export { Step4 };
