// @ts-nocheck
/* eslint react/prop-types: 0 */
import { Column } from "react-table";
import { TableCustomHeader } from "./TableCustomHeader";
import { TableInsJsDomainCell } from "./TableInsJsDomainCell";
import { TableInsJsEmbedCodeCell } from "./TableInsJsEmbedCodeCell";
import { TableInsJsActionsCell } from "./TableInsJsActionsCell";
import { TableColumns } from "../../../core/_models";

const rolesColumns: ReadonlyArray<Column<TableColumns>> = [
  {
    Header: (props: any) => (
      <TableCustomHeader tableProps={props} title="SETTINGS.INSENTRIC-JS.TABLE.HEADER.DOMAIN" className="min-w-125px" />
    ),
    id: "domain",
    accessor: "domain",
    Cell: ({ ...props }) => <TableInsJsDomainCell domain={props.row.original.domain} />
  },
  {
    Header: (props: any) => (
      <TableCustomHeader
        tableProps={props}
        title="SETTINGS.INSENTRIC-JS.TABLE.HEADER.EMBEDCODE"
        className="min-w-125px"
      />
    ),
    id: "embed_code",
    accessor: "embed_code",
    Cell: ({ ...props }) => (
      <TableInsJsEmbedCodeCell
        id={props.row.original.id}
        domain={props.row.original.domain}
        embedCode={props.row.original.embedCode}
      />
    )
  },
  {
    Header: (props: any) => (
      <TableCustomHeader
        tableProps={props}
        title="SETTINGS.INSENTRIC-JS.TABLE.HEADER.ACTIONS"
        className="text-center w-200px pr-5"
      />
    ),
    id: "actions",
    accessor: "actions",
    Cell: ({ ...props }) => (
      <TableInsJsActionsCell
        id={props.row.original.id || ""}
        domain={props.row.original.domain}
        embedCode={props.row.original.embedCode}
      />
    )
  }
];

export { rolesColumns };
