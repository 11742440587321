import { useIntl } from "react-intl";
import { hasFetchPermission } from "../../../helpers/permissions";
import PageHeader from "../../../layout/components/content/PageHeader";
import InsSpinner from "../../../layout/components/spinner/InsSpinner";
import { useAuth } from "../../auth";
import RefreshCard from "./components/RefreshCard";
import { RefreshDataProvider, useRefreshData } from "./core/RefreshDataProvider";
import { KTIcon } from "../../../helpers";

const RefreshPageContainer = () => {
  const intl = useIntl();
  const { isLoading } = useRefreshData();
  const { currentUser } = useAuth();
  const refresh_marketo = hasFetchPermission(currentUser, "marketo", "data");
  const refresh_sfdc = hasFetchPermission(currentUser, "salesforce", "data");

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level1name="SIDEBAR.HEADER.UTILITIES"
          level1link="/app/home"
          level2name="SIDEBAR.SUBHEAD.REFRESHDATA"
        />
      </div>
      <div className="d-flex gap-5 flex-column flex-lg-row">
        {!isLoading ? (
          <div className="d-flex flex-column flex-lg-row h-100 gap-5">
            <div className="d-flex flex-column w-100 w-lg-50 gap-5 h-100">
              {refresh_marketo && (
                <>
                  <RefreshCard app="mkto" />
                  <RefreshCard app="mktoAssets" />
                </>
              )}
            </div>
            <div className="w-100 w-lg-50 h-100">{refresh_sfdc && <RefreshCard app="sfdc" />}</div>
          </div>
        ) : (
          <InsSpinner />
        )}
      </div>
      {!refresh_marketo && !refresh_sfdc && (
        <div className="alert alert-danger d-flex align-items-center gap-3 w-100" role="alert">
          <KTIcon iconName="shield" className="fs-1 text-danger" />
          <div>{intl.formatMessage({ id: "REFRESHDATA.NOPREMISSION.DESC" })}</div>
        </div>
      )}
    </>
  );
};

const RefreshDataWrapper = () => {
  return (
    <RefreshDataProvider>
      <RefreshPageContainer />
    </RefreshDataProvider>
  );
};

export default RefreshDataWrapper;
