import React from "react";
import { MultiSelectDropdownWithInput, NumberField, TextField } from "../../../../layout/components/form";
import { useIntl } from "react-intl";
import { useInsJSFilesRequest } from "../../core/InsJSFilesProvider";
import { useAuth } from "../../../auth";
import { hasEditPermission } from "../../../../helpers/permissions";

interface Step1Props {
  errors: Record<string, any>;
  touched: Record<string, any>;
  values: Record<string, any>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const Step1: React.FC<Step1Props> = ({
  errors,
  touched,
  values,
  setFieldValue
}) => {
  const intl = useIntl();
  const {currentUser } = useAuth();
  const source_tracker = hasEditPermission(currentUser, "source_tracker", "scripts");

  const { itemIdForUpdate } = useInsJSFilesRequest();
  const isItemForUpdate = itemIdForUpdate !== undefined;
  const { setIsInitialAndConversion, setPmcfOrLeadFields } = useInsJSFilesRequest();

  return (
    <div className="w-100">
      <h1>
        {intl.formatMessage({
          id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1"
        })}
      </h1>
      <div className="text-muted mt-3">
        {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.INSTRUCTIONS" })}
      </div>
      <hr />
      <div className="w-100 d-flex flex-column">
        <div className="m-3">
          <h2 id="main-domain-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.DOMAIN" })}</h2>
          <div id="main-domain-description" className="text-muted my-3">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.DOMAIN.INSTRUCTIONS" })}
          </div>
          <TextField
            id="maindomain"
            label=""
            description=""
            placeholder="example.com"
            disabled={!isItemForUpdate || !source_tracker}
            optional={false}
            classes={errors.maindomain && touched.maindomain ? "border-danger" : ""}
            isInvalid={false}
            ariaLabelledBy="main-domain-label"
            ariaDescribedBy="main-domain-description"
          />
          {errors.maindomain && touched.maindomain && (
            <p className="text-danger">{intl.formatMessage({ id: errors.maindomain })}</p>
          )}
        </div>
        <div className="m-3">
          <h2 className="cookie-months-label">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.COOKIES" })}
          </h2>
          <NumberField
            id="cookie_months"
            label=""
            description=""
            optional={false}
            classes={errors.cookie_months && touched.cookie_months ? "border-danger" : ""}
            disabled={!source_tracker}
            min={1}
            max={12}
            placeholder={6}
            ariaLabelledBy="cookie-months-label"
            ariaDescribedBy="cookie-months-description"
            hideLabel={false}
            hideDescription={false}
          />
          {errors.cookie_months && touched.cookie_months && (
            <p className="text-danger">{intl.formatMessage({ id: errors.cookie_months })}</p>
          )}
          <div id="cookie-months-description" className="text-muted mt-3">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.COOKIES.INSTRUCTIONS" })}
          </div>
        </div>
        <div className="m-3">
          <div className="mb-10">
            <div className="form-check form-switch form-check-custom form-check-solid flex-column align-items-start">
              <h2>{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.INITIALCONVERSION" })}</h2>
              <div id="initial-conversion-description" className="text-muted mb-3">
                {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.INITIALCONVERSION.INSTRUCTIONS" })}
              </div>
              <div className="d-flex align-items-center gap-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="initialConversion"
                  id="initialConversion"
                  checked={values.initialConversion}
                  onChange={(e: any) => {
                    setIsInitialAndConversion(e.target.checked);
                    setFieldValue("initialConversion", e.target.checked);
                  }}
                  aria-describedby="initial-conversion-description"
                  disabled={!source_tracker}
                />
                <span className="fw-bold text-primary fs-5">
                  {values.initialConversion === true ? "Add Initial traffic fields" : "Exclude Initial traffic fields"}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="m-3">
          <div className="mb-10">
            <div className="form-check form-switch form-check-custom form-check-solid flex-column align-items-start">
              <h2>{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.LASTSOURCEOBJECT" })}</h2>
              <div id="last-source-object-description" className="text-muted mb-3">
                {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.LASTSOURCEOBJECT.INSTRUCTIONS" })}
              </div>
              <div className="d-flex align-items-center gap-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="last_source_object"
                  id="last_source_object"
                  checked={values.last_source_object === "lead"}
                  onChange={(e: any) => {
                    setPmcfOrLeadFields(e.target.checked ? "lead" : "program_member");
                    setFieldValue("last_source_object", e.target.checked ? "lead" : "program_member");
                  }}
                  aria-describedby="last-source-object-description"
                  disabled={!source_tracker}
                />
                <span className="fw-bold text-primary fs-5">
                  {values.last_source_object === "lead" && "Lead fields"}
                  {values.last_source_object === "program_member" && "Program Member fields"}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="m-3">
          <h2 className="valid-domains-label">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.DOMAINLOCK" })}
          </h2>
          <div id="valid-domains-description" className="text-muted my-3">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.DOMAINLOCK.INSTRUCTIONS" })}
          </div>
          <MultiSelectDropdownWithInput
            id="validdomains"
            label=""
            description=""
            optional={true}
            options={values.validdomains || []}
            classes={errors.validdomains && touched.validdomains ? "border-danger" : ""}
            showRefreshButton={false}
            ariaLabelledBy="valid-domains-label"
            ariaDescribedBy="valid-domains-description"
            disabled={!source_tracker}
          />
          {errors.validdomains && touched.validdomains && (
            <p className="text-danger">{intl.formatMessage({ id: errors.validdomains })}</p>
          )}
        </div>
        <div className="m-3">
          <h2 id="reserved-strings-label">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.RESERVEDSTRINGS" })}
          </h2>
          <div id="reserved-strings-description" className="text-muted my-3">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.RESERVEDSTRINGS.INSTRUCTIONS" })}
          </div>
          <MultiSelectDropdownWithInput
            id="reservedstrings"
            label=""
            description=""
            options={values.reservedstrings}
            showRefreshButton={false}
            ariaLabelledBy="reserved-strings-label"
            ariaDescribedBy="reserved-strings-description"
            disabled={!source_tracker}
          />
        </div>
      </div>
    </div>
  );
};

export { Step1 };
