import { useIntl } from "react-intl";
import { TextField } from "../../../layout/components/form";

const GenSettingsCompanyID = ({ errors, touched }: { errors: any; touched: any }) => {
  const intl = useIntl();
  
  return (
    <div className="row">
      <div className="col-lg-4 p-3 mb-3">
        <span className="fw-bold fs-4 text-muted" id="company_id-label">
          {intl.formatMessage({
            id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.COMPANYID"
          })}
        </span>
      </div>
      <div className="col-lg-8 p-3 mb-3">
        <TextField
          disabled={true}
          id="company_id"
          label=""
          description=""
          placeholder=""
          optional={true}
          classes={errors.company_id && touched.company_id ? "border-danger" : ""}
          isInvalid={false}
          ariaLabelledBy="company_id-label"
          ariaDescribedBy=""
        />
        {!!errors.company_id && touched.company_id ? (
          <p className="text-danger">
            {intl.formatMessage({
              id: errors.company_id
            })}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default GenSettingsCompanyID;
