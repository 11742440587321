import { useState } from "react";
import { useRolesView } from "../../../core/RolesAndPermissionsProvider";
import { useIntl } from "react-intl";
import { updateUsersRole } from "../../../core/_requests";
import { ID } from "../../../core/_models";

interface UserRoleUpdatePayload {
  userId: ID;
  roleId: ID;
}

const ModalBodyUsers = () => {
  const intl = useIntl();
  const { roles, roleIdForUpdate, refetch, setRoleIdForUpdate } = useRolesView();

  const [usersRoleUpdatePayload, setUsersRoleUpdatePayload] = useState<Array<UserRoleUpdatePayload>>([]);
  const [isSaving, setIsSaving] = useState(false);

  const role = roles.find((role) => role.id === roleIdForUpdate.id);

  const rolesList = roles.map((role) => ({
    name: role.name,
    id: role.id
  }));

  const handleSaveChanges = async () => {
    setIsSaving(true);
    await updateUsersRole(usersRoleUpdatePayload);
    if (refetch) {
      refetch();
    }
    setIsSaving(false);

    setRoleIdForUpdate((prevState) => {
      return { ...prevState, type: "EDIT_ROLE" };
    });
  };

  const handleRoleChange = (userID: ID, userRoleID: ID) => {
    const newUsersRoleUpdatePayload = usersRoleUpdatePayload.filter((user) => user.userId !== userID);
    newUsersRoleUpdatePayload.push({ userId: userID, roleId: userRoleID });
    setUsersRoleUpdatePayload(newUsersRoleUpdatePayload);
  };

  return (
    <>
      <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
        <div className="d-flex flex-row-fluid flex-start">
          <div className="row">
            {role?.users.map((user) => (
              <div key={user.id} className="d-flex flex-column flex-md-row gap-md-5">
                <div key={user.id} className="col-12 col-md-7 p-2 m-2 d-flex align-items-center">
                  <div className="d-flex flex-column">
                    <span className="text-gray-800 mb-1 fs-4">
                      {user.first_name} {user.last_name}
                    </span>
                    <span className="text-gray-600 mb-1 fs-6">{user.email}</span>
                  </div>
                </div>
                <div className="col-12 col-md-4 p-2 m-2 d-flex align-items-center ">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={String(role.id)}
                    onChange={(event) => handleRoleChange(user.id, (event.target as HTMLSelectElement).value as ID)}
                  >
                    {rolesList.map((roleItem) => (
                      <option key={roleItem.id} value={String(roleItem.id)}>
                        {roleItem.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-secondary me-2"
          onClick={() => setRoleIdForUpdate((prevState) => ({ ...prevState, type: "EDIT_ROLE" }))}
        >
          {intl.formatMessage({ id: "BUTTON.CANCEL" })}
        </button>

        <button
          type="button"
          onClick={handleSaveChanges}
          className={isSaving ? "btn btn-primary me-2 disabled" : "btn btn-primary me-2"}
        >
          {!isSaving && <span className="indicator-label">{intl.formatMessage({ id: "BUTTON.SAVE" })}</span>}
          {isSaving && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({ id: "FORM.PLEASEWAIT" })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default ModalBodyUsers;
