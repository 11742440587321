import marketoLogo from "../../../../assets/logos/marketo.svg";
import salesforceLogo from "../../../../assets/logos/salesforce.svg";
import { OBJECT_KEYS, endpointMap } from "../../../../helpers/apiHelpers";

export const mktoBasicInfo = {
  id: "1",
  logo: marketoLogo,
  title: "REFRESHDATA.CARD.TITLE.MKTO",
  description: "REFRESHDATA.CARD.DESC.MKTO",
  app: "mkto"
};

export const sfdcBasicInfo = {
  id: "2",
  logo: salesforceLogo,
  title: "REFRESHDATA.CARD.TITLE.SFDC",
  description: "REFRESHDATA.CARD.DESC.SFDC",
  app: "sfdc"
};

export const mktoAssetsInfo = {
  id: "25",
  logo: marketoLogo,
  title: "REFRESHDATA.CARD.TITLE.MKTOASSET",
  description: "REFRESHDATA.CARD.DESC.MKTOASSETS",
  app: "mktoAssets"
};

/**
 * Marketo objects refresh options
 */
export const mktoRefreshOptions = [
  {
    id: "3",
    title: "REFRESHDATA.OPTION.TITLE.MKTO.LEAD",
    description: "REFRESHDATA.OPTION.DESC.MKTO.LEAD",
    buttonText: "REFRESHDATA.OPTION.BUTTON.MKTO.LEAD",
    refreshAPI: endpointMap["marketo-fetch-metadata"],
    payload: { objectKeys: [OBJECT_KEYS.MKTO.LEAD] },
    objectKey: OBJECT_KEYS.MKTO.LEAD,
    statusKey: "lead_fields",
    lastSuccessFullRefresh: "",
    assetIcon: "square-brackets"
  },
  {
    id: "4",
    title: "REFRESHDATA.OPTION.TITLE.MKTO.COMPANY",
    description: "REFRESHDATA.OPTION.DESC.MKTO.COMPANY",
    buttonText: "REFRESHDATA.OPTION.BUTTON.MKTO.COMPANY",
    refreshAPI: endpointMap["marketo-fetch-metadata"],
    payload: { objectKeys: [OBJECT_KEYS.MKTO.COMPANY] },
    objectKey: OBJECT_KEYS.MKTO.COMPANY,
    statusKey: "company_fields",
    lastSuccessFullRefresh: "",
    assetIcon: "square-brackets"
  },
  {
    id: "5",
    title: "REFRESHDATA.OPTION.TITLE.MKTO.OPPORTUNITY",
    description: "REFRESHDATA.OPTION.DESC.MKTO.OPPORTUNITY",
    buttonText: "REFRESHDATA.OPTION.BUTTON.MKTO.OPPORTUNITY",
    refreshAPI: endpointMap["marketo-fetch-metadata"],
    payload: { objectKeys: [OBJECT_KEYS.MKTO.OPPORTUNITY] },
    objectKey: OBJECT_KEYS.MKTO.OPPORTUNITY,
    statusKey: "opportunity_fields",
    lastSuccessFullRefresh: "",
    assetIcon: "square-brackets"
  },
  {
    id: "6",
    title: "REFRESHDATA.OPTION.TITLE.MKTO.PROGRAMMEMBER",
    description: "REFRESHDATA.OPTION.DESC.MKTO.PROGRAMMEMBER",
    buttonText: "REFRESHDATA.OPTION.BUTTON.MKTO.PROGRAMMEMBER",
    refreshAPI: endpointMap["marketo-fetch-metadata"],
    payload: { objectKeys: [OBJECT_KEYS.MKTO.PROGRAMMEMBER] },
    objectKey: OBJECT_KEYS.MKTO.PROGRAMMEMBER,
    statusKey: "program_member_fields",
    lastSuccessFullRefresh: "",
    assetIcon: "square-brackets"
  }
];

/**
 * Marketo assets refresh options
 */
export const mktoAssetsOptions = [
  {
    id: "7",
    title: "REFRESHDATA.OPTION.TITLE.MKTO.ASSETS.FORMS",
    description: "REFRESHDATA.OPTION.DESC.MKTO.ASSETS.FORMS",
    buttonText: "REFRESHDATA.OPTION.BUTTON.MKTO.ASSETS.FORMS",
    refreshAPI: endpointMap["marketo-fetch-assets"],
    payload: { assetNames: ["forms"] },
    objectKey: "forms",
    statusKey: "forms",
    lastSuccessFullRefresh: "",
    assetIcon: "message-text"
  },
  {
    id: "8",
    title: "REFRESHDATA.OPTION.TITLE.MKTO.ASSETS.EMAILS",
    description: "REFRESHDATA.OPTION.DESC.MKTO.ASSETS.EMAILS",
    buttonText: "REFRESHDATA.OPTION.BUTTON.MKTO.ASSETS.EMAILS",
    refreshAPI: endpointMap["marketo-fetch-assets"],
    payload: { assetNames: ["emails"] },
    objectKey: "emails",
    statusKey: "emails",
    lastSuccessFullRefresh: "",
    assetIcon: "sms"
  },
  {
    id: "9",
    title: "REFRESHDATA.OPTION.TITLE.MKTO.ASSETS.FOLDERS",
    description: "REFRESHDATA.OPTION.DESC.MKTO.ASSETS.FOLDERS",
    buttonText: "REFRESHDATA.OPTION.BUTTON.MKTO.ASSETS.FOLDERS",
    refreshAPI: endpointMap["marketo-fetch-assets"],
    payload: { assetNames: ["folders"] },
    objectKey: "folders",
    statusKey: "folders",
    lastSuccessFullRefresh: "",
    assetIcon: "folder"
  }
];

/**
 * Salesforce objects refresh options
 */
export const sfdcRefreshOptions = [
  {
    id: "10",
    title: "REFRESHDATA.OPTION.TITLE.SFDC.LEAD",
    description: "REFRESHDATA.OPTION.DESC.SFDC.LEAD",
    buttonText: "REFRESHDATA.OPTION.BUTTON.SFDC.LEAD",
    refreshAPI: endpointMap["salesforce-fetch-metadata"],
    payload: { objectKeys: [OBJECT_KEYS.SFDC.LEAD] },
    objectKey: OBJECT_KEYS.SFDC.LEAD,
    statusKey: "leads",
    lastSuccessFullRefresh: "",
    assetIcon: "square-brackets"
  },
  {
    id: "11",
    title: "REFRESHDATA.OPTION.TITLE.SFDC.CONTACT",
    description: "REFRESHDATA.OPTION.DESC.SFDC.CONTACT",
    buttonText: "REFRESHDATA.OPTION.BUTTON.SFDC.CONTACT",
    refreshAPI: endpointMap["salesforce-fetch-metadata"],
    payload: { objectKeys: [OBJECT_KEYS.SFDC.CONTACT] },
    objectKey: OBJECT_KEYS.SFDC.CONTACT,
    statusKey: "contacts",
    lastSuccessFullRefresh: "",
    assetIcon: "square-brackets"
  },
  {
    id: "12",
    title: "REFRESHDATA.OPTION.TITLE.SFDC.ACCOUNT",
    description: "REFRESHDATA.OPTION.DESC.SFDC.ACCOUNT",
    buttonText: "REFRESHDATA.OPTION.BUTTON.SFDC.ACCOUNT",
    refreshAPI: endpointMap["salesforce-fetch-metadata"],
    payload: { objectKeys: [OBJECT_KEYS.SFDC.ACCOUNT] },
    objectKey: OBJECT_KEYS.SFDC.ACCOUNT,
    statusKey: "accounts",
    lastSuccessFullRefresh: "",
    assetIcon: "square-brackets"
  },
  {
    id: "13",
    title: "REFRESHDATA.OPTION.TITLE.SFDC.OPPORTUNITY",
    description: "REFRESHDATA.OPTION.DESC.SFDC.OPPORTUNITY",
    buttonText: "REFRESHDATA.OPTION.BUTTON.SFDC.OPPORTUNITY",
    refreshAPI: endpointMap["salesforce-fetch-metadata"],
    payload: { objectKeys: [OBJECT_KEYS.SFDC.OPPORTUNITY] },
    objectKey: OBJECT_KEYS.SFDC.OPPORTUNITY,
    statusKey: "opportunities",
    lastSuccessFullRefresh: "",
    assetIcon: "square-brackets"
  },
  {
    id: "14",
    title: "REFRESHDATA.OPTION.TITLE.SFDC.CAMPAIGN",
    description: "REFRESHDATA.OPTION.DESC.SFDC.CAMPAIGN",
    buttonText: "REFRESHDATA.OPTION.BUTTON.SFDC.CAMPAIGN",
    refreshAPI: endpointMap["salesforce-fetch-metadata"],
    payload: { objectKeys: [OBJECT_KEYS.SFDC.CAMPAIGN] },
    objectKey: OBJECT_KEYS.SFDC.CAMPAIGN,
    statusKey: "campaigns",
    lastSuccessFullRefresh: "",
    assetIcon: "square-brackets"
  },
  {
    id: "15",
    title: "REFRESHDATA.OPTION.TITLE.SFDC.CAMPAIGNMEMBER",
    description: "REFRESHDATA.OPTION.DESC.SFDC.CAMPAIGNMEMBER",
    buttonText: "REFRESHDATA.OPTION.BUTTON.SFDC.CAMPAIGNMEMBER",
    refreshAPI: endpointMap["salesforce-fetch-metadata"],
    payload: { objectKeys: [OBJECT_KEYS.SFDC.CAMPAIGNMEMBER] },
    objectKey: OBJECT_KEYS.SFDC.CAMPAIGNMEMBER,
    statusKey: "campaign_members",
    lastSuccessFullRefresh: "",
    assetIcon: "square-brackets"
  },
  {
    id: "16",
    title: "REFRESHDATA.OPTION.TITLE.SFDC.TASK",
    description: "REFRESHDATA.OPTION.DESC.SFDC.TASK",
    buttonText: "REFRESHDATA.OPTION.BUTTON.SFDC.TASK",
    refreshAPI: endpointMap["salesforce-fetch-metadata"],
    payload: { objectKeys: [OBJECT_KEYS.SFDC.TASK] },
    objectKey: OBJECT_KEYS.SFDC.TASK,
    statusKey: "tasks",
    lastSuccessFullRefresh: "",
    assetIcon: "square-brackets"
  }
];
