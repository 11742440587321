/**
 * @description - Helper function to sort tracker files based on the column and order
 *
 * @param filesList - list of tracker files to be sorted
 * @param order - order of sorting (asc or desc)
 * @param column - column to be sorted by
 * @param defaultCol - default column to be sorted by
 * @returns - sorted list of tracker files
 */

const sortFiles = (filesList: object[], order: string, column: string, defaultCol: string) => {
  let sortedList = [...filesList];

  if (column === undefined || order === undefined) {
    sortedList = sortedList.sort((a: any, b: any) => {
      return (a[defaultCol] || "").localeCompare(b[defaultCol] || "");
    });
  } else {
    sortedList = sortedList.sort((a: any, b: any) => {
      if (order && order === "asc" && column !== undefined) {
        return (a[column] || "").localeCompare(b[column] || "");
      }
      if (order && order === "desc" && column !== undefined) {
        return (b[column] || "").localeCompare(a[column] || "");
      }
      if (!column || column === undefined) {
        return (a.domain || "").localeCompare(b.domain || "");
      }
    });
  }

  return sortedList;
};

export { sortFiles };
