import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

type ConnectionsListCardProps = {
  /** The route path for navigating to the connection configuration page. */
  linkName: string;

  /** The source or URL of the image to display (e.g., app logo). */
  image: any;

  /** The name of the application represented by the card (e.g., "Marketo", "Salesforce"). */
  appName: string;

  /** A boolean indicating the current connection status of the application. */
  connectionStatus: boolean | undefined;

  /** A short description of the application's purpose or functionality. */
  appDescription: string;
};

/**
 * `ConnectionsListCard` renders an individual card for an external application connection.
 * It displays the app's name, description, current connection status, and a button
 * to navigate to the connection setup or edit page.
 *
 * @param {ConnectionsListCardProps} props - The props for the component.
 * @param {string} props.linkName - The route for navigating to the configuration page.
 * @param {any} props.image - The image source or URL to display in the card.
 * @param {string} props.appName - The name of the application.
 * @param {boolean | undefined} props.connectionStatus - The current connection status.
 * @param {string} props.appDescription - A short description of the application.
 *
 * @returns {JSX.Element} A styled card component displaying the application's connection status.
 */
const ConnectionsListCard = ({
  linkName,
  image,
  appName,
  appDescription,
  connectionStatus,
}: ConnectionsListCardProps): JSX.Element => {
  const intl = useIntl();

  /** Determine the badge color based on connection status. */
  const badgeColor = connectionStatus ? "badge-light-success" : "badge-light-danger";

  /** Determine the button text based on connection status. */
  const buttonText = connectionStatus
    ? intl.formatMessage({ id: "SECRETS.CONNECTIONS.EDIT" })
    : intl.formatMessage({ id: "SECRETS.CONNECTIONS.SETUP" });

  /** Determine the connection status text based on the current state. */
  const connectionStatusText = connectionStatus
    ? intl.formatMessage({ id: "SECRETS.CONNECTIONS.STATUS.CONNECTED" })
    : intl.formatMessage({ id: "SECRETS.CONNECTIONS.STATUS.NOTCONNECTED" });

  return (
    <div key={appName} className="col-md-6 col-xl-3">
      <div className="card mt-6">
        <div className="card-header border-0 pt-9 px-6">
          <div className="card-title m-0">
            <div>
              <img src={image} alt={appName} style={{ width: 60, height: 60 }} />
            </div>
          </div>
          <div className="card-toolbar">
            <span className={`badge ${badgeColor} fw-bold me-auto px-4 py-3`}>{connectionStatusText}</span>
          </div>
        </div>
        <div className="card-body p-6">
          <div className="fs-3 fw-bold text-gray-900">{intl.formatMessage({ id: `SECRETS.APPNAME.${appName}` })}</div>
          <p className="text-gray-500 fw-semibold fs-5 mt-3 mb-1">{intl.formatMessage({ id: appDescription })}</p>
        </div>
        <div className="card-footer text-end">
          <Link
            to={linkName}
            className="d-flex justify-content-end align-items-center fs-4 fw-bold"
            aria-label={linkName}
          >
            {buttonText}&nbsp;&gt;
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ConnectionsListCard;
