import { FC } from "react";
import { ColumnInstance } from "react-table";
import { TableColumns } from "../../../core/_models";

type Props = {
  column: ColumnInstance<TableColumns>;
};

const TableCustomHeaderColumn = ({ column }: Props) => (
  <>
    {column.Header && typeof column.Header === "string" ? (
      <th {...column.getHeaderProps()}>{column.render("Header")}</th>
    ) : (
      column.render("Header")
    )}
  </>
);

export { TableCustomHeaderColumn };
