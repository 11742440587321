import { memo } from "react";
import "../style/SingleSelectDropdownWithSearch.css";

type SvgProps = JSX.IntrinsicElements["svg"];

const Svg = memo((props: SvgProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...props} />
));

const DropdownIndicator = memo(() => (
  <div className="SSD_dropdown-indicator">
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
));

export default DropdownIndicator;
