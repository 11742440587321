import {FC} from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ObjectSetup } from '../../helpers/components/object-setup/ObjectSetup'

const MktoSetupWrapper: FC = () => {
  return (
    <>
    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-12'>
        <Routes>
          <Route path="/leads-setup" element={<ObjectSetup platform = "MKTO" object="LEAD" className='mb-xl-3' />} />
          <Route path="/companies-setup" element={<ObjectSetup platform = "MKTO" object="COMPANY" className='mb-xl-3' />} />
          <Route path="/opportunities-setup" element={<ObjectSetup platform = "MKTO" object="OPPORTUNITY" className='mb-xl-3' />} />
          <Route path="/program-members-setup" element={<ObjectSetup platform = "MKTO" object="PROGRAMMEMBER" className='mb-xl-3' />} />

          <Route path='*' element={<Navigate to='/app/home' />} />
        </Routes>
      </div>
    </div>
    {/* end::Row */}
  </>
  )
  
  }

export {MktoSetupWrapper}


