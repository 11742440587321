import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../helpers";
import { useInsJSFilesRequest } from "../../core/InsJSFilesProvider";
import { useIntl } from "react-intl";
import { useAuth } from "../../../auth";
import { hasEditPermission } from "../../../../helpers/permissions";

const ActionsUpdate = (props: any) => {
  const { setItemIdForUpdate } = useInsJSFilesRequest();
  const intl = useIntl();
  const { currentUser } = useAuth();
  const source_tracker = hasEditPermission(currentUser, "source_tracker", "scripts");

  const openEditModal = () => {
    // Check if user has permission to edit the source tracker file
    if (!source_tracker) {
      console.error(intl.formatMessage({ id: "PERMISSIONS.ERRORMESSAGE.NOEDITPERMISSION" }));
      return;
    }
    setItemIdForUpdate((prev) => props.jsFileId);
  };

  return (
    <div className="menu-item px-3">
      <button
        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
        onClick={openEditModal}
        data-tooltip-id={`updateInsJSFile-${props.jsFileId}`}
        aria-label={intl.formatMessage({ id: "INSENTRICJS.EDIT" })}
        disabled={!source_tracker}
      >
        <KTIcon iconName="pencil" className="fs-2 m-0 actionIcon" />
      </button>
      <Tooltip
        id={`updateInsJSFile-${props.jsFileId}`}
        key={`updateInsJSFile-${props.jsFileId}`}
        content={intl.formatMessage({ id: "INSENTRICJS.EDIT" })}
        place="top"
        variant="dark"
        style={{ zIndex: 100 }}
      />
    </div>
  );
};

export default ActionsUpdate;
