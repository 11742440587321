import marketoLogo from "../../../assets/logos/marketo.svg";
import salesforceLogo from "../../../assets/logos/salesforce.svg";
import hubspotLogo from "../../../assets/logos/hubspot.svg";
import slackLogo from "../../../assets/logos/slack.svg";

export const connections = {
  marketo: {
    linkName: "marketo",
    image: marketoLogo,
    appName: "MARKETO",
    appDescription: "SECRETS.MARKETO.CARD.DESC",
    formDescription: "SECRETS.MARKETO.FORM.DESC"
  },
  salesforce: {
    linkName: "salesforce",
    image: salesforceLogo,
    appName: "SALESFORCE",
    appDescription: "SECRETS.SFDC.CARD.DESC",
    formDescription: "SECRETS.SFDC.FORM.DESC"
  },
  hubspot: {
    linkName: "hubspot",
    image: hubspotLogo,
    appName: "HUBSPOT",
    appDescription: "SECRETS.HUBSPOT.CARD.DESC",
    formDescription: "SECRETS.HUBSPOT.FORM.DESC"
  },
  slack: {
    linkName: "slack",
    image: slackLogo,
    appName: "SLACK",
    appDescription: "SECRETS.SLACK.CARD.DESC",
    formDescription: "SECRETS.SLACK.FORM.DESC"
  }
};
