import { useEffect } from "react";
import { useThemeMode } from "../../../../layout/partials";
import { toAbsoluteUrl } from "../../../../helpers";
import PasswordGen from "./components/PasswordGen";

const SetPassword = () => {
  const { mode } = useThemeMode();
  useEffect(() => {
    document.body.style.backgroundImage =
      mode === "dark"
        ? `url(${toAbsoluteUrl("/media/auth/bg4-dark.jpg")})`
        : `url(${toAbsoluteUrl("/media/auth/bg4.jpg")})`;
  }, [mode]);

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid">
        <div className="d-flex flex-column flex-center p-10">
          <div className="card card-flush  w-lg-650px py-5">
            <div className="card-body">
              <PasswordGen />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
