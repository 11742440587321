import { Dispatch, SetStateAction } from "react";

export type ID = string | undefined | null;

export type RoleUser = {
  id: ID;
  first_name: string;
  last_name: string;
  email: string;
};

export type Permission = {
  id: ID;
  name: string;
  description: string;
  stage: string;
  action: string;
  application: string;
  object: string;
  resource: string;
  created_at: string;
  updated_at: string;
};

export type Role = {
  id: ID;
  name: string;
  description: string;
  active: boolean;
  created_at: string;
  created_by: string;
  users: Array<RoleUser>;
  permissions: Array<string>;
};

export type IdForUpdate = {
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // STRING => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  id: ID;
  type: "ADD_NEW_ROLE" | "LIST_USERS" | "EDIT_ROLE" | null;
};

export type RolePayload = {
  name: string;
  description: string;
  permissions: Array<string>;
};

export type rolesAndPermissionsContextProps = {
  roles: Array<Role>;
  updateRoleObject: RolePayload;
  setUpdateRoleObject: Dispatch<SetStateAction<RolePayload>>;
  permissions: Array<Permission>;
  permissionHasChanged?: boolean;
  setPermissionHasChanged: Dispatch<SetStateAction<boolean>>;
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  selected: Array<IdForUpdate>;
  roleIdForUpdate: IdForUpdate;
  setRoleIdForUpdate: Dispatch<SetStateAction<IdForUpdate>>;
  isLoading?: boolean;
  refetch?: () => void;
};

export const initialRoleView: rolesAndPermissionsContextProps = {
  roles: [],
  updateRoleObject: { name: "", description: "", permissions: [] },
  setUpdateRoleObject: () => {},
  permissions: [],
  permissionHasChanged: false,
  setPermissionHasChanged: () => {},
  isSubmitting: false,
  setIsSubmitting: () => {},
  selected: [],
  roleIdForUpdate: { id: undefined, type: null },
  setRoleIdForUpdate: () => {}
};

export type RBACContextProps = {
  allowedUserButtonClick: boolean | undefined;
  allowedUserInputEdit: boolean | undefined;
  allowedUserViewPage: boolean | undefined;
};

export const initialRBACView: RBACContextProps = {
  allowedUserButtonClick: false,
  allowedUserInputEdit: false,
  allowedUserViewPage: false
};

export type usersRoleUpdateObject = {
  userId: ID;
  roleId: ID;
};