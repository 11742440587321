import FormFieldParametersToken from "./FormFieldParametersToken";
import FormFieldParameter from "./FormFieldUrlParameter";
import FormFieldExcludedDomains from "./FormFieldExcludedDomains";
import FormFieldNonTemplatedEmails from "./FormFieldNonTemplatedEmails";
import FormFieldStartDate from "./FormFieldStartDate";
import FormFieldRunInTwoHours from "./FormFieldRunInTwoHours";
import { useAuth } from "../../../../auth";
import { hasEditPermission } from "../../../../../helpers/permissions";

type FormFieldsProps = {
  errors: any;
  touched: any;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

const FormFields = ({ errors, touched, values, setFieldValue }: FormFieldsProps) => {
  const { currentUser } = useAuth();
  const marketo_link_appender = hasEditPermission(currentUser, "marketo", "qs_appender_settings");

  return (
    <>
      <div className="mb-6">
        <FormFieldParametersToken errors={errors} touched={touched} editPermission={marketo_link_appender} />
      </div>
      <div className="mb-6">
        <FormFieldParameter errors={errors} touched={touched} editPermission={marketo_link_appender} />
      </div>
      <div className="mb-6">
        {" "}
        <FormFieldExcludedDomains errors={errors} touched={touched} editPermission={marketo_link_appender} />
      </div>
      <div className="mb-6">
        <FormFieldNonTemplatedEmails errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} editPermission={marketo_link_appender} />
      </div>

      <div className="mb-6">
        <FormFieldRunInTwoHours errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} editPermission={marketo_link_appender} />
      </div>

      <div className="mb-6">
        <FormFieldStartDate errors={errors} touched={touched} editPermission={marketo_link_appender}  />
      </div>
    </>
  );
};

export default FormFields;
