import { useRolesView } from "../../../core/RolesAndPermissionsProvider";
import { createRole } from "../../../core/_requests";
import { validationSchema } from "../../schema/validationSchema";
import { TextField } from "../../../../../layout/components/form";
import Form from "../../../../../layout/components/form/Form";
import { useIntl } from "react-intl";

interface formValues {
  name: string;
  description: string;
  permissions: string[];
}

const ModalBodyAddNewRole = () => {
  const intl = useIntl();
  const { setUpdateRoleObject, setRoleIdForUpdate, refetch } = useRolesView();
  const initialValues: formValues = {
    name: "",
    description: "",
    permissions: []
  };

  const fields = [
    {
      label: intl.formatMessage({ id: "ROLES.NEWROLE.FORM.ROLENAME.TITLE" }),
      description: intl.formatMessage({ id: "ROLES.NEWROLE.FORM.ROLENAME.DESC" }),
      id: "name",
      optional: false,
      fieldComponent: TextField
    },
    {
      label: intl.formatMessage({ id: "ROLES.NEWROLE.FORM.ROLEDESC.TITLE" }),
      description: intl.formatMessage({ id: "ROLES.NEWROLE.FORM.ROLEDESC.DESC" }),
      id: "description",
      optional: false,
      fieldComponent: TextField
    }
  ];

  const createNewRole = async (
    values: formValues,
    formikHelpers: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    console.log("values", values);
    formikHelpers.setSubmitting(true);

    await createRole(values);
    setUpdateRoleObject({ permissions: [], name: "", description: "" });
    setRoleIdForUpdate({ id: values.name, type: "EDIT_ROLE" });
    if (refetch) {
      refetch();
    }
    formikHelpers.setSubmitting(false);
  };

  return (
    <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
      <div className="d-flex flex-row-fluid flex-start">
        <div className="w-100">
          <Form
            fields={fields}
            validationSchema={validationSchema}
            initialValues={initialValues}
            handleSubmit={createNewRole}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalBodyAddNewRole;
