import { useIntl } from "react-intl";
import { KTIcon } from "../../../../helpers";
import { useRolesView } from "../../core/RolesAndPermissionsProvider";

const NewRoleBtn = () => {
  const intl = useIntl();

  const { setRoleIdForUpdate } = useRolesView();

  const handleAddNewRole = () => {
    setRoleIdForUpdate((prevState) => {
      return { id: null, type: "ADD_NEW_ROLE" };
    });
  };

  return (
    <button
      type="button"
      className="btn btn-primary d-flex align-items-center justify-content-center"
      onClick={handleAddNewRole}
      aria-label={intl.formatMessage({ id: "ROLES.BUTTON.ADDNEWROLE" })}
    >
      <KTIcon iconName="plus" className="fs-2" aria-hidden="true" />
      <span className="d-none d-md-block">{intl.formatMessage({ id: "ROLES.BUTTON.ADDNEWROLE" })}</span>
    </button>
  );
};

export default NewRoleBtn;
