import clsx from "clsx";
import { PropsWithChildren, useMemo } from "react";
import { HeaderProps } from "react-table";
import { useIntl } from "react-intl";
import { TableColumns } from "../../../core/_models";
import { useInsJSFilesRequest } from "../../../core/InsJSFilesProvider";

type Props = {
  className?: string;
  title?: string;
  tableProps: PropsWithChildren<HeaderProps<TableColumns>>;
};

export type Order = "asc" | "desc" | undefined;

const TableCustomHeader = ({ className, title, tableProps }: Props) => {
  const intl = useIntl();

  const id = tableProps.column.id;
  const { actionsObject, setActionsObject } = useInsJSFilesRequest();

  const isSelectedForSorting = useMemo(() => {
    return actionsObject.sort?.column && actionsObject.sort?.column === id;
  }, [actionsObject.sort?.column, id]);

  const order: "asc" | "desc" | undefined = useMemo(() => actionsObject?.sort?.order, [actionsObject?.sort?.order]);

  const sortColumn = () => {
    if (id === "actions" || id === "embed_code") {
      return;
    }

    if (!isSelectedForSorting) {
      setActionsObject((prev: any) => ({
        ...prev,
        sort: { column: id, order: "asc" }
      }));
      return;
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === "asc") {
        setActionsObject((prev: any) => ({
          ...prev,
          sort: { column: id, order: "desc" }
        }));
        return;
      }

      setActionsObject((prev: any) => ({
        ...prev,
        sort: { column: undefined, order: undefined }
      }));
    }
  };

  const { key, ...headerProps } = tableProps.column.getHeaderProps();

  return (
    <th
      key={key} // Pass `key` directly here
      {...headerProps}
      className={clsx(className, isSelectedForSorting && order !== undefined && `table-sort-${order}`)}
      style={{ cursor: "pointer" }}
      onClick={sortColumn}
    >
      {intl.formatMessage({ id: title })}
    </th>
  );
};

export { TableCustomHeader };
