import { Form as BootstrapForm } from "react-bootstrap";
import { ErrorMessage, useField } from "formik";
import Slider from "rc-slider";
import { handleRender as customHandleRender } from "../../../../helpers/components/handleRender";
import "rc-slider/assets/index.css";
import { useIntl } from "react-intl";

type SliderFieldProps = {
  id: string;
  label: string;
  description?: string;
  hideLabel?: boolean;
  hideDescription?: boolean;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
};

/**
 * Slider field component that uses the "rc-slider" component to render a slider input field.
 *
 * @prop {string} id - The id of the field
 * @prop {string} label - The label for the field
 * @prop {string} [description] - The description for the field
 * @prop {boolean} [hideLabel=false] - Whether to hide the label or not
 * @prop {boolean} [hideDescription=false] - Whether to hide the description or not
 * @prop {string} [ariaLabelledBy] - The id of the element that labels the field
 * @prop {string} [ariaDescribedBy] - The id of the element that describes the field
 * @prop {string} [props] - Any additional props to pass to the field
 */

export const SliderField = ({
  id,
  label,
  description,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}: SliderFieldProps) => {
  const intl = useIntl();
  const [, meta, helpers] = useField(id);
  const { value } = meta;
  const { setValue } = helpers;

  const handleChange = (value: number | number[]) => {
    if (Array.isArray(value)) {
      setValue(value[0]);
    } else {
      setValue(value);
    }
  };

  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label && (
        <BootstrapForm.Label htmlFor={`${id}-slider`} id={`${id}-label`}>
          {label}
        </BootstrapForm.Label>
      )}
      <div style={{ margin: 10, marginLeft: 0 }}>
        <Slider
          key={`${id}-slider`}
          style={{ marginBottom: 20 }}
          onChange={handleChange}
          min={1}
          max={99}
          value={value}
          marks={{ 1: "1%", 50: "50%", 99: "99%" }}
          handleRender={(origin, props) =>
            customHandleRender(origin, { ...props, children: origin.props.children, visible: true })
          }
          aria-labelledby={ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)}
          aria-describedby={ariaDescribedBy || (description ? `${id}-description` : undefined)}
          {...props}
        />
      </div>
      <BootstrapForm.Control.Feedback type="invalid">
        <ErrorMessage name={id} render={(msg) => intl.formatMessage({ id: msg })} />
      </BootstrapForm.Control.Feedback>
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

export default SliderField;
