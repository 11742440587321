/**
 * @name handleOutsideClick
 * @description Handles the click event outside of the element to close the dropdown
 *
 * @param event MouseEvent
 * @param ref The ref of the element to check if the click is outside of it
 * @param setIsOpen The state setter for the dropdown
 */
export const handleOutsideClick = (
  event: MouseEvent,
  ref: React.RefObject<HTMLElement>,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  if (ref.current && !ref.current.contains(event.target as Node) && typeof setIsOpen === "function") {
    setIsOpen(false);
  }
};

/**
 * @name handleEscKeydown
 * @description Handles the ESC keydown event to close the dropdown
 *
 * @param event KeyboardEvent
 * @param setIsOpen The state setter for the dropdown
 */

export const handleEscKeydown = (
  event: KeyboardEvent,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  if (event.key === "Escape" && typeof setIsOpen === "function") {
    setIsOpen(false);
  }
};


/**
 * @description Throttle the click and keydown event handlers
 * 
 * @param func The function to execute after the throttle
 * @param wait The time to wait before the next event
 * @returns The executed function
 */

export const throttle = (func: any, wait = 250) =>{
  let isWaiting = false;
  return function executedFunction(this: any, ...args: any) {
    if (!isWaiting) {
      func.apply(this, args);
      isWaiting = true;
      setTimeout(() => {
        isWaiting = false;
      }, wait);
    }
  };
}
