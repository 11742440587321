import { useIntl } from "react-intl";
import { createFields, handleConnectionSubmit } from "../core/_helpers";
import { ConnectionFormProps } from "../core/_models";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useConnections } from "../core/ConnectionsProvider";
import Form from "../../../layout/components/form/Form";
import InsSpinner from "../../../layout/components/spinner/InsSpinner";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { auth } from "../../../firebaseApp";
import { useAuth } from "../../auth";
import { hasEditPermission } from "../../../helpers/permissions";

const MySwal = withReactContent(Swal);

/**
 * `ConnectionForm` is a form component for managing connection configurations.
 * It allows users to input and update credentials for connecting to various APIs.
 * The form dynamically adapts based on user permissions and validation rules.
 *
 * @param {FieldConfig[]} fieldConfigs - Configuration for the form fields, including labels, validation, and user permissions.
 * @param {any} validationSchema - The validation schema for the form fields.
 * @param {SecretConfig} secretConfig - Configuration for the secret keys used in the form.
 * @param {any} initialValues - The initial values for the form fields.
 * @param {boolean} isLoading - Flag indicating whether the form is in a loading state.
 * @param {boolean} isEnabled - Flag indicating whether the connection is enabled.
 * @param {string} enableLink - The link to enable the connection.
 * @returns {JSX.Element} The connection configuration form.
 */

const ConnectionForm = ({
  fieldConfigs,
  validationSchema,
  secretConfig,
  initialValues,
  isLoading,
  isEnabled = true,
  enableLink = ""
}: ConnectionFormProps): JSX.Element => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const account_connections = hasEditPermission(currentUser, "account", "credentials");
  
  const { refetch } = useConnections();

  const fields = createFields(intl, fieldConfigs);

  const handleSubmit = async (values: any, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
    // Check if user has permission to update account credentials
    if (!account_connections) {
      console.error(intl.formatMessage({ id: "PERMISSIONS.ERRORMESSAGE.NOEDITPERMISSION" }));
      return;
    }

    const requiredSecrets = secretConfig.secretKeys.reduce(
      (o, key: any) => Object.assign(o, { [key[0]]: values[key[1]] }),
      {}
    );

    let optionalSecrects = {};
    if (secretConfig.optionalKeys && secretConfig.optionalKeys.length > 0) {
      optionalSecrects = secretConfig.optionalKeys.reduce(
        (o, key: any) => Object.assign(o, { [key[0]]: values[key[1]] }),
        {}
      );
    }

    const submitSecrets = { ...secretConfig, secretKeys: requiredSecrets, optionalKeys: optionalSecrects };

    handleConnectionSubmit(intl, MySwal, submitSecrets, setSubmitting, refetch);
  };

  return (
    <>
      <div key={Math.random().toString(16).substring(7)} className="my-5">
        {isEnabled ? (
          <>
            {isLoading ? (
              <InsSpinner />
            ) : (
              <Form
                className="py-2"
                fields={fields}
                initialValues={initialValues}
                validationSchema={validationSchema}
                handleSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
                buttonIsDisabled={!account_connections}
              />
            )}
          </>
        ) : (
          <div>
            <div className="py-3">
              {intl.formatMessage({
                id: "SECRETS.SFDC.ENABLE_INSTRUCTIONS"
              })}
            </div>
            <Button disabled={!account_connections}>
              <Link style={{ color: "white" }} to={enableLink}>
                {intl.formatMessage({
                  id: "SECRETS.SFDC.ENABLE_LINK_TEXT"
                })}
              </Link>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default ConnectionForm;
