import { useIntl } from "react-intl";
import PageHeader from "../../layout/components/content/PageHeader";
import { KTIcon } from "../../helpers";
import InsentricJSModal from "./edit-modal/InsentricJSModal";
import "./style/style.scss";
import { InsJSFilesProvider, useInsJSFilesRequest } from "./core/InsJSFilesProvider";
import InsJsFilesTable from "./components/table/InsJsFilesTable";
import { SourceTrackerHeader } from "./components/SourceTrackerHeader";

const InsentricJsPage = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useInsJSFilesRequest();
  const openNewFileModal = () => {
    setItemIdForUpdate(null);
  };
  const intl = useIntl();

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level1name="SIDEBAR.HEADER.CONFIGURATION"
          level1link="/app/home"
          level2name="SIDEBAR.ITEM.INSENTRIJSCONFIGURATOR"
          instructions="SETTINGS.ACCOUNT.INSENTRICJS.INSTRUCTIONS"
        />
      </div>

      <div className={`card`}>
        <SourceTrackerHeader openNewFileModal={openNewFileModal} />
        <div className="row px-8 pb-8">
          <InsJsFilesTable />
        </div>
      </div>
      {itemIdForUpdate !== undefined && <InsentricJSModal />}
    </>
  );
};

const InsentricJsConfigurator = () => {
  return (
    <InsJSFilesProvider>
      <InsentricJsPage />
    </InsJSFilesProvider>
  );
};

export default InsentricJsConfigurator;
