import { useIntl } from "react-intl";
import { TextField } from "../../../../layout/components/form";
import { TimezoneSelect } from "../../../../layout/components/timezone";
import { FC } from "react";
import { useAuth } from "../../../auth";
import { hasEditPermission } from "../../../../helpers/permissions";

type ProfileSettingsFieldsProps = {
  touched: any;
  errors: any;
  selectedTimezone: string;
  setTimezone: (value: string) => void;
  isSaving: boolean;
};

const ProfileSettingsFields: FC<ProfileSettingsFieldsProps> = ({
  touched,
  errors,
  selectedTimezone,
  setTimezone,
  isSaving
}) => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const user_profile = hasEditPermission(currentUser, "user", "profile");

  return (
    <>
      <div className="mb-10">
        <TextField
          id="first_name"
          label={intl.formatMessage({ id: "USER.FIRSTNAME" })}
          placeholder=""
          description=""
          classes=""
          ariaLabelledBy=""
          ariaDescribedBy=""
          isInvalid={!!errors.first_name && touched.first_name}
          disabled={!user_profile}
        />
      </div>
      <div className="mb-10">
        <TextField
          id="last_name"
          label={intl.formatMessage({ id: "USER.LASTNAME" })}
          placeholder=""
          description=""
          classes=""
          ariaLabelledBy=""
          ariaDescribedBy=""
          isInvalid={!!errors.last_name && touched.last_name}
          disabled={!user_profile}
        />
      </div>
      <div className="mb-3">
        <label className="form-label" id="timezone-label">
          {intl.formatMessage({ id: "USER.TIMEZONE" })}
        </label>
        <TimezoneSelect
          ariaLabelledBy="timezone-label"
          value={selectedTimezone}
          onChange={setTimezone}
          disabled={!user_profile}
        />
        {!!errors.timezone && touched.timezone && (
          <p className="text-danger">{intl.formatMessage({ id: errors.timezone })}</p>
        )}
      </div>
      <div className="d-flex justify-content-end mt-5">
        <button type="submit" className="btn btn-primary" disabled={!user_profile || isSaving}>
          {!isSaving ? (
            intl.formatMessage({ id: "BUTTON.SAVE" })
          ) : (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({ id: "BUTTON.SAVING" })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default ProfileSettingsFields;
