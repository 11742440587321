import React from 'react';
import icons from '../icons-config/icons';
import { getLayoutFromLocalStorage } from '../../layout/core';

type Props = {
  className?: string;
  iconType?: 'duotone' | 'solid' | 'outline';
  iconName?: string; // Make optional for scenarios where svgIcon is used
  svgIcon?: React.ReactNode; // New prop to accept SVGs as JSX
};

const KTIcon: React.FC<Props> = ({ className = '', iconType, iconName, svgIcon }) => {
  const layoutConfig = getLayoutFromLocalStorage();
  const finalIconType = iconType ?? layoutConfig?.main?.iconType ?? 'solid';

  if (svgIcon) {
    // Render SVG directly if provided
    return (
      <span className={`kt-icon-svg ${className}`}>
        {svgIcon}
      </span>
    );
  }

  // Render existing behavior for string-based icons
  if (iconName) {
    return (
      <i
        aria-hidden={true}
        className={`ki-${finalIconType} ki-${iconName}${className && ' ' + className}`}
      >
        {finalIconType === 'duotone' &&
          Array.from({ length: icons[iconName] }).map((_, index) => (
            <span
              key={`${finalIconType}-${iconName}-${className}-path-${index + 1}`}
              className={`path${index + 1}`}
            ></span>
          ))}
      </i>
    );
  }

  // Default fallback
  return null;
};

export { KTIcon };
