import axios from "axios";
import { StatusCodes } from "http-status-codes";
import { IntlShape } from "react-intl";
import { Field, FieldConfig, SubmitSecretKeys } from "./_models";
import { PasswordField } from "../../../layout/components/form";

/**
 * Creates form fields based on the field configuration and user permissions.
 *
 * @param {IntlShape} intl - React Intl object for handling translations.
 * @param {FieldConfig[]} fieldConfigs - Configuration object for the form fields.
 * @returns {Array<{
 *   label: string;
 *   id: string;
 *   initialValue: any;
 *   optional: boolean;
 *   fieldComponent: typeof PasswordField;
 *   disabled?: boolean;
 *   showIcon?: boolean;
 * }>} The form fields to be rendered.
 */
export const createFields = (intl: IntlShape, fieldConfigs: FieldConfig[]): Field[] =>
  fieldConfigs.map(({ id, labelId, disabled, showIcon }) => ({
    label: intl.formatMessage({ id: labelId }),
    id,
    initialValue: undefined,
    optional: false,
    fieldComponent: PasswordField,
    disabled: disabled,
    hideShowPasswordIcon: !showIcon,
    refreshType: "",
    showRefreshButton: false
  }));

/**
 * Handles form submission by validating input and making API requests to save credentials.
 *
 * @param {IntlShape} intl - React Intl object for handling translations.
 * @param {any} MySwal - SweetAlert object for displaying alerts.
 * @param {SecretConfig} secretConfig - Configuration object for the secrets.
 * @param {Function} setSubmitting - Callback to toggle the form's submitting state.
 * @param {Function} refetch - Callback to refetch the connection data.
 */

export const handleConnectionSubmit = async (
  intl: IntlShape,
  MySwal: any,
  secretConfig: SubmitSecretKeys,
  setSubmitting: (isSubmitting: boolean) => void,
  refetch: () => void
) => {
  MySwal.fire({
    title: intl.formatMessage({ id: "SECRETS.ALERT.TITLE" }),
    text: intl.formatMessage({ id: secretConfig.alertDescId }),
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: intl.formatMessage({ id: "SECRETS.ALERT.CONFIRM" }),
    cancelButtonText: intl.formatMessage({ id: "SECRETS.ALERT.CANCEL" })
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      setSubmitting(true);

      try {
        // Test secrets onlz with the required keys
        const testResponse = await axios.post(secretConfig.testEndpoint, secretConfig.secretKeys);

        if (testResponse.status !== StatusCodes.OK) {
          throw new Error(testResponse.data.message);
        }

        // Save secrets with all keys (required and optional)
        await axios.post(secretConfig.saveEndpoint, { ...secretConfig.secretKeys, ...secretConfig.optionalKeys });

        MySwal.fire(
          intl.formatMessage({ id: "SECRETS.TOAST.TITLE.SUCCESS" }),
          intl.formatMessage({ id: "SECRETS.TOAST.SUCCESS" }),
          "success"
        );

        refetch();
      } catch (error: any) {
        MySwal.fire(
          intl.formatMessage({ id: "SECRETS.TOAST.TITLE.ERROR" }),
          `${intl.formatMessage({ id: "SECRETS.TOAST.ERROR" })}: ${error.message}`,
          "error"
        );
      } finally {
        setSubmitting(false);
      }
    }
  });
};
