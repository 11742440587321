import { object, number, string, boolean, array } from "yup";

const allowedChars = /^[a-zA-Z0-9!@#$%^&*()\/\.:;?_\\-\s]+$/;
const validDomain = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const sanitizeValue = (value: string): string => {
  return value.startsWith("@") ? value.slice(1) : value;
};

export const validationSchema = object().shape({
  company_id: string().required("SETTINGS.ACCOUNT.ACCOUNTSETTINGS.REQUIREDFIELDS"),
  company_name: string()
    .required("SETTINGS.ACCOUNT.ACCOUNTSETTINGS.REQUIREDFIELDS")
    .test({
      message: () => "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.INVALID",
      test: (value) => allowedChars.test(value)
    })
    .max(100, "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.MAXLENGTH")
    .trim(),
  company_main_timezone: string().required("SETTINGS.ACCOUNT.ACCOUNTSETTINGS.REQUIREDFIELDS"),
  company_fiscal_year_type: boolean().required("SETTINGS.ACCOUNT.ACCOUNTSETTINGS.REQUIREDFIELDS"),
  company_fiscal_year_start_month: number().required("SETTINGS.ACCOUNT.ACCOUNTSETTINGS.REQUIREDFIELDS"),
  client_email_domains: array()
    .of(
      object({
        value: string().transform((value) => sanitizeValue(value)),
        label: string()
      })
    )
    .test({
      message: () => "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.INVALIDEMAILDOMAIN",
      test: (values) => values?.map((value) => validDomain.test(value.value ?? '')).every((value) => value === true)
    })
    .min(1, "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.MINONEVALUE")
    .required("SETTINGS.ACCOUNT.ACCOUNTSETTINGS.EMAILDOMAINS.REQUIRED")
});
