import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useIntl } from "react-intl";

import CustomTooltip from "../CustomTooltip";
import ObjectSetupSaveBtn from "./ObjectSetupSaveBtn";
import ObjectSetupRenderFields from "./ObjectSetupRenderFields";
import ObjectSetupSearch from "./ObjectSetupSearch";
import PageHeader from "../../../layout/components/content/PageHeader";
import { MKTOObject, OBJECT_KEYS, SFDCObject } from "../../apiHelpers";
import ObjectSetupCheckAll from "./ObjectSetupCheckAll";
import ObjectSetupUnCheckAll from "./ObjectSetupUnCheckAll";

const platformNames = {
  MKTO: "Marketo",
  SFDC: "Salesforce"
}

const platformEndpoints = {
  MKTO: "marketo",
  SFDC: "salesforce"
}

interface MKTOProps {
  platform: "MKTO";
  object: MKTOObject
}

interface SFDCProps {
  platform: "SFDC";
  object: SFDCObject
}

type Props = {
  className?: string;
} & (MKTOProps | SFDCProps);

interface FieldItem {
  description: string;
  displayName: string;
  insentricStatus: string;
  isApiCreated: boolean;
  isCustom: boolean;
  isHidden: boolean;
  isHtmlEncodingInEmail: boolean;
  isSensitive: boolean;
  isUpdateable: boolean;
  length: string;
  name: string;
  type: string;
  tempIsDisabled: boolean;
  tempIsChecked: boolean;
}

interface FieldItems extends Array<FieldItem> {}

const ObjectSetup: React.FC<Props> = ({ className, object, platform }) => {

  let objectKey: string;

  switch (platform) {
    case "MKTO":
      objectKey = OBJECT_KEYS.MKTO[object];
      break;
    case "SFDC":
      objectKey = OBJECT_KEYS.SFDC[object];
      break;
    default:
      throw new Error("Invalid platform");
  }

  const [hasChange, setHasChange] = useState<boolean>(false);
  const [updatedFieldsList, setUpdatedFieldsList] = useState<FieldItems>([]);
  const [renderFieldsList, setRenderFieldsList] = useState<FieldItems>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [objectToSave, setObjectToSave] = useState<object>([]);
  const intl = useIntl();

  // Update objectToSave function
  const updateObjectToSave = useCallback((fieldList: FieldItems) => {
    const tempArray: Array<object> = [];
    fieldList.forEach((field) => {
      if (field.tempIsDisabled === false) {
        tempArray.push({
          name: field.name,
          include: field.tempIsChecked
        });
      }
    });
    return tempArray;
  }, []);

  // Based on the object fetch the fields and update the arrays
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/schema/${platformEndpoints[platform]}?objects=${objectKey}`);
      const fields: FieldItems = response.data[objectKey].map((field: FieldItem) => {
        if (field.insentricStatus) {
          switch (field.insentricStatus) {
            case "default":
              return { ...field, tempIsDisabled: true, tempIsChecked: true };
            case "custom":
              return { ...field, tempIsDisabled: false, tempIsChecked: true };
            case "available":
              return { ...field, tempIsDisabled: false, tempIsChecked: false };
            case "unavailable":
              return { ...field, tempIsDisabled: true, tempIsChecked: false };
            default:
              return field;
          }
        }
        return field;
      }).sort((a : FieldItem, b : FieldItem) => a.displayName.localeCompare(b.displayName));

      setUpdatedFieldsList(fields);
      setRenderFieldsList(fields);
      setObjectToSave(updateObjectToSave(fields));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [platform, objectKey, updateObjectToSave]);

  // Run fetchData if the object has changed
  useEffect(() => {
    setHasChange(false);
    setRenderFieldsList([]);
    fetchData();
  }, [fetchData, object]);

  // Initially the Save button is disabled since there are no changes in the fields
  // When one of the fields is checked/unchecked, it enables the Save button
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasChange) setHasChange(true);

    const tempArray: FieldItems = renderFieldsList.map((item) => {
      if (item.name === e.target.name) {
        return { ...item, tempIsChecked: !item.tempIsChecked };
      }
      return item;
    });

    setObjectToSave(updateObjectToSave(tempArray));
    setUpdatedFieldsList(tempArray);
    setRenderFieldsList(tempArray);
  };

  // If the UnCheck All button is clicked it unchecks all the checked fields, as well as updates the objectToSave object
  const handleCheckAll = () => {
    if (!hasChange) setHasChange(true);

    const tempArray: FieldItems = renderFieldsList.map((item) => {
      if (!item.tempIsDisabled) {
        return { ...item, tempIsChecked: true };
      }
      return item;
    });

    setObjectToSave(updateObjectToSave(tempArray));
    setUpdatedFieldsList(tempArray);
    setRenderFieldsList(tempArray);
  };

  const handleUnCheckAll = () => {
    if (!hasChange) setHasChange(true);

    const tempArray: FieldItems = renderFieldsList.map((item) => {
      if (!item.tempIsDisabled) {
        return { ...item, tempIsChecked: false };
      }
      return item;
    });

    setObjectToSave(updateObjectToSave(tempArray));
    setUpdatedFieldsList(tempArray);
    setRenderFieldsList(tempArray);
  };

  // Since there are more than 100 fields in some cases it's quicker to search for the field that should be included
  // It searches in the API and display names, and the search is not case sensitive
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLocaleLowerCase();
    if (searchValue.length > 0) {
      setRenderFieldsList(
        updatedFieldsList.filter(
          (field) =>
            field.displayName.toLocaleLowerCase().includes(searchValue) ||
            field.name.toLocaleLowerCase().includes(searchValue)
        )
      );
    } else {
      setRenderFieldsList(updatedFieldsList);
    }
  };

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level1name="SIDEBAR.SUBHEAD.MKTOFIELDS"
          level1link="/app/home"
          level2name={`SETTINGS.MKTO.OBJECTFIELDS.${object}`}
          instructions="SETTINGS.OBJECTFIELDS.INSTRUCTIONS"
        />
      </div>

      <div className={`card ${className}`}>
        <div className="row p-8">
          <div className="col-12">
            <h4 className="text-muted fw-semibold d-block">
              {intl.formatMessage({ id: "SETTINGS.OBJECTFIELDS.FIELDTITLE" })}
              <CustomTooltip
                tooltipText={`Fetched ${platformNames[platform]} fields`}
                placement="right"
                variant="info"
                trigger="click"
              />
            </h4>
          </div>
        </div>

        <div className="row px-8">
          <div className="col--12 p-3">
            <div className="row border-bottom" style={{ marginBottom: 18 }}>
              <div className="col-sm-8 d-flex flex-md-row p-3">
                <ObjectSetupCheckAll handleCheckAll={handleCheckAll} />
                <ObjectSetupUnCheckAll
                  handleUnCheckAll={handleUnCheckAll}
                />
                <ObjectSetupSearch handleSearch={handleSearch} />
              </div>
              <div className="col-sm-4 d-flex flex-md-row justify-content-end p-3">
                <ObjectSetupSaveBtn
                  hasChange={hasChange}
                  updatedObject={objectToSave}
                  object={objectKey}
                  platformEndpoint={platformEndpoints[platform]}
                  platform={platform}
                />
              </div>
            </div>
            <div>
              <ObjectSetupRenderFields
                handleChange={handleChange}
                loading={loading}
                renderFieldsList={renderFieldsList}
              />
            </div>
          </div>
        </div>

        <div className="card-footer py-6">
          <div className="row">
            <div className="col-lg-12 text-end">
              <ObjectSetupSaveBtn
                hasChange={hasChange}
                updatedObject={objectToSave}
                object={objectKey}
                platformEndpoint={platformEndpoints[platform]}
                platform={platform}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ObjectSetup };
