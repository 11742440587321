// Accessibility Notice:
// All jsx-a11y rules for keyboard accessibility and interactive elements have been applied in this file.
// However, there may need to be some sorting of tabindex on the final renders this icon is on. - CG 2024-10-04
import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../../helpers";

const ShowPasswordIcon = ({
  id,
  showPassword,
  setShowPassword
}: {
  id: string;
  showPassword: boolean;
  setShowPassword: () => void;
}) => {
  return (
    <div
      id={id}
      style={{
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer"
      }}
      onClick={setShowPassword}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          setShowPassword();
        }
      }}
      role="button"
      tabIndex={0}
      aria-pressed={showPassword}
      aria-label={showPassword ? "Hide Password" : "Show Password"}
      data-tooltip-id="showHidePassword"
    >
      <KTIcon
        iconName={showPassword ? "eye-slash" : "eye"}
        iconType="outline"
        className="iconBtn fs-1"
      />
      <Tooltip
        id="showHidePassword"
        key="showHidePassword"
        content={showPassword ? "Hide Password" : "Show Password"}
        place="top"
        variant="dark"
        style={{ zIndex: 100 }}
      />
    </div>
  );
};
export { ShowPasswordIcon };
