export type refreshOptionsObject = {
  id: string;
  title: string;
  description: string;
  buttonText: string;
  refreshAPI: string;
  payload?: any;
  objectKey: string;
  statusKey?: string;
  lastSuccessFullRefresh: string;
  assetIcon?: string;
};

export type BasicInfoObject = {
  id: string;
  logo: any;
  title: string;
  description: string;
  app: string;
};

const initialBasicInfo: BasicInfoObject = {
  id: "",
  logo: null,
  title: "",
  description: "",
  app: ""
};

export type RefreshDataContextType = {
  mktoRefreshOptions: refreshOptionsObject[];
  sfdcRefreshOptions: refreshOptionsObject[];
  mktoAssetsOptions: refreshOptionsObject[];
  sfdcBasicInfo: BasicInfoObject;
  mktoBasicInfo: BasicInfoObject;
  mktoAssetsInfo: BasicInfoObject;
  refetch: () => void;
  isLoading: boolean;
};

export const initialRefreshDataContext: RefreshDataContextType = {
  mktoRefreshOptions: [],
  sfdcRefreshOptions: [],
  mktoAssetsOptions: [],
  sfdcBasicInfo: initialBasicInfo,
  mktoBasicInfo: initialBasicInfo,
  mktoAssetsInfo: initialBasicInfo,
  refetch: () => {},
  isLoading: false
};
