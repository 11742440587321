import { SingleSelectDropdown } from "../../../../layout/components/form";
import { useIntl } from "react-intl";
import { useAuth } from "../../../auth";
import { hasEditPermission } from "../../../../helpers/permissions";

const Step3 = (props: any) => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const source_tracker = hasEditPermission(currentUser, "source_tracker", "scripts");

  return (
    <div className="w-100">
      <h1>{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP3" })}</h1>
      <div className="text-muted mt-3">
        {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP3.INSTRUCTIONS" })}
      </div>
      <hr />
      <div className="w-100 d-flex flex-column">
        <div className="m-3 flex-fill">
          <h2 id="pmfcSource-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.SOURCE" })}</h2>
          <div className="text-muted my-2" id="pmfcSource-description">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.SOURCE.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="pmfcSource"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="pmfcSource-label"
            ariaDescribedBy="pmfcSource-description"
            isDisabled={!source_tracker}
          />
        </div>
        <div className="m-3 flex-fill">
          <h2 id="pmfcMedium-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.MEDIUM" })}</h2>
          <div className="text-muted my-2" id="pmfcMedium-description">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.MEDIUM.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="pmfcMedium"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="pmfcMedium-label"
            ariaDescribedBy="pmfcMedium-description"
            isDisabled={!source_tracker}
          />
        </div>
        <div className="m-3 flex-fill">
          <h2 id="pmfcCampaign-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.CAMPAIGN" })}</h2>
          <div className="text-muted my-2" id="pmfcCampaign-description">
            {intl.formatMessage({
              id: "SETTINGS.INSENTRIC-JS.WIZARD.CAMPAIGN.INSTRUCTIONS"
            })}
          </div>
          <SingleSelectDropdown
            id="pmfcCampaign"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="pmfcCampaign-label"
            ariaDescribedBy="pmfcCampaign-description"
            isDisabled={!source_tracker}
          />
        </div>
        <div className="m-3 flex-fill">
          <h2 id="pmfcTerm-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.TERM" })}</h2>
          <div className="text-muted my-2" id="pmfcTerm-description">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.TERM.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="pmfcTerm"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="pmfcTerm-label"
            ariaDescribedBy="pmfcTerm-description"
            isDisabled={!source_tracker}
          />
        </div>
        <div className="m-3 flex-fill">
          <h2 id="pmfcContent-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.CONTENT" })}</h2>
          <div className="text-muted my-2" id="pmfcContent-description">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.CONTENT.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="pmfcContent"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="pmfcContent-label"
            ariaDescribedBy="pmfcContent-description"
            isDisabled={!source_tracker}
          />
        </div>
      </div>
    </div>
  );
};

export { Step3 };
