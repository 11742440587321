import { memo } from "react";
import { Row } from "react-table";
import { Role } from "../../core/_models";
import { useRolesView } from "../../core/RolesAndPermissionsProvider";

type Props = {
  row: Row<Role>;
  rowRoleId: any;
};

const CustomRow = ({ row, rowRoleId }: Props) => {
  const { roleIdForUpdate } = useRolesView();
  // Extract key from row props
  const { key, ...rowProps } = row.getRowProps();

  let classes = "text-gray-800 fw-bolder p-2 text-center";

  if (rowRoleId === roleIdForUpdate.id) {
    classes += " bg-light-dark";
  }

  return (
    <tr key={key} {...rowProps}>
      {row.cells.map((cell, index) => {
        // Create a unique key using the row index and cell column ID
        const key = `${row.id}-${cell.column.id}-${index}`;
        return (
          <td {...cell.getCellProps()} key={key} className={classes}>
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};

export default memo(CustomRow);
