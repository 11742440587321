import ConnectionsListCard from "./ConnectionsListCard";
import { connections } from "../core/connections";
import PageHeader from "../../../layout/components/content/PageHeader";
import { useConnections } from "../core/ConnectionsProvider";
import InsSpinner from "../../../layout/components/spinner/InsSpinner";

/**
 * `ConnectionsList` is a component that displays a list of available connections
 * for external services such as Marketo, Salesforce, HubSpot, and Slack.
 * It uses `ConnectionsListCard` for each connection and displays the current connection status.
 * If the data is still loading, it shows a spinner instead.
 *
 * @returns {JSX.Element} The rendered list of connection cards or a loading spinner.
 */
const ConnectionsList = (): JSX.Element => {
  const { isMarketoConnected, isSalesforceConnected, isHubspotConnected, isSlackConnected, isLoading } =
    useConnections();

  return (
    <div className="container-fluid px-3 form-container">
      <div className="w-100">
        <div className="col-12">
          <div className="align-items-start d-flex flex-column mb-7">
            <PageHeader
              level1name="SIDEBAR.HEADER.CONFIGURATION"
              level1link="/app/home"
              level2name="SIDEBAR.SUBHEAD.AUTHKEYS"
              instructions="SECRETS.APICREDENTIALS.INSTRUCTIONS"
            />
          </div>
          <div className="row">
            {!isLoading ? (
              <>
                {/** Render a card for the Marketo connection */}
                <ConnectionsListCard {...connections["marketo"]} connectionStatus={isMarketoConnected} />

                {/** Render a card for the Salesforce connection */}
                <ConnectionsListCard {...connections["salesforce"]} connectionStatus={isSalesforceConnected} />

                {/** Render a card for the HubSpot connection */}
                <ConnectionsListCard {...connections["hubspot"]} connectionStatus={isHubspotConnected} />

                {/** Render a card for the Slack connection */}
                <ConnectionsListCard {...connections["slack"]} connectionStatus={isSlackConnected} />
              </>
            ) : (
              <InsSpinner />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionsList;
