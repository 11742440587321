import { useIntl } from "react-intl";
import ConnectionWrapper from "./ConnectionWrapper";
import ConnectionHubspotForm from "./ConnectionHubspotForm";
import ConnectionMarketoForm from "./ConnectionMarketoForm";
import ConnectionSalesforceForm from "./ConnectionSalesforceForm";
import ConnectionSlackForm from "./ConnectionSlackForm";
import { useConnections } from "../core/ConnectionsProvider";
import InsSpinner from "../../../layout/components/spinner/InsSpinner";

type ConnectionsProps = {
  /** The URL or source of the image displayed in the card. */
  image: any;

  /** The name of the application to configure (e.g., "HUBSPOT", "MARKETO"). */
  appName: string;

  /** The description of the form for the application connection configuration. */
  formDescription: string;
};

/**
 * `ConnectionConfigCard` renders a card component for configuring a specific application connection.
 * The card dynamically displays the connection form, status, and associated details based on the provided application name.
 *
 * @param {ConnectionsProps} props - The properties required for the component.
 * @param {any} props.image - The image to display in the card header (e.g., app logo).
 * @param {string} props.appName - The name of the application being configured.
 * @param {string} props.formDescription - The description text for the connection form.
 *
 * @returns {JSX.Element} A styled card component for application connection configuration.
 */
const ConnectionConfigCard = ({ image, appName, formDescription }: ConnectionsProps): JSX.Element => {
  const intl = useIntl();
  const { isLoading, isMarketoConnected, isSalesforceConnected, isHubspotConnected, isSlackConnected } =
    useConnections();

  let badgeColor: string;
  let connectionStatusText: string;
  let connectionForm: JSX.Element | null;

  switch (appName) {
    case "HUBSPOT":
      connectionForm = <ConnectionHubspotForm />;
      badgeColor = isHubspotConnected ? "badge-light-success" : "badge-light-danger";
      connectionStatusText = isHubspotConnected
        ? intl.formatMessage({ id: "SECRETS.CONNECTIONS.STATUS.CONNECTED" })
        : intl.formatMessage({ id: "SECRETS.CONNECTIONS.STATUS.NOTCONNECTED" });
      break;
    case "MARKETO":
      connectionForm = <ConnectionMarketoForm />;
      badgeColor = isMarketoConnected ? "badge-light-success" : "badge-light-danger";
      connectionStatusText = isMarketoConnected
        ? intl.formatMessage({ id: "SECRETS.CONNECTIONS.STATUS.CONNECTED" })
        : intl.formatMessage({ id: "SECRETS.CONNECTIONS.STATUS.NOTCONNECTED" });
      break;
    case "SALESFORCE":
      connectionForm = <ConnectionSalesforceForm />;
      badgeColor = isSalesforceConnected ? "badge-light-success" : "badge-light-danger";
      connectionStatusText = isSalesforceConnected
        ? intl.formatMessage({ id: "SECRETS.CONNECTIONS.STATUS.CONNECTED" })
        : intl.formatMessage({ id: "SECRETS.CONNECTIONS.STATUS.NOTCONNECTED" });
      break;
    case "SLACK":
      connectionForm = <ConnectionSlackForm />;
      badgeColor = isSlackConnected ? "badge-light-success" : "badge-light-danger";
      connectionStatusText = isSlackConnected
        ? intl.formatMessage({ id: "SECRETS.CONNECTIONS.STATUS.CONNECTED" })
        : intl.formatMessage({ id: "SECRETS.CONNECTIONS.STATUS.NOTCONNECTED" });
      break;
    default:
      connectionForm = null;
      badgeColor = "badge-light-danger";
      connectionStatusText = intl.formatMessage({ id: "SECRETS.CONNECTIONS.STATUS.UNKNOWN" });
  }

  return (
    <ConnectionWrapper app={appName}>
      <div key={appName} className="col-xxl-8 col-xl-10">
        <div className="card mt-6">
          <div className="card-header border-0 pt-9 px-6">
            <div>
              <img
                src={image}
                alt={intl.formatMessage({ id: `SECRETS.APPNAME.${appName}` })}
                style={{ width: "auto", height: 80 }}
              />
            </div>
            <div className="card-toolbar">
              <span className={`badge ${badgeColor} fw-bold fs-5 me-auto px-4 py-3`}>{connectionStatusText}</span>
            </div>
          </div>
          <div className="card-body p-9">
            <div className="fs-1 fw-bold text-gray-900">{intl.formatMessage({ id: `SECRETS.APPNAME.${appName}` })}</div>
            <p className="text-gray-500 fw-semibold fs-5 mt-3 mb-1">{intl.formatMessage({ id: formDescription })}</p>
            {!isLoading ? connectionForm : <InsSpinner />}
          </div>
        </div>
      </div>
    </ConnectionWrapper>
  );
};

export default ConnectionConfigCard;
