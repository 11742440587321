import { ReactNode } from "react";
import FieldWithSearchBlanket from "../components/FieldWithSearchBlanket";
import FieldWithSearchMenu from "../components/FieldWithSearchMenu";

const FieldWithSearchDropdown = ({
  children,
  isOpen,
  target,
  onClose
}: {
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}) => (
  <div style={{ position: "relative" }}>
    {target}
    {isOpen ? <FieldWithSearchMenu>{children}</FieldWithSearchMenu> : null}
    {isOpen ? <FieldWithSearchBlanket onClick={onClose} /> : null}
  </div>
);

export default FieldWithSearchDropdown;
