import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { SwitchField } from "../../../layout/components/form";
import { AccountSettingsType } from "../core/_models";
import { useAuth } from "../../auth";
import { hasEditPermission } from "../../../helpers/permissions";

interface GenSettingsFiscalYearTypeProps {
  values: Pick<AccountSettingsType, 'company_fiscal_year_type'>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const GenSettingsFiscalYearType: React.FC<GenSettingsFiscalYearTypeProps> = ({ values, setFieldValue }) => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const account_settings_edit_permission = hasEditPermission(currentUser, "account", "settings");
  
  return (
    <div className="row">
      <div className="col-lg-4 p-3 mb-3">
        <span className="fw-bold fs-4 text-muted" id="company_fiscal_year_type-label">
          {intl.formatMessage({
            id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.FISCALYEARTYPE"
          })}
        </span>
        <p className="fs-7 text-muted" id="company_fiscal_year_type-description">
          {intl.formatMessage({
            id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.FISCALYEARSTARTMONTH.DESCRIPTION"
          })}
        </p>
      </div>
      <div className="col-lg-8 p-3 mb-3 d-flex">
        <SwitchField
          id="company_fiscal_year_type"
          label=""
          description=""
          ariaLabelledBy="company_fiscal_year_type-label"
          ariaDescribedBy="company_fiscal_year_type-description"
          hideLabel={false}
          hideDescription={false}
          isInvalid={false}
          checked={values.company_fiscal_year_type}
          onChange={() => {
            return setFieldValue("company_fiscal_year_type", !values.company_fiscal_year_type);
          }}
          disabled={!account_settings_edit_permission}
        />
      </div>
    </div>
  );
};

GenSettingsFiscalYearType.propTypes = {
  values: PropTypes.shape({
    company_fiscal_year_type: PropTypes.bool.isRequired
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default GenSettingsFiscalYearType;
