import axios from "axios";
import { KTIcon } from "../../../../helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useIntl } from "react-intl";
import { Tooltip } from "react-tooltip";
import { useAuth } from "../../../auth";
import { hasEditPermission } from "../../../../helpers/permissions";

const MySwal = withReactContent(Swal);

const QueryBuilderDeleteQueryAction = (props: any) => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const sfdc_general_settings = hasEditPermission(currentUser, "salesforce", "general_settings");

  const handleDeleteQuery = async () => {
    // Check if user has permission to delete queries before submitting
    if (!sfdc_general_settings) {
      console.error(intl.formatMessage({ id: "PERMISSIONS.ERRORMESSAGE.NOEDITPERMISSION" }));
      return;
    }

    const payload: any = {
      object_queries: {}
    };
    payload.object_queries[props.object.toLowerCase()] = "";

    try {
      await axios.post("salesforce/settings/object_queries", payload);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnClick = () => {
    MySwal.fire({
      title: intl.formatMessage({ id: "QUERYBUILDER.DELETE.TITLE" }),
      text: intl.formatMessage({ id: "QUERYBUILDER.DELETE.DESC" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({
        id: "QUERYBUILDER.DELETE.CONFIRM"
      }),
      cancelButtonText: intl.formatMessage({ id: "BUTTON.CANCEL" })
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleDeleteQuery();
        MySwal.fire(
          intl.formatMessage({ id: "SETTINGS.OBJECTFIELDS.DELETED_TITLE" }),
          intl.formatMessage({ id: "SETTINGS.OBJECTFIELDS.DELETED_TEXT" }),
          "success"
        );
        props.tableReload(Math.random());
      }
    });
  };

  return (
    <div className="menu-item px-3 mx-auto">
      <button
        className="menu-link px-3 mx-auto"
        style={{ width: "auto" }}
        onClick={handleOnClick}
        data-tooltip-id={`delete-tooltip-${props.object}`}
        data-tooltip-content={intl.formatMessage({ id: "QUERYBUILDER.TABLE.DELETE" })}
        data-tooltip-place="bottom"
        aria-label={intl.formatMessage({ id: "QUERYBUILDER.TABLE.DELETE" })}
        disabled={!sfdc_general_settings}
      >
        <KTIcon iconName="trash" className="fs-2 m-0" />
      </button>
      <Tooltip id={`delete-tooltip-${props.object}`} style={{ zIndex: 100 }} />
    </div>
  );
};

export default QueryBuilderDeleteQueryAction;
