import { ReactNode } from "react";
import { PasswordField } from "../../../layout/components/form";
import { To } from "react-router-dom";

export type WithChildren = {
  children?: ReactNode;
};

export type connectionDataMarketo = {
  mkto_munchkin_id: string;
  mkto_client_id: string;
  mkto_client_secret: string;
};

export type connectionDataSalesforce = {
  sfdc_login_url: string;
  sfdc_username: string;
  sfdc_password: string;
  sfdc_token: string;
};

export type connectionDataHubspot = {
  hubspot_hub_id: string;
  hubspot_private_app_token: string;
  hubspot_public_app_id: string;
  hubspot_public_client_id: string;
  hubspot_public_client_secret: string;
};

export type connectionDataSlack = {
  slack_api_token: string;
};

export type connectionsStateProps = {
  isSfdcEnabled: boolean;
  connection_data_marketo: connectionDataMarketo;
  connection_data_salesforce: connectionDataSalesforce;
  connection_data_hubspot: connectionDataHubspot;
  connection_data_slack: connectionDataSlack;
  isMarketoConnected?: boolean;
  isSalesforceConnected?: boolean;
  isHubspotConnected?: boolean;
  isSlackConnected?: boolean;
};

export type connectionsContextProps = {
  isLoading: boolean;
  refetch: () => void;
  isSfdcEnabled: boolean;
  connection_data_marketo: connectionDataMarketo;
  connection_data_salesforce: connectionDataSalesforce;
  connection_data_hubspot: connectionDataHubspot;
  connection_data_slack: connectionDataSlack;
  isMarketoConnected?: boolean;
  isSalesforceConnected?: boolean;
  isHubspotConnected?: boolean;
  isSlackConnected?: boolean;
};

export const initialConnectionsState: connectionsStateProps = {
  isSfdcEnabled: false,
  connection_data_marketo: {
    mkto_munchkin_id: "",
    mkto_client_id: "",
    mkto_client_secret: ""
  },
  connection_data_salesforce: {
    sfdc_login_url: "",
    sfdc_username: "",
    sfdc_password: "",
    sfdc_token: ""
  },
  connection_data_hubspot: {
    hubspot_hub_id: "",
    hubspot_private_app_token: "",
    hubspot_public_app_id: "",
    hubspot_public_client_id: "",
    hubspot_public_client_secret: ""
  },
  connection_data_slack: {
    slack_api_token: ""
  },
  isMarketoConnected: false,
  isSalesforceConnected: false,
  isHubspotConnected: false,
  isSlackConnected: false
};

export const initialConnectionsContext: connectionsContextProps = {
  isLoading: false,
  refetch: () => {},
  isSfdcEnabled: false,
  connection_data_marketo: {
    mkto_munchkin_id: "",
    mkto_client_id: "",
    mkto_client_secret: ""
  },
  connection_data_salesforce: {
    sfdc_login_url: "",
    sfdc_username: "",
    sfdc_password: "",
    sfdc_token: ""
  },
  connection_data_hubspot: {
    hubspot_hub_id: "",
    hubspot_private_app_token: "",
    hubspot_public_app_id: "",
    hubspot_public_client_id: "",
    hubspot_public_client_secret: ""
  },
  connection_data_slack: {
    slack_api_token: ""
  },
  isMarketoConnected: false,
  isSalesforceConnected: false,
  isHubspotConnected: false,
  isSlackConnected: false
};

export interface FieldConfig {
  id: string;
  labelId: string;
  disabled: boolean;
  showIcon: boolean;
  refreshType?: string;
  showRefreshButton?: boolean;
}

export type secretKeysRecord = [string, string];

export interface SecretConfig {
  testEndpoint: string;
  saveEndpoint: string;
  alertDescId: string;
  secretKeys: Array<secretKeysRecord>;
  optionalKeys: Array<secretKeysRecord>;
}

export interface SubmitSecretKeys {
  testEndpoint: string;
  saveEndpoint: string;
  alertDescId: string;
  secretKeys: Record<string, string>;
  optionalKeys: Record<string, string>;
}

export type Field = {
  label: string;
  id: string;
  initialValue: any;
  optional: boolean;
  fieldComponent: typeof PasswordField;
  disabled?: boolean;
  hideShowPasswordIcon?: boolean;
  refreshType?: string;
  showRefreshButton?: boolean;
};

export interface ConnectionFormProps {
  fieldConfigs: FieldConfig[];
  validationSchema: any;
  secretConfig: SecretConfig;
  initialValues: any;
  isLoading: boolean;
  isEnabled?: boolean;
  enableLink?: any;
}
