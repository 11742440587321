import { useIntl } from "react-intl";
import { useRolesView } from "../../core/RolesAndPermissionsProvider";
import { updateRole } from "../../core/_requests";

const PermissionsFooter = () => {
  const intl = useIntl();
  const { roleIdForUpdate, roles, updateRoleObject, refetch, permissionHasChanged, isSubmitting, setIsSubmitting } =
    useRolesView();

  const role = roles.find((role) => role.id === roleIdForUpdate.id);

  const handleSavePermissions = async () => {
    setIsSubmitting(true);
    await updateRole(roleIdForUpdate.id, updateRoleObject);
    setIsSubmitting(false);
    if (refetch) {
      refetch();
    }
  };

  return (
    <div className="card-footer pt-6 ">
      {roleIdForUpdate.id !== undefined && (
        <div className="card-toolbar">
          <button
            id="update_role"
            onClick={handleSavePermissions}
            type="submit"
            className="btn btn-primary m-2"
            disabled={isSubmitting || !permissionHasChanged}
          >
            {!isSubmitting && (
              <span className="indicator-label">{intl.formatMessage({ id: "ROLES.BUTTON.SAVE" })}</span>
            )}
            {isSubmitting && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "ROLES.BUTTON.SAVING" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default PermissionsFooter;
