import { Form, Formik } from "formik";
import { profileValidationSchema } from "../schema/profileValidationSchema";
import { useAuth } from "../../../auth";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { toastSettings } from "../../../../layout/components/toast/Toast";
import ProfileSettingsFields from "./ProfileSettingsFields";
import { useIntl } from "react-intl";

const ProfileSettingsForm = () => {
  const { currentUser } = useAuth();
  const intl = useIntl();

  const [userInfo, setUserInfo] = useState<any>(null);
  const [selectedTimezone, setSelectedTimezone] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser) {
      axios
        .get(`/users/${currentUser.id}`)
        .then((response: any) => {
          setUserInfo(response.data);
          setSelectedTimezone(currentUser.timezone || "");
        })
        .catch((error: any) => {
          console.error("Error fetching user data :", error);
        });
    }
  }, [currentUser]);

  const onSubmit = (values: any) => {
    setIsSaving(true);
    if (currentUser) {
      const payload = {
        user_properties: {
          first_name: values.first_name,
          last_name: values.last_name
        }
      };

      axios
        .put(`/users/${currentUser.id}`, payload)
        .then((response: any) => {
          toast.success("The User has been successfully updated", toastSettings("success"));
        })
        .catch((error: any) => {
          console.error("Error updating user:", error.response?.data);
          toast.error("The User has not been updated", toastSettings("error"));
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          first_name: currentUser?.first_name,
          last_name: currentUser?.last_name,
          timezone: currentUser?.timezone || ""
        }}
        validationSchema={profileValidationSchema}
        onSubmit={onSubmit}
      >
        {({ touched, errors }) => {
          return (
            <Form id="profile-page">
              <ProfileSettingsFields
                touched={touched}
                errors={errors}
                selectedTimezone={selectedTimezone}
                setTimezone={setSelectedTimezone}
                isSaving={isSaving}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ProfileSettingsForm;
