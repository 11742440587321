import { Dispatch, SetStateAction } from "react";

export type ID = undefined | null | string;

export type pmcfOrLeadFields = "program_member" | "lead" | undefined;

export type TableColumns = {
  id: string;
  domain: string;
  embed_code: string;
  actions: string;
};

export type fieldListItems = {
  host: string;
  medium: string;
  display: string;
};

export type validDomainItems = {
  label: string;
  value: string;
};

export type reservedStringsItems = {
  label: string;
  value: string;
};

export type InsentricJSFile = {
  maindomain: string;
  cookie_months: number;
  initialConversion: boolean;
  last_source_object: string;
  source: string;
  medium: string;
  campaign: string;
  term: string;
  content: string;
  pmfcSource: string;
  pmfcMedium: string;
  pmfcCampaign: string;
  pmfcTerm: string;
  pmfcContent: string;
  tempSource: string;
  tempMedium: string;
  tempCampaign: string;
  tempTerm: string;
  tempContent: string;
  validdomains: Array<validDomainItems>;
  reservedstrings: Array<reservedStringsItems>;
  referrals: Array<fieldListItems>;
};

export type FieldItem = {
  displayName: string;
  isCustom: boolean;
  insentricStatus: "available" | "unavailable" | "default";
  length: string;
  name: string;
  picklistValues: string[];
  type: string;
};

export const initialValues = {
  maindomain: "",
  cookie_months: 6,
  last_source_object: "program_member",
  initialConversion: false,
  source: "",
  medium: "",
  campaign: "",
  term: "",
  content: "",
  pmfcSource: "",
  pmfcMedium: "",
  pmfcCampaign: "",
  pmfcTerm: "",
  pmfcContent: "",
  tempSource: "",
  tempMedium: "",
  tempCampaign: "",
  tempTerm: "",
  tempContent: "",
  validdomains: [],
  reservedstrings: [],
  referrals: [{ host: "", medium: "", display: "" }]
};

export type InsJSFilesContextProps = {
  trackerFiles: Array<any>;
  refetch: () => void;
  isFetching: boolean;
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID;
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>;
  isInitialAndConversion?: boolean | undefined;
  setIsInitialAndConversion: Dispatch<SetStateAction<boolean | undefined>>;
  pmcfOrLeadFields?: pmcfOrLeadFields;
  setPmcfOrLeadFields: Dispatch<SetStateAction<pmcfOrLeadFields>>;
  handleCopyEmbedCode: (fileEmbedCode: any, fileID: string) => void;
  showFileLinkCopied: string;
  setShowFileLinkCopied: Dispatch<SetStateAction<string>>;
  actionsObject: any;
  setActionsObject: Dispatch<SetStateAction<any>>;
};

export const initialInsJSFilesRequest = {
  trackerFiles: [],
  refetch: () => {},
  isFetching: true,
  itemIdForUpdate: undefined,
  setItemIdForUpdate: () => {},
  setIsInitialAndConversion: () => {},
  setPmcfOrLeadFields: () => {},
  handleCopyEmbedCode: () => {},
  showFileLinkCopied: "",
  setShowFileLinkCopied: () => {},
  actionsObject: {
    sort: {
      column: "domain",
      order: "asc"
    }
  },
  setActionsObject: () => {}
};
