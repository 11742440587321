import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../helpers";
import { useIntl } from "react-intl";

type ActionsCopyProps = {
  handleOnClick: any;
  index: string;
  domain: string;
  showCopied: boolean;
};

const ActionsCopy = ({ handleOnClick, index, domain, showCopied }: ActionsCopyProps) => {
  const intl = useIntl();

  return (
    <div className="menu-item px-3">
      {!showCopied ? (
        <>
          <button
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            onClick={handleOnClick}
            data-tooltip-id={`tooltip-btn-${index}`}
            aria-label={`Copy embed code for ${domain}`}
          >
            <KTIcon iconName="copy" iconType="outline" className="fs-2 m-0 actionIcon" aria-hidden="true" />
          </button>
          <Tooltip
            id={`tooltip-btn-${index}`}
            key={`tooltip-btn-${index}`}
            content={intl.formatMessage({ id: "INSENTRICJS.EMBED" })}
            place="top"
            role="tooltip"
            variant="dark"
            style={{ zIndex: 100 }}
          />
        </>
      ) : (
        <div
          className="bg-light-success rounded d-flex justify-content-center align-items-center"
          style={{ width: "35px", height: "35px" }}
        >
          <KTIcon iconName="check" iconType="duotone" className="w-auto h-auto fs-1  text-success p-1" />
        </div>
      )}
    </div>
  );
};

export default ActionsCopy;
