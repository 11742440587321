import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { initialRefreshDataContext, RefreshDataContextType } from "./_models";
import {
  mktoBasicInfo,
  mktoRefreshOptions,
  sfdcBasicInfo,
  sfdcRefreshOptions,
  mktoAssetsInfo,
  mktoAssetsOptions
} from "./refreshOptions";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getDataStatus } from "./_requests";

const refreshDataContext = createContext<RefreshDataContextType>(initialRefreshDataContext);

const RefreshDataProvider = ({ children }: { children: React.ReactNode }) => {
  const [mktoOptions, setMktoOptions] = useState(mktoRefreshOptions);
  const [sfdcOptions, setSfdcOptions] = useState(sfdcRefreshOptions);
  const [assetsMktoOptions, setAssetsMktoOptions] = useState(mktoAssetsOptions);

  const {
    isFetching,
    refetch,
    data: response
  } = useQuery({
    queryKey: ["Fetch objects and assets status"],
    queryFn: getDataStatus,
    refetchOnMount: false,
    gcTime: 3600 * 1000,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  // Memoize fetched statuses to avoid unnecessary re-renders
  const fetchedDataStatus = useMemo(() => response || [], [response]);

  useEffect(() => {
    if (Object.keys(fetchedDataStatus).length > 0) {
      setMktoOptions((prev) =>
        prev.map((option) => {
          return {
            ...option,
            lastSuccessFullRefresh: option.statusKey
              ? fetchedDataStatus.mkto[option.statusKey].last_successful_fetch
              : undefined
          };
        })
      );

      setSfdcOptions((prev) =>
        prev.map((option) => {
          return {
            ...option,
            lastSuccessFullRefresh: option.statusKey
              ? fetchedDataStatus.sfdc[option.statusKey].last_successful_fetch
              : undefined
          };
        })
      );

      setAssetsMktoOptions((prev) =>
        prev.map((option) => {
          return {
            ...option,
            lastSuccessFullRefresh: option.statusKey
              ? fetchedDataStatus.mkto[option.statusKey].last_successful_fetch
              : undefined
          };
        })
      );
    }
  }, [fetchedDataStatus]);

  return (
    <refreshDataContext.Provider
      value={{
        mktoRefreshOptions: mktoOptions,
        sfdcRefreshOptions: sfdcOptions,
        mktoAssetsOptions: assetsMktoOptions,
        sfdcBasicInfo: sfdcBasicInfo,
        mktoBasicInfo: mktoBasicInfo,
        mktoAssetsInfo: mktoAssetsInfo,
        refetch,
        isLoading: isFetching
      }}
    >
      {children}
    </refreshDataContext.Provider>
  );
};

const useRefreshData = () => useContext(refreshDataContext);

export { useRefreshData, RefreshDataProvider };
