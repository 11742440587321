import { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import * as Yup from "yup";
import { useIntl } from "react-intl";

import Form from "../../../layout/components/form/Form";
import PageHeader from "../../../layout/components/content/PageHeader";
import {
  TextField,
  DateField,
  SliderField,
  SingleSelectDropdownWithSearch,
  MultiSelectDropdownWithSearch
} from "../../../layout/components/form";

import InsSpinner from "../../../layout/components/spinner/InsSpinner";
import { toast } from "react-toastify";
import { toastSettings } from "../../../layout/components/toast/Toast";
import { useAuth } from "../../auth";
import { hasEditPermission } from "../../../helpers/permissions";

const validationSchema =
  Yup.object().shape({
    mkto_smart_list_id: Yup.number().required("MARKETO.SETTINGS.MKTO.SMARTLIST.REQUIRED"),
    mkto_all_records_smart_list_id: Yup.number().required("MARKETO.SETTINGS.MKTO.SMARTLIST.REQUIRED"),
    mkto_program_ids_ignore_members: Yup.array()
      .of(Yup.string())
      .required("MARKETO.SETTINGS.MKTO.SMARTLIST.REQUIRED")
      .min(1),
    mkto_opportunity_amount_field: Yup.string().required("MARKETO.SETTINGS.MKTO.SMARTLIST.REQUIRED"),
    mkto_start_date: Yup.date().nullable(),
    mkto_max_daily_calls: Yup.number().required("MARKETO.SETTINGS.MKTO.SMARTLIST.REQUIRED"),
    max_daily_bulk_export_size: Yup.number().required("MARKETO.SETTINGS.MKTO.SMARTLIST.REQUIRED"),
    mkto_max_lead_records: Yup.number().required("MARKETO.SETTINGS.MKTO.SMARTLIST.REQUIRED"),
    mkto_max_leads_notification_threshold: Yup.number().required("MARKETO.SETTINGS.MKTO.SMARTLIST.REQUIRED")
  });

export type mktoSettingsValuesType = {
  initialValues: {
    mkto_smart_list_id?: number;
    mkto_all_records_smart_list_id?: number;
    mkto_program_ids_ignore_members?: { label: string; value: string }[];
    mkto_opportunity_amount_field?: string;
    mkto_start_date?: Date | null;
    mkto_max_daily_calls?: number;
    max_daily_bulk_export_size?: number;
    mkto_max_lead_records?: number;
    mkto_max_leads_notification_threshold?: number;
  };
  smartLists: object[];
  programs: object[];
  oppFields: object[];
  mktoDataFetchStatus: boolean | undefined;
};

const MktoSettings = () => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const marketo_general_settings = hasEditPermission(currentUser, "marketo", "general_settings");

  const [mktoSettingsValues, setMktoSettingsValues] = useState<mktoSettingsValuesType>();

  const fields = [
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.SMARTLIST" }),
      description: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.SMARTLIST.DESC" }),
      id: "mkto_smart_list_id",
      options: mktoSettingsValues?.smartLists,
      selectedOption: mktoSettingsValues?.initialValues?.mkto_smart_list_id,
      fieldComponent: (props: any) => (
        <SingleSelectDropdownWithSearch {...props} placeholder={intl.formatMessage({ id: "FORM.SELECT" })} />
      ),
      showRefreshButton: true,
      refreshType: "smart_lists"
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.ALLRECORDSSMARTLIST" }),
      description: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.ALLRECORDSSMARTLIST.DESC" }),
      id: "mkto_all_records_smart_list_id",
      options: mktoSettingsValues?.smartLists,
      selectedOption: mktoSettingsValues?.initialValues?.mkto_all_records_smart_list_id,
      fieldComponent: (props: any) => (
        <SingleSelectDropdownWithSearch {...props} placeholder={intl.formatMessage({ id: "FORM.SELECT" })} />
      ),
      showRefreshButton: true,
      refreshType: "smart_lists"
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.EXCLUDEPROGMEM" }),
      description: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.EXCLUDEPROGMEM.DESC" }),
      id: "mkto_program_ids_ignore_members",
      options: mktoSettingsValues?.programs,
      fieldComponent: (props: any) => (
        <MultiSelectDropdownWithSearch {...props} placeholder={intl.formatMessage({ id: "FORM.SELECT" })} />
      ),
      showRefreshButton: true,
      refreshType: "mkto_programs"
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.OPPAMOUNT" }),
      description: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.OPPAMOUNT.DESC" }),
      id: "mkto_opportunity_amount_field",
      options: mktoSettingsValues?.oppFields,
      fieldComponent: (props: any) => (
        <SingleSelectDropdownWithSearch {...props} placeholder={intl.formatMessage({ id: "FORM.SELECT" })} />
      )
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.EXTRACTSTART" }),
      description: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.EXTRACTSTART.DESC" }),
      id: "mkto_start_date",
      validationSchema: Yup.date(),
      fieldComponent: DateField,
      disabled: !marketo_general_settings
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.MAXDAILYCALLS" }),
      description: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.MAXDAILYCALLS.DESC" }),
      id: "mkto_max_daily_calls",
      validationSchema: Yup.number(),
      fieldComponent: TextField,
      disabled: !marketo_general_settings
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.MAXDAILYBULKCALLS" }),
      description: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.MAXDAILYBULKCALLS.DESC" }),
      id: "max_daily_bulk_export_size",
      validationSchema: Yup.number(),
      fieldComponent: TextField,
      disabled: !marketo_general_settings
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.MAXLEADRECORDS" }),
      description: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.MAXLEADRECORDS.DESC" }),
      id: "mkto_max_lead_records",
      validationSchema: Yup.number(),
      fieldComponent: TextField,
      disabled: !marketo_general_settings
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.MAXLEADNOTIFICATION" }),
      description: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.MAXLEADNOTIFICATION.DESC" }),
      id: "mkto_max_leads_notification_threshold",
      fieldComponent: SliderField,
      disabled: !marketo_general_settings
    }
  ];

  const handleSubmit = async (values: any, { setSubmitting }: { setSubmitting: any }) => {
    // Check if user has permission to edit marketo general settings before submitting
    if (!marketo_general_settings) {
      console.error(intl.formatMessage({ id: "PERMISSIONS.ERRORMESSAGE.NOEDITPERMISSION" }));
      return;
    }

    setSubmitting(true);

    try {
      await axios.post("/configs/insentric-mkto", {
        opportunity_amount_field: values.mkto_opportunity_amount_field,
        smart_list_id: values.mkto_smart_list_id,
        all_records_smart_list_id: values.mkto_all_records_smart_list_id,
        start_date: values.mkto_start_date,
        max_daily_calls: values.mkto_max_daily_calls,
        max_daily_bulk_export_size: values.max_daily_bulk_export_size,
        max_lead_records: values.mkto_max_lead_records,
        program_ids_ignore_members: values.mkto_program_ids_ignore_members,
        max_leads_notification_threshold: values.mkto_max_leads_notification_threshold
      });

      toast.success(intl.formatMessage({ id: "SETTINGS.TOAST.SUCCESS" }), toastSettings("success"));
    } catch (err: any) {
      toast.error(
        `${intl.formatMessage({
          id: "SETTINGS.TOAST.ERROR"
        })}: ${err.message}`,
        toastSettings("error")
      );
    }
  };

  useEffect(() => {
    setMktoSettingsValues({
      initialValues: {},
      smartLists: [],
      programs: [],
      oppFields: [],
      mktoDataFetchStatus: undefined
    });

    //switchYupLocale(intl.locale);

    Promise.all([
      axios.get("/status/data"),
      axios.get("/marketo/settings/marketo_smart_lists"),
      axios.get("/marketo/settings/marketo_programs"),
      axios.get("/marketo/settings/marketo_fields/opportunity", {
        params: {
          data_types: ["currency"]
        }
      }),
      axios.get("/configs/insentric-mkto")
    ])
      .then((response: any) => {
        const [status, smartListsResponse, programsResponse, oppFieldsResponse, initialValues] = response;

        const smartLists = smartListsResponse.data.map((smartList: any) => {
          const parentProgramName =
            smartList._dl_parent_program && smartList._dl_parent_program.name ? smartList._dl_parent_program.name : "";

          return {
            label: parentProgramName.length > 0 ? `${parentProgramName}.${smartList.name}` : smartList.name,
            value: +smartList.id
          };
        });

        const programs = programsResponse.data.map((program: any) => ({
          ...program,
          label: program.name,
          value: program.id
        }));

        const oppFields = oppFieldsResponse.data.map((oppField: any) => ({
          ...oppField,
          label: oppField.displayName,
          value: oppField.name
        }));

        setMktoSettingsValues((prevStatus) => ({
          ...prevStatus,
          initialValues: {
            mkto_opportunity_amount_field: initialValues.data.opportunity_amount_field,
            mkto_smart_list_id: initialValues.data.smart_list_id || undefined,
            mkto_all_records_smart_list_id: initialValues.data.all_records_smart_list_id || undefined,
            mkto_start_date: initialValues.data.start_date,
            mkto_max_daily_calls: initialValues.data.max_daily_calls,
            max_daily_bulk_export_size: initialValues.data.max_daily_bulk_export_size,
            mkto_max_lead_records: initialValues.data.max_lead_records,
            mkto_program_ids_ignore_members: initialValues.data.program_ids_ignore_members || [],
            mkto_max_leads_notification_threshold: initialValues.data.max_leads_notification_threshold || 90
          },
          smartLists: smartLists || [],
          programs: programs || [],
          oppFields: oppFields || [],
          mktoDataFetchStatus: status?.data?.mkto?.lead_fields.last_successful_fetch !== ""
        }));
      })
      .catch((error) => {
        console.error("Error fetching initial values:", error);
      });
  }, [intl.locale]);

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level1name="SIDEBAR.SUBHEAD.MARKETO"
          level1link="/app/home"
          level2name="SIDEBAR.ITEM.MKTOGENERALSETTINGS"
          instructions=""
        />
      </div>
      <div className="card px-3 mb-xl-3">
        {mktoSettingsValues?.mktoDataFetchStatus === undefined ? (
          <div>
            <InsSpinner />
          </div>
        ) : mktoSettingsValues.mktoDataFetchStatus ? (
          mktoSettingsValues.initialValues ? (
            <div className="mt-10 mb-10">
              <Container fluid className="form-container">
                <div>
                  <Form
                    fields={fields}
                    initialValues={mktoSettingsValues.initialValues}
                    validationSchema={validationSchema}
                    handleSubmit={handleSubmit}
                    buttonIsDisabled={!marketo_general_settings}
                  />
                </div>
              </Container>
            </div>
          ) : (
            <div>
              <InsSpinner />
            </div>
          )
        ) : (
          <div className="mt-10 mb-10">
            <Container fluid>
              <div className="d-flex justify-content-center">
                <h3>
                  {intl.formatMessage({
                    id: "SETTINGS.MKTO.GENERAL.DATAFETCHING.WAITMESSAGE"
                  })}
                </h3>
              </div>
            </Container>
          </div>
        )}
      </div>
    </>
  );
};

export default MktoSettings;
