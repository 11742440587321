import { Form, Formik, FormikValues } from "formik";
import { validateStep1, validateStep2, validateStep3, validateStep4, validateStep5 } from "../schema/validationSchema";
import { FC, useEffect, useRef, useState } from "react";
import axios from "axios";
import { initialValues, InsentricJSFile, FieldItem, pmcfOrLeadFields } from "../core/_models";
import { Step1 } from "../components/steps/Step1";
import { Step2 } from "../components/steps/Step2";
import { Step3 } from "../components/steps/Step3";
import { Step4 } from "../components/steps/Step4";
import { Step5 } from "../components/steps/Step5";
import { StepperComponent } from "../../../assets/ts/components/_StepperComponent";
import { KTIcon } from "../../../helpers";
import StepController from "../components/steps/StepController";
import { createInsentricJS, updateInsentricJS } from "../core/_requests";
import { useInsJSFilesRequest } from "../core/InsJSFilesProvider";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { toastSettings } from "../../../layout/components/toast/Toast";
import { useAuth } from "../../auth";
import { hasEditPermission } from "../../../helpers/permissions";

type Props = {
  fileToUpdate: any;
  isFileLoading: boolean;
};

type FieldItems = FieldItem[];

const InsentricJsForm: FC<Props> = ({ fileToUpdate, isFileLoading }) => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const source_tracker = hasEditPermission(currentUser, "source_tracker", "scripts");

  const {
    itemIdForUpdate,
    setItemIdForUpdate,
    refetch,
    isInitialAndConversion,
    setIsInitialAndConversion,
    pmcfOrLeadFields,
    setPmcfOrLeadFields
  } = useInsJSFilesRequest();

  const validationSchema: Array<object> = [];

  if (isInitialAndConversion && pmcfOrLeadFields === "lead") {
    validationSchema.push(validateStep1, validateStep2, validateStep4, validateStep5);
  } else if (isInitialAndConversion && pmcfOrLeadFields === "program_member") {
    validationSchema.push(validateStep1, validateStep2, validateStep3, validateStep5);
  } else if (!isInitialAndConversion && pmcfOrLeadFields === "program_member") {
    validationSchema.push(validateStep1, validateStep3, validateStep5);
  } else {
    validationSchema.push(validateStep1, validateStep4, validateStep5);
  }

  const [dropdownLeadFieldOptions, setDropdownLeadFieldOptions] = useState<object[]>([]);
  const [dropdownProgramMemberFieldOptions, setDropdownProgramMemberFieldOptions] = useState<object[]>([]);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(validationSchema[0]);
  const [isSubmitButton, setSubmitButton] = useState(false);

  useEffect(() => {
    if (isInitialAndConversion && isInitialAndConversion !== undefined && stepper.current !== null) {
      stepper.current.totalStepsNumber = 4;
    } else if (stepper.current) {
      stepper.current.totalStepsNumber = 3;
    }
  }, [isInitialAndConversion]);

  const fetchData = async () => {
    try {
      const response = await axios.get("/schema/marketo");
      const leadFields: FieldItems = response.data.lead;
      const pmfcFields: FieldItems = response.data.program_member;

      const leadObj: Array<object> = [];
      const programMemberObj: Array<object> = [];

      if (leadFields) {
        leadFields.map((field) => {
          if (field.type === "string") {
            leadObj.push({ label: field.displayName, value: field.name });
          }
        });
        setDropdownLeadFieldOptions(leadObj);
      }

      if (pmfcFields) {
        pmfcFields.map((field) => {
          if (field.type === "string") {
            programMemberObj.push({
              label: field.displayName,
              value: field.name
            });
          }
        });
        setDropdownProgramMemberFieldOptions(programMemberObj);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const loadStepper = () => {
    if (stepperRef.current) {
      stepper.current = StepperComponent.createInstance(stepperRef.current as HTMLDivElement);
    } else {
      console.error("Stepper ref is not set");
    }
  };

  const prevStep = () => {
    if (!stepper.current) {
      console.error("Stepper instance is not available");
      return;
    }

    stepper.current.goPrev();

    setCurrentSchema(validationSchema[stepper.current.currentStepIndex - 1]);
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber);
  };

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const submitStep = async (values: InsentricJSFile, actions: FormikValues) => {
    actions.setTouched({});
    if (!stepper.current) {
      console.error("Stepper instance is not available");
      return;
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext();
    } else {
      // Check if user has permission to edit marketo activity types before submitting
      if (!source_tracker) {
        console.error(intl.formatMessage({ id: "PERMISSIONS.ERRORMESSAGE.NOEDITPERMISSION" }));
        return;
      }

      stepper.current.goto(1);

      let refferalsArr: any = [];
      let reservedStrings: any = [];

      if (values.referrals && values.referrals.length > 0) {
        refferalsArr = values.referrals.map((referral) => {
          return {
            host: referral.host,
            medium: referral.medium,
            display: referral.display
          };
        });
      }

      const domainLocks = values.validdomains.map((domain) => {
        return domain.value;
      });

      if (values.reservedstrings && values.reservedstrings.length > 0) {
        reservedStrings = values.reservedstrings.map((string) => {
          return string.value;
        });
      }

      const updateFilePayload = {
        script_config: {
          cookie_months: values.cookie_months,
          initial_and_conversion: values.initialConversion,
          last_source_object: values.last_source_object,
          timezone: -5,
          referrals: refferalsArr,
          organics: [],
          marketo_form_fields: {
            initial: !values.initialConversion
              ? undefined
              : ({
                  source: values.source,
                  medium: values.medium,
                  campaign: values.campaign,
                  content: values.content,
                  term: values.term
                } as any),
            last: {
              lead:
                !values.last_source_object || values.last_source_object === "program_member"
                  ? undefined
                  : {
                      source: values.tempSource,
                      medium: values.tempMedium,
                      campaign: values.tempCampaign,
                      content: values.tempContent,
                      term: values.tempTerm
                    },
              program_member:
                !values.last_source_object || values.last_source_object === "lead"
                  ? undefined
                  : {
                      source: values.pmfcSource,
                      medium: values.pmfcMedium,
                      campaign: values.pmfcCampaign,
                      content: values.pmfcContent,
                      term: values.pmfcTerm
                    }
            }
          }
        },
        obfuscator_config: {
          domain_lock: domainLocks,
          reserved_strings: reservedStrings
        }
      };

      try {
        if (itemIdForUpdate && itemIdForUpdate !== "") {
          updateInsentricJS(itemIdForUpdate, updateFilePayload).then((response: any) => {
            if (response === "success") {
              cancel(true);
            }
          });
        } else {
          const newFilePayload = {
            ...updateFilePayload,
            domain: values.maindomain
          };
          createInsentricJS(newFilePayload).then((response: any) => {
            if (response === "success") {
              cancel(true);
            }
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        actions.resetForm();
      }
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber);
    setCurrentSchema(validationSchema[stepper.current.currentStepIndex - 1]);
  };

  useEffect(() => {
    if (!stepperRef.current) {
      console.error("Stepper ref is not available in useEffect");
      return;
    }
    loadStepper();
  }, [stepperRef]);

  let itemForUpdate = {
    ...initialValues,
    validdomains: fileToUpdate?.valid_domains || initialValues.validdomains || [],
    reservedstrings: fileToUpdate?.reservedstrings || initialValues.reservedstrings || [],
    referrals: fileToUpdate?.config?.referrals || initialValues.referrals || []
  };

  if (fileToUpdate && Object.keys(fileToUpdate).length > 0) {
    itemForUpdate = {
      ...itemForUpdate,
      maindomain: fileToUpdate.domain || "",
      cookie_months: fileToUpdate.config?.cookie_months || 6,
      initialConversion: fileToUpdate.config?.initial_and_conversion || false,
      last_source_object: fileToUpdate.config?.last_source_object || "program_member",
      source: fileToUpdate.config?.marketo_form_fields?.initial?.source || "",
      medium: fileToUpdate.config?.marketo_form_fields?.initial?.medium || "",
      campaign: fileToUpdate.config?.marketo_form_fields?.initial?.campaign || "",
      term: fileToUpdate.config?.marketo_form_fields?.initial?.term || "",
      content: fileToUpdate.config?.marketo_form_fields?.initial?.content || "",
      pmfcSource: fileToUpdate.config?.marketo_form_fields?.last?.program_member?.source || "",
      pmfcMedium: fileToUpdate.config?.marketo_form_fields?.last?.program_member?.medium || "",
      pmfcCampaign: fileToUpdate.config?.marketo_form_fields?.last?.program_member?.campaign || "",
      pmfcTerm: fileToUpdate.config?.marketo_form_fields?.last?.program_member?.term || "",
      pmfcContent: fileToUpdate.config?.marketo_form_fields?.last?.program_member?.content || "",
      tempSource: fileToUpdate.config?.marketo_form_fields?.last?.lead?.source || "",
      tempMedium: fileToUpdate.config?.marketo_form_fields?.last?.lead?.medium || "",
      tempCampaign: fileToUpdate.config?.marketo_form_fields?.last?.lead?.campaign || "",
      tempTerm: fileToUpdate.config?.marketo_form_fields?.last?.lead?.term || "",
      tempContent: fileToUpdate.config?.marketo_form_fields?.last?.lead?.content || ""
    };
  }

  useEffect(() => {
    setIsInitialAndConversion(itemForUpdate.initialConversion);
    setPmcfOrLeadFields(itemForUpdate.last_source_object as pmcfOrLeadFields);
  }, [itemForUpdate.initialConversion, itemForUpdate.last_source_object]);

  return (
    <div
      ref={stepperRef}
      className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
      id="insentric_js_form_stepper"
    >
      <StepController />

      <div className="d-flex flex-row-fluid flex-center">
        <Formik initialValues={itemForUpdate} validationSchema={currentSchema} onSubmit={submitStep}>
          {({ values, touched, errors, setFieldValue }) => {
            return (
              <Form className="mx-auto mw-800px w-100 pb-10" id="insentric_js_form">
                <div className="current" data-kt-stepper-element="content">
                  <Step1 errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} />
                </div>

                {isInitialAndConversion && (
                  <div data-kt-stepper-element="content">
                    <Step2 options={dropdownLeadFieldOptions} errors={errors} touched={touched} />
                  </div>
                )}

                {pmcfOrLeadFields === "program_member" && (
                  <div data-kt-stepper-element="content">
                    <Step3 options={dropdownProgramMemberFieldOptions} errors={errors} touched={touched} />
                  </div>
                )}

                {pmcfOrLeadFields === "lead" && (
                  <div data-kt-stepper-element="content">
                    <Step4 options={dropdownLeadFieldOptions} errors={errors} touched={touched} />
                  </div>
                )}

                <div data-kt-stepper-element="content">
                  <Step5 errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} />
                </div>
                <hr />

                <div className="d-flex flex-stack pt-10">
                  <div className="mr-2">
                    <button
                      onClick={prevStep}
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                    >
                      <KTIcon iconName="arrow-left" className="fs-4 me-1" />
                      {intl.formatMessage({ id: "BUTTON.BACK" })}
                    </button>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary me-3"
                      data-insentricjs-modal-action="submit"
                      disabled={isSubmitButton || !source_tracker}
                    >
                      <span className="indicator-label">
                        {!isSubmitButton
                          ? intl.formatMessage({ id: "BUTTON.CONTINUE", defaultMessage: "Continue" })
                          : intl.formatMessage({ id: "BUTTON.SUBMIT", defaultMessage: "Submit" })}
                      </span>
                      <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default InsentricJsForm;
