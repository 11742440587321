import { KTIcon } from "../../../../helpers";
import "../style/MultiSelectDropdownWithSearch.css";

type RemoveOptionButtonProps = {
  label: string;
  onClick: (e: any) => void;
};

const RemoveOptionButton = ({ label, onClick }: RemoveOptionButtonProps) => {
  return (
    <div
      role="button"
      className="MSD__remove-btn"
      tabIndex={0}
      aria-label={`Remove ${label}`}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <KTIcon iconName="cross" iconType="outline" />
    </div>
  );
};

type FieldWithSearchRemoveOptionsProps = {
  optionLabel: string;
  onClick: (e: any) => void;
};

const FieldWithSearchRemoveOptions = ({ optionLabel, onClick }: FieldWithSearchRemoveOptionsProps) => {
  return (
    <div className="d-flex flex-row">
      <span className="MSD_option-label text-nowrap">{optionLabel}</span>
      <RemoveOptionButton label={optionLabel} onClick={onClick} />
    </div>
  );
};

export default FieldWithSearchRemoveOptions;
