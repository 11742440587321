import { memo } from "react";
import { Row } from "react-table";
import { TableColumns } from "../../../core/_models";

type Props = {
  row: Row<TableColumns>;
};

const CustomRow = ({ row }: Props) => {
  // Extract key from row props
  const { key, ...rowProps } = row.getRowProps();

  return (
    <tr key={key} {...rowProps}>
      {row.cells.map((cell, index) => {
        // Create a unique key for each cell
        const cellKey = `${row.id}-${cell.column.id}-${index}`;
        return (
          <td {...cell.getCellProps()} key={cellKey} className="text-gray-800 fw-bolder p-2">
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};

export default memo(CustomRow);
