import { SingleSelectDropdown } from "../../../../layout/components/form";
import { useIntl } from "react-intl";
import { useAuth } from "../../../auth";
import { hasEditPermission } from "../../../../helpers/permissions";

const Step2 = (props: any) => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const source_tracker = hasEditPermission(currentUser, "source_tracker", "scripts");

  return (
    <div className="w-100">
      <h1>{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP2" })}</h1>
      <div className="text-muted mt-3">
        {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP2.INSTRUCTIONS" })}
      </div>
      <hr />
      <div className="w-100 d-flex flex-column">
        <div className="m-3 flex-fill">
          <h2 id="source-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.SOURCE" })}</h2>
          <div className="text-muted my-3" id="source-description">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.SOURCE.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="source"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="source-label"
            ariaDescribedBy="source-description"
            isDisabled={!source_tracker}
          />
        </div>
        <div className="m-3 flex-fill">
          <h2 id="medium-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.MEDIUM" })}</h2>
          <div className="text-muted my-2" id="medium-description">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.MEDIUM.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="medium"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="medium-label"
            ariaDescribedBy="medium-description"
            isDisabled={!source_tracker}
          />
        </div>
        <div className="m-3 flex-fill">
          <h2 id="campaign-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.CAMPAIGN" })}</h2>
          <div className="text-muted my-2" id="campaign-description">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.CAMPAIGN.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="campaign"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="campaign-label"
            ariaDescribedBy="campaign-description"
            isDisabled={!source_tracker}
          />
        </div>
        <div className="m-3 flex-fill">
          <h2 id="term-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.TERM" })}</h2>
          <div className="text-muted my-2" id="term-instructions">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.TERM.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="term"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="term-label"
            ariaDescribedBy="term-description"
            isDisabled={!source_tracker}
          />
        </div>
        <div className="m-3 flex-fill">
          <h2 id="content-label">{intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.CONTENT" })}</h2>
          <div className="text-muted my-2" id="content-description">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.CONTENT.INSTRUCTIONS" })}
          </div>
          <SingleSelectDropdown
            id="content"
            options={props.options}
            selectedOption={props.selectedOption}
            showRefreshButton={false}
            refreshType=""
            hideLabel={true}
            hideDescription={true}
            ariaLabelledBy="content-label"
            ariaDescribedBy="content-description"
            isDisabled={!source_tracker}
          />
        </div>
      </div>
    </div>
  );
};

export { Step2 };
