import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { MasterLayout } from "../../layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";

import { getCSSVariableValue } from "../../assets/ts/_utils";
import { WithChildren } from "../../helpers";
import { SFDCSetupWrapper } from "../salesforce-configuration/SFDCSetupWrapper";
import { MktoSetupWrapper } from "../marketo-configuration/MktoSetupWrapper";
import { RolesAndPermissionsWrapper } from "../roles-permissions/RolesPermission";
import DependenciesAcknowledgement from "../misc-pages/dependencies-acknowledgement/DependenciesAcknowledgement";
import { MktoConfigWrapper } from "../marketo-configuration/MktoConfigWrapper";
import AccountSettings from "../account-settings/AccountSettings";
import UserProfile from "../user-management/profile-page/UserProfile";
import { hasFetchPermission, hasPermission } from "../../helpers/permissions";
import { useAuth } from "../auth";
import ConnectionsRouter from "../connections/ConnectionsRouter";
import RefreshDataWrapper from "../utilities/refresh/RefreshDataWrapper";
import InsentricJsConfigurator from "../insentric-js/InsentricJsConfigurator";

const AppContentRoutes = () => (
  <div>
    {/* Other layout content */}
    <Outlet /> {/* Nested routes will render here */}
  </div>
);
const PrivateRoutes = () => {
  const { currentUser } = useAuth();

  /* USER PROFILE PERMISSIONS */
  const user_profile = hasPermission(currentUser, "user", "profile");

  /* ACCOUNT PERMISSIONS */
  const account_roles = hasPermission(currentUser, "account", "roles");
  const account_users = hasPermission(currentUser, "account", "users");
  const account_settings = hasPermission(currentUser, "account", "settings");
  const account_connections = hasPermission(currentUser, "account", "credentials");

  /* MARKERTO SETTINGS PERMISSIONS */
  const marketo_general_settings = hasPermission(currentUser, "marketo", "general_settings");
  const marketo_activity_types = hasPermission(currentUser, "marketo", "activity_types");
  const marketo_customer_journey = hasPermission(currentUser, "marketo", "customer_journey");
  const marketo_progression_status_map = hasPermission(currentUser, "marketo", "progression_status_map");
  const marketo_qs_appender_settings = hasPermission(currentUser, "marketo", "qs_appender_settings");

  /* OBJECTS AND ASSETS REFRESH PERMISSIONS */
  const refresh_marketo = hasFetchPermission(currentUser, "marketo", "data");
  const refresh_sfdc = hasFetchPermission(currentUser, "salesforce", "data");

  /* FIELDS UPDATE PERMISSIONS */
  const marketo_fields = hasPermission(currentUser, "marketo", "fields");
  const sfdc_fields = hasPermission(currentUser, "salesforce", "fields");

  /* SALESFORCE SETTINGS PERMISSIONS */
  const sfdc_general_settings = hasPermission(currentUser, "salesforce", "general_settings");

  /* SOURCE TRACKER PERMISSIONS */
  const source_tracker = hasPermission(currentUser, "source_tracker", "scripts");

  const Home = lazy(() => import("../home/Home"));
  const MktoFetchProgram = lazy(() => import("../utilities/marketo-utilities/MktoFetchProgram"));
  const MktoFetchActivities = lazy(() => import("../utilities/marketo-utilities/MktoFetchActivities"));
  const SfdcSettings = lazy(() => import("../salesforce-configuration/components/SfdcSettings/SfdcSettings"));
  const UsersPage = lazy(() => import("../../pages/user-management/UsersPage"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Home after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/app/home" />} />
          {/* Pages */}
          <Route path="/app/" element={<AppContentRoutes />}>

          <Route path="home" element={<SuspensedView><Home /></SuspensedView>} />

          {/* USER MANAGEMENT route with permission check */}
          <Route path="user-management/*" element={<SuspensedView>{!account_users ? <Navigate to="/app/home" /> : <UsersPage />}</SuspensedView>}/>

          {/* ACCOUNT SETTINGS route with permission check */}
          <Route path="account-settings/*" element={<SuspensedView>{!account_settings ? <Navigate to="/app/home" /> : <AccountSettings />}</SuspensedView>}/>

          {/* ROLES AND PERMISSIONS route with permission check */}
          <Route path="roles-and-permissions" element={<SuspensedView>{!account_roles ? <Navigate to="/app/home" /> : <RolesAndPermissionsWrapper />}</SuspensedView>}/>

          {/* CONNECTIONS settings route with permission check */}
          <Route path="configs/connections/*" element={<SuspensedView>{!account_connections ? <Navigate to="/app/home" /> : <ConnectionsRouter />}</SuspensedView>}/>

          {/* MARKETO settings route with permission check */}
          <Route
            path="configs/*"
            element={
              <SuspensedView>
                {!marketo_general_settings &&
                !marketo_activity_types &&
                !marketo_customer_journey &&
                !marketo_progression_status_map &&
                !marketo_qs_appender_settings ? (
                  <Navigate to="/app/home" />
                ) : (
                  <MktoConfigWrapper />
                )}
              </SuspensedView>
            }
          />

          {/* SALESFORCE SETTINGS route with permission check */}
          <Route path="configs/salesforce_settings" element={<SuspensedView>{!sfdc_general_settings ? <Navigate to="/app/home" /> : <SfdcSettings />}</SuspensedView>} />

          {/* SALESFORCE FIELDS route with permission check */}
          <Route path="salesforce-config/*" element={<SuspensedView>{!sfdc_fields ? <Navigate to="/app/home" /> : <SFDCSetupWrapper />}</SuspensedView>} />
          
          {/* MARKETO FIELDS route with permission check */}
          <Route path="marketo-config/*" element={<SuspensedView>{!marketo_fields ? <Navigate to="/app/home" /> : <MktoSetupWrapper />}</SuspensedView>} />

          {/* SOURCE TRACKER route with permission check */}
          <Route path='source-tracker' element={<SuspensedView>{!source_tracker ? <Navigate to="/app/home" /> : <InsentricJsConfigurator />}</SuspensedView>} />
          
          {/* USER PROFILE route with permission check  */}
          <Route path="profile" element={<SuspensedView>{!user_profile ? <Navigate to="/app/home" /> : <UserProfile />}</SuspensedView>} />

          <Route path="acknowledgements" element={<SuspensedView><DependenciesAcknowledgement /></SuspensedView>} />

          {/* OBJECT AND ASSETS REFRESH route with permission check */}
          <Route path="refresh" element={<SuspensedView>{!refresh_marketo || !refresh_sfdc ? <Navigate to="/app/home" /> : <RefreshDataWrapper />}</SuspensedView>}/>

          {/* MARKETO FETCH PROGRAMS AND ACTIVITIES route with permission check */}
          <Route path="fetch/marketo_programs" element={<SuspensedView>{!refresh_marketo ? <Navigate to="/app/home" /> : <MktoFetchProgram />}</SuspensedView>}/>
          <Route path="fetch/marketo_activities" element={<SuspensedView>{!refresh_marketo ? <Navigate to="/app/home" /> : <MktoFetchActivities />}</SuspensedView>} />
          
        </Route>
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/app/home" />} />
      </Route>
    </Routes>
  );
};
const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
