import { useIntl } from "react-intl";

type TableRoleNameCellProps = {
  role?: string;
  desc?: string;
};

const TableRoleNameCell = ({ role, desc }: TableRoleNameCellProps) => {
  const intl = useIntl();

  return (
    <div className="w-100 text-start">
      {role ? (
        <div id={`role-cell-${role}`}>
          <h3 className="text-body text-start fs-5">{role}</h3>
          {desc && desc.length > 0 && <p className="fs-8 fw-normal text-wrap fst-italic">{desc}</p>}
          
        </div>
      ) : (
        <div id="role-cell-no-role" className="badge badge-light-danger text-end px-4 py-2">
          {intl.formatMessage({ id: "ROLES.TABLE.CELL.ROLE.NOROLE" })}
        </div>
      )}
    </div>
  );
};

export { TableRoleNameCell };
