import { KTIcon } from "../../../../helpers";
import "../style/SingleSelectDropdownWithSearch.css";

type FieldsWithSearchDropdownButtonSingleProps = {
  children: any;
  onClick: any;
  error?: string;
};

const FieldsWithSearchDropdownButtonSingle = ({
  children,
  onClick,
  error
}: FieldsWithSearchDropdownButtonSingleProps) => {
  let btnClass = "SSD__dropdownWithSearchBtn w-100";

  if (error) {
    btnClass = "SSD__dropdownWithSearchBtn w-100 SSD__error";
  }

  return (
    <div className={btnClass} role="textbox" tabIndex={0} onClick={onClick} onKeyDown={onClick}>
      <div className="d-flex w-100 justify-content-between">
        {children}
        <div className="d-flex">
          <div className="SSD__dropdownWithSearchSeparator"></div>
          <KTIcon iconName="down" className="fs-2 SSD__dropdownWithSearchDownIcon" />
        </div>
      </div>
    </div>
  );
};

export default FieldsWithSearchDropdownButtonSingle;
