import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  connectionsContextProps,
  initialConnectionsContext,
  WithChildren,
  connectionsStateProps,
  initialConnectionsState
} from "./_models";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../auth/core/Auth";
import { fetchInitialData } from "./_requests";

const ConnectionsContext = createContext<connectionsContextProps>(initialConnectionsContext);

/**
 * `ConnectionsProvider` is a context provider that manages the state of external service connections.
 * It handles the fetching and updating of connection data for services like Marketo, Salesforce, HubSpot, and Slack.
 * The provider makes the connection state available to all child components via React Context.
 *
 * @param {WithChildren} props - Props containing child components to be wrapped by the provider.
 * @param {React.ReactNode} props.children - The child components that will have access to the context.
 *
 * @returns {JSX.Element} A context provider wrapping its children with connection data.
 */
const ConnectionsProvider = ({ children }: WithChildren): JSX.Element => {
  const { currentUser } = useAuth();

  // State object to store connection data and status for all services
  const [connectionData, setConnectionData] = useState<connectionsStateProps>(initialConnectionsState);

  // Query to fetch initial data from the backend
  const {
    isFetching,
    refetch,
    data: response
  } = useQuery({
    queryKey: ["Fetch all connections", currentUser?.roles?.[0]?.id],
    queryFn: fetchInitialData,
    refetchOnMount: false,
    gcTime: 3600 * 1000,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  // Memoized response data to avoid unnecessary re-renders
  const responseData = useMemo(() => response, [response]);

  // Memoized connection status calculations
  const marketoConnected = useMemo(() => {
    if (!responseData) return false;
    return (
      responseData.marketo.mkto_munchkin_id.length > 0 &&
      responseData.marketo.mkto_client_id.length > 0 &&
      responseData.marketo.mkto_client_secret.length > 0
    );
  }, [responseData]);

  const salesforceConnected = useMemo(() => {
    if (!responseData) return false;
    return (
      responseData.salesforce.sfdc_login_url.length > 0 &&
      responseData.salesforce.sfdc_username.length > 0 &&
      responseData.salesforce.sfdc_password.length > 0 &&
      responseData.salesforce.sfdc_token.length > 0
    );
  }, [responseData]);

  const hubspotConnected = useMemo(() => {
    if (!responseData) return false;
    return responseData.hubspot.hubspot_hub_id.length > 0 && responseData.hubspot.hubspot_private_app_token.length > 0;
  }, [responseData]);

  const slackConnected = useMemo(() => {
    if (!responseData) return false;
    return responseData.slack.slack_api_token.length > 0;
  }, [responseData]);

  // Update states based on fetched data
  useEffect(() => {
    if (responseData) {
      setConnectionData((prevState) => ({
        ...prevState,
        isSfdcEnabled: responseData.isSfdcEnabled,
        connection_data_marketo: responseData.marketo,
        connection_data_salesforce: responseData.salesforce,
        connection_data_hubspot: responseData.hubspot,
        connection_data_slack: responseData.slack,
        isMarketoConnected: marketoConnected,
        isSalesforceConnected: salesforceConnected,
        isHubspotConnected: hubspotConnected,
        isSlackConnected: slackConnected
      }));
    }
  }, [responseData, marketoConnected, salesforceConnected, hubspotConnected, slackConnected]);

  return (
    <ConnectionsContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        isSfdcEnabled: connectionData.isSfdcEnabled,
        connection_data_marketo: connectionData.connection_data_marketo,
        connection_data_salesforce: connectionData.connection_data_salesforce,
        connection_data_hubspot: connectionData.connection_data_hubspot,
        connection_data_slack: connectionData.connection_data_slack,
        isMarketoConnected: connectionData.isMarketoConnected,
        isSalesforceConnected: connectionData.isSalesforceConnected,
        isHubspotConnected: connectionData.isHubspotConnected,
        isSlackConnected: connectionData.isSlackConnected
      }}
    >
      {children}
    </ConnectionsContext.Provider>
  );
};

/**
 * `useConnections` is a custom hook for accessing the `ConnectionsContext`.
 * It provides connection data and states for external services within the application.
 *
 * @returns {connectionsContextProps} The current state and actions for managing connections.
 * @throws Will throw an error if used outside of a `ConnectionsProvider`.
 */
const useConnections = (): connectionsContextProps => useContext(ConnectionsContext);

export { ConnectionsProvider, useConnections };
