import GenSettingsHeader from "./GenSettingsHeader";
import GenSettingsCompanyID from "./GenSettingsCompanyID";
import GenSettingsCompanyName from "./GenSettingsCompanyName";
import GenSettingsTimezone from "./GenSettingsTimezone";
import CompanyLogoWrapper from "./CompanyLogoWrapper";
import GenSettingsFiscalYearType from "./GenSettingsFiscalYearType";
import GenSettingsFiscalYearStartMonth from "./GenSettingsFiscalYearStartMonth";
import GenSettingsSaveButton from "./GenSettingsSaveButton";

import { Form, Formik } from "formik";
import { validationSchema } from "../schema/validationSchema";
import { AccountSettingsType } from "../core/_models";
import GenSettingsHeaderLogo from "./GenSettingsHeaderLogo";
import GenSettingsClientEmailDomains from "./GenSettingsClientEmailDomains";

const GenSettingsFormWrapper = ({
  isSaving,
  timezone,
  setTimezone,
  formValues,
  onSubmit
}: {
  isSaving: boolean;
  timezone: string;
  setTimezone: any;
  formValues: AccountSettingsType;
  onSubmit: any;
}) => {
  return (
    <div className="d-flex flex-column flex-lg-row gap-10">
      <div className="w-100 w-lg-50">
        <Formik initialValues={formValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ values, touched, errors, setFieldValue }) => {
            return (
              <Form id="account_settings_form">
                <div className="card mb-5 mb-xxl-8">
                  <GenSettingsHeader />
                  <div className="card-body">
                    <GenSettingsCompanyID errors={errors} touched={touched} />

                    <GenSettingsCompanyName errors={errors} touched={touched} />

                    <GenSettingsTimezone timezone={timezone} setTimezone={setTimezone} setFieldValue={setFieldValue} />

                    <GenSettingsFiscalYearType values={values} setFieldValue={setFieldValue} />

                    {values.company_fiscal_year_type && (
                      <GenSettingsFiscalYearStartMonth value={formValues.company_fiscal_year_start_month} />
                    )}

                    <GenSettingsClientEmailDomains />

                    <GenSettingsSaveButton isSaving={isSaving} />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="w-100 w-lg-50">
        <div className="card mb-5 mb-xxl-8">
          <GenSettingsHeaderLogo />
          <div className="card-body">
            <CompanyLogoWrapper />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenSettingsFormWrapper;
