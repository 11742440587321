import { useRolesView } from "../../core/RolesAndPermissionsProvider";
import ModalBodyAddNewRole from "./components/ModalBodyAddNewRole";
import ModalBodyUsers from "./components/ModalBodyUsers";

const RolesModalBody = () => {
  const { roleIdForUpdate } = useRolesView();

  return (
    <>
      {roleIdForUpdate.type === "LIST_USERS" && <ModalBodyUsers />}
      {roleIdForUpdate.type === "ADD_NEW_ROLE" && <ModalBodyAddNewRole />}
    </>
  );
};

export default RolesModalBody;
